@import "styles/basics/mixins";
@import "styles/basics/vars";

.learningCenter {
  @include flex;
  align-items: flex-start;

  &_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
    
    &_head {
      @include flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      h2 { margin: 0; color: $navy; }
      p { margin: 0; color: $grey; }

    }

    &_vid {
      display: flex;
      align-items: flex-start;
      max-height: 100%;
      flex-grow: 1;
      height: 650px;
      width: 100%;

      i {
        color: grey;
        font-size: 48px;
      }

    }

    &_footer {
      @include flex;
    }

  }

  &_sideBar {
    width: 350px;
    min-height: calc(100vh - 60px);
    border-right: 1px solid $lightGrey;
    background-color: #FAFAFA;

    h1 {
      color: $navy;
      font-size: 24px;
      padding: 1rem;
      margin: 0;
    }

  }

}

.defaultScreen {
  @include unicorn;
  width: 100%;
  height: calc(100% - 150px);

  i { color: $teal; }
  h3 { color: $navy; margin: 0; }
  p { color: $grey; margin: 0; }

  & > div { 
    display: flex; 
    align-items: center;
    flex-direction: column; 
    gap: 10px;
    padding: 2rem;
    border-radius: 10px;
    border: 1px solid $lightGrey;
  }

}

.skeleton {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 1rem;
}