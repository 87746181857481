.dynamsoft-dialog-wrap {
  background: #F30 !important;
  display: none !important;
}

.scanner {
  @include flex;
  align-items: stretch;

  &__container {
    padding: 1rem 1rem;
    background: #EFEFEF;
    border-top: 1px solid $lightGrey;
    border-bottom: 1px solid $lightGrey;
    margin-bottom: 2rem;

    .error {
      padding: .5rem;
      border-radius: 3px;
      font-size: .825rem;
    }

    .hideThumbnails {
      display: none;
      position: absolute;
      top: .5rem; left: .5rem;
      z-index: 10;
      padding: 0;
      border: 0;
      font-size: 2rem;
      color: $grey;
      cursor: pointer;
      &:hover {
        color: $navy;
      }
    }

    &--thumbs {
      position: relative;
      .scanner__scan {
        width: 100%;
      }
      .scanner__options {
        display: none;
      }
      .hideThumbnails {
        display: block;
      }
    }
  }

  &__scan {
    width: calc(100% - 248px);
  }

  &__options {
    width: 232px;
    border-left: 1px solid $lightGrey;
    background: #FAFAFA;

    &__toggle {
      @include flex;
      justify-content: flex-start;
      .switch  {
        label {
          margin: 0;
        }
      }
      label {
        margin-left: .25rem;
        font-size: .7rem;
      }
    }

    &--scanner {
      padding: 1rem;
      border-bottom: 1px solid $lightGrey;
      .inputRow__block {
        margin: 0;
      }
    }

    &--options {
      padding: 1rem;
      border-bottom: 1px solid $lightGrey;
      .scanner__options__toggle {
        margin-bottom: .25rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &--actions {
      padding: 1rem;
      button {
        width: 100%;
      }
    }

    &--pixelType {
      margin-bottom: .5rem;
    }
  }

  .fileOptions {
    padding: 1rem;
    .inputRow__block {
      margin-bottom: .5rem;
    }
    button {
      margin-top: .5rem;
      width: 100%;
    }
  }
}

.radioToggle {
  @include flex;
  align-items: stretch;
  border: 2px solid $grey;
  border-radius: 3px;
  & > div {
    border-right: 2px solid $grey;
    width: calc(100% / 3);
    &:last-child {
      border-right: 0;
    }
    
    input {
      display: none;
    }
    input + label {
      display: inline-block;
      padding: .25rem 0;
      width: 100%;
      font-size: 1rem;
      color: $grey;
      text-align: center;
      cursor: pointer;
    }
    input:checked + label {
      background: $grey;
      color: #FFF;
    }
  }
}