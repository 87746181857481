@import "styles/basics/mixins";

.right {
  body:global(.template-bondsman) & {
    @include flex;
    justify-content: flex-end;
  }

  body:global(.layout--signup) & {
    display: none;
  }
}
