@mixin flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin unicorn {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin input {
  padding: 10px;
  width: 100%;
  border: 1px solid #DFE6F4;
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
  -webkit-appearance:none;
  @include bp(Mama) {
    font-size: .875rem;
  }

  &:focus {
    border-color: #24C0AC;
  }
}

@mixin innerPod {
  padding: .5rem;
  border: 1px solid $lightGrey;
  background: #FFF;
  border-radius: 5px;
}

@mixin pod {
  @include flex;
  row-gap: 0.5rem;
  margin-bottom: .5rem;
  padding: .5rem;
  border: 2px solid $lightGrey;
  background: #FFF;
  border-radius: 5px;

  &__left {
    h4 {
      margin: 0;
    }
    p {
      margin: 0;
    }
  }
  &__right {
    display: flex;
    justify-content: flex-end;
    row-gap: 0.5rem;
    flex-direction: column;  

    @include bp(Kid) {
      flex-direction: row;
      align-items: center;
    }
  
    & > * {
      margin-left: .5rem;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

@mixin bigAddButton {
  button {
    width: 100%;
    background: #FAFAFA;
    border: 2px solid $lightGrey;
    padding: .5rem 0;
    font-size: 2rem;
    color: $grey;
    cursor: pointer;
    border-radius: 10px;
    &:hover {
      color: $navy;
    }
  }
}

// Breakpoint Mixin
@mixin bp($point) {
  @if $point == Baby {
    // 320
    @media (min-width: 20.0625rem) {
      @content;
    }
  }
  @if $point == Toddler {
    // 375
    @media (min-width: 23.4375rem) {
      @content;
    }
  }
  @if $point == Kid {
    // 600
    @media (min-width: 37.5rem) {
      @content;
    }
  } @else if $point == Mama {
    // 767
    @media (min-width: 47.9375rem) {
      @content;
    }
  } @else if $point == BigMama {
    // 960
    @media (min-width: 60rem) {
      @content;
    }
  } @else if $point == Uncle {
    // 996
    @media (min-width: 65.25rem) {
      @content;
    }
  } @else if $point == UnclePapa {
    // 1000
    @media (min-width: 62.5rem) {
      @content;
    }
  } @else if $point == Papa {
    // 1080
    @media (min-width: 67.5rem) {
      @content;
    }
  } @else if $point == BigPapa {
    // 1136
    @media (min-width: 71rem) {
      @content;
    }
  } @else if $point == Grand {
    // 1200
    @media (min-width: 75rem) {
      @content;
    }
  } @else if $point == SupaPapa {
    // 1200
    @media (min-width: 87.5rem) {
      @content;
    }
  } @else if $point == SupaDupaPapa {
    // 1200
    @media (min-width: 81.25rem) {
      @content;
    }
  } @else if $point == Supa {
    // 1600
    @media (min-width: 100rem) {
      @content;
    }
  } @else if $point == Tablet {
    @media (min-width: 768px) and (max-width: 1023px) {
      @content;
    }
  }
}

