.template-home {

  .slick-prev, .slick-next, slick-dots {
    display: none !important;
    @include bp(Mama) {
      display: none !important;
    }
  }

  .slick-slide {
    height: 100vh;

    img {
      position: relative;
      left: -420px;
      height: 100vh;
      width: auto;
      @include bp(Mama) {
        left: 0;
      }
    }
  }

  .slide__container {
    position: relative;
    height: 500px;
    @include bp(Mama) {
      min-height: 700px;
      height: 70vh;
    }

    &__slide {
      width: 100%; height: 100%;
      background: url('/assets/images/home_hero_1.jpg') no-repeat 50% 50%;
      background-size: cover;
    }
  }

  .floater {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    padding: 1.5rem;
    max-width: 100%;
    width: 100%;
    color: #FFF;
    background: rgba(0,0,0,.5);

    @include bp(Mama) {
      padding: 4rem 3rem;
      top: 50%; left: 5rem;
      transform: translateY(-42%);
      max-width: 600px;
    }

    h2 {
      font-size: 1.5rem;
      line-height: 1.5rem;
      margin: 0 0 1rem;
      @include bp(Mama) {
        font-size: 3rem;
        line-height: 3rem;
      }
    }

    p {
      font-size: 1rem;
      @include bp(Mama) {
        font-size: 1.25rem;
      }

      &.notice {
        font-size: 1.25rem;
        @include bp(Mama) {
          font-size: 1.5rem;
        }

        strong {
          display: block;
          font-size: 1.5rem;
          @include bp(Mama) {
            font-size: 1.75rem;
          }
        }
      }
    }

    &__actions {
      button {
        margin-right: 1rem;
        font-size: 1rem;
        width: 100%;

        @include bp(Mama) {
          font-size: 1.5rem;
          height: 50px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .layout__home {
    
  }

  .mobile_stepper {
    position: relative;

    &__actions {
      position: absolute;
      top: 290px; left: 0;
      width: 100%;

      @include bp(Mama) {
        display: none;
      }

      .button-next, .button-back {
        min-width: 28px;
    
        svg {
          fill: #FFF;
        }

        &:disabled {
          svg {
            opacity: .3;
          }
        }
      }
    }

    .mobile__paper {
      margin-top: 1rem;
    }
  }

  .home__howItWorks {
    padding: 4rem 0;
    background: $navy;

    h3 {
      margin-top: 0;
      font-size: 3rem;
      color: #FFF;
      text-align: center;
    }

    .MuiStep-root {

      .MuiStepLabel-label {
        font-size: 1.25rem;
        color: #FFF !important;
      }

      .MuiStepLabel-root {  
        .MuiStepLabel-labelContainer {
          // background: #FFF;
          padding: 0 .75rem 0;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
          color: #FFF;
        }

        &.Mui-disabled {
          .MuiStepLabel-labelContainer {
            padding: .75rem;
            border-radius: 3px;
          }
        }
      }

      &.MuiStep-completed {
        .MuiStepLabel-labelContainer {
          padding: .75rem;
          border-radius: 3px;
        }
      }
  
      .MuiStepLabel-iconContainer {
        padding-right: 9px;
      }

      .MuiStepContent-root {
        margin-top: 0;
        padding-right: 0;
        align-items: stretch;
  
        .MuiCollapse-container {
          // background: #FFF;
          padding: .75rem;
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
          color: #FFF;
        }
      }

      &.MuiStepConnector-active {
        .MuiStepLabel-labelContainer {
          padding: .75rem .75rem 0;
          border-radius: 0;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }

      }
    }

    .MuiPaper-root {
      background: transparent;
    }

    .MuiMobileStepper-dots {
      display: none;

      @include bp(Mama) {
        display: flex;
      }
    }

    .mobile__paper {
      padding: 0 1rem;
      &__inner {
        padding: 1rem;
        background: #FFF;
      }
      &--title {
        font-size: 1.25rem;
        margin-bottom: .75rem;
      }
      &--description {
        font-size: 1rem;
      }
    }
  }

  .home__footer {
  }
}

.gideons {
  padding: 1.25rem 0;
  background: #114684;
  display: none;
  @include bp(Mama) {
    display: block;
  }

  &__container {
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    max-width: 900px;
    @include bp(Mama) {
      padding: 0 2rem;
      flex-direction: row;
    }
  }

  &__image {
    margin: 0 1rem 1rem 0;
    height: 100px;
    background: $white;
    padding: .5rem;
    @include bp(Mama) {
      margin: 0 2rem 0 0;
    }

    img {
      max-width: auto;
      height: 100%;
    }
  }

  &__info {
    p {
      margin: 0 0 1rem;
      color: $white;
      text-align: left;
      font-size: .75rem;
      @include bp(Mama) {
        font-size: .85rem;
        line-height: 1rem;
      }
      &:last-child {
        margin: 0;
      }
    }
  }

  button {
    background: $white;
    color: #114684;
    border-color: $white;
    font-weight: 700;
  }
  .butt--desktop {
    display: none;
    @include bp(Mama) {
      display: block;
    }
  }
  .butt--mobile {
    display: block;
    padding: 0 1rem;
    margin: 0 auto 0;
    max-width: 400px;
    @include bp(Mama) {
      display: none;
    }

    button {
      height: auto;
      padding: .25rem 3rem;
    }
  }

  &__mobileHeader {
    padding: 1.25rem 0;
    display: flex;
    justify-content: space-between;
    @include bp(Mama) {
      display: none;
    }

    .gideons__image {
      height: 70px;
      margin-bottom: 0;
    }
    .gideons__info {
      p {
        margin-bottom: .25rem;
      }
    }
  }

  &__wrapper {
    &--mobileActive {
      .gideons {
        display: block;
      }
      .gideons__mobileHeader {
        display: none;
      }
    }
  }
}