body.template-settings {
  .layout {
    &.layout--profile, &.layout--notifications, &.layout--settings, &.layout--reinstate {
      padding: 0 1rem !important;
      .layout__info {
      }
      .register__form {
        max-width: 100%;
        margin: 0;
        padding: 0;
      }
    }
    &.layout--importer {
      padding: 0;
      .layout__info {
        padding-left: 1rem !important;
      }
    }
  }
  .users {
    padding: 0 0 5rem;
  }
  .dashboard.register__form {
    max-width: 100%;
    .register__form__block {
      padding: 0;
    }
  }
}

.reinstateCases {
  table tr td, table tr th {
    text-align: left;
    &:nth-child(2) {
      width: 60px;
      text-align: center;
    }
    &.action {
      width: 100px;
      text-align: center;
    }
  }
  .button--reinstate {
    height: 30px;
  }
  &__case {
    &__expired {
      background: #eaf4ff !important;
    }
  }
}

.settings {
  padding: 0;
  
  .setting, .formBlock--setting {

    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #DFE6F4;

    &:last-child {
      margin-bottom: 0;
      border: 0;
    }
    
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &--inset {
        padding-left: 2rem;
      }
    }
    
    .setting__content {
      h5 {
        margin: 0 0 .5rem;
        font-size: .85rem;
        font-weight: 800;
      }
      p {
        color: #6F7F9F;
        font-size: .75rem;
        margin-bottom: 0;
      }

      &__meta {
        padding-top: 1rem;
      }

      &__textarea {
        input[type="text"], textarea {
          font-family: 'Rubik', -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
          "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
          sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          padding: 10px;
          width: 100%;
          border: 1px solid #DFE6F4;
          border-radius: 5px;
          font-size: 1rem;
          outline: none;
          -webkit-appearance:none;
          @include bp(Mama) {
            font-size: .875rem;
          }
    
          &:focus {
            border-color: #24C0AC;
          }
        }
        
      }
      &__metaItem {
        display: inline-block;
        margin-right: 1rem;
        &:last-child {
          margin-right: 0;
        }
        label {
          display: block;
          color: #6F7F9F;
          margin-bottom: 4px;
        }
        input {
          padding: 5px;
          width: 100%;
          border: 1px solid #DFE6F4;
          border-radius: 5px;
          font-size: .85rem;
          outline: none;
          -webkit-appearance:none;
          @include bp(Mama) {
            font-size: .875rem;
          }

          &:focus {
            border-color: #24C0AC;
          }
        }
      }
    }

    &--toggle {
      .setting__content {
        width: calc(100% - 60px);
      }
      .setting__action {
        width: 40px;
      } 
    }

    &--dropdown {
      .setting__content {
        min-width: calc(100% - 270px);
      }
      .setting__action {
        min-width: 250px;
        .inputRow__block {
          margin: 0;
        }
      } 
    }
  }

  .setting__offices {
    margin-bottom: 2rem;
    &__header {
      p {
        font-size: .825rem;
      }
    }
    .officeTags {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      & > .tag {
        padding-right: 1.25rem;
        cursor: pointer;
      }
    }
    button {
      margin-right: .5rem;
      height: 45px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: .5rem .5rem;
      background: $grey;
      border: 1px solid $lightGrey;
      color: #FFF;
      border-radius: 5px;
      &:hover {
        background: $navy;
      }
      i {
        margin-bottom: 2px;
        font-size: 2rem;
        line-height: 2.2rem;
      }
      span {
        display: inline-block;
        color: #FFF;
        line-height: 21px;
        font-weight: 700;
      }

      &.add {
        width: 100%;
        margin-bottom: 0;
        border: 2px solid $lightGrey;
        color: $grey;
        background: #EFEFEF;
        cursor: pointer;
        i {
          margin-bottom: 0;
        }
        &:hover {
          border-color: $grey;
          background: $grey;
          color: #FFF;
        }
      }
    }
  }
}

.setting__info__sections {
  &__file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .25rem 0;

    &:hover {
      background: #EFEFEF;
    }

    &--selectAll {
      margin-bottom: 6px;
      border-bottom: 1px solid #6F7F9F;
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-right: 1rem;

        &:last-child {
          margin-right: 0;
        }
      }

      span {
        margin-top: 4px;
        display: inline-block;
        font-size: .6rem;
        font-weight: 300;
        text-transform: uppercase;
      }
    }
  }
}

.setting--fee {
  .setting__content {
    width: 100%;
  }
}

.setting__fees {
  margin-top: 1rem;
  button {
    cursor: pointer;
  }
  .deleteConfirm__left {
    padding-left: 0 !important;
  }
  &__add {
    @include unicorn;
    padding-top: .5rem;
    button {
      background: transparent;
      border: 0;
      padding: 0;
      color: $grey;
      font-size: 2rem;
      &:hover {
        color: $navy;
      }
    }
  }
  &__fee {
    @include flex;
    border: 1px solid $lightGrey;
    background: #FFF;
    border-top: 0;
    width: 100%;
    label {
      padding: .5rem;
      span {
        color: $lightGrey;
      }
      strong {
        font-weight: 500;
        font-size: .75rem;
      }
    }
    input {
      padding: 0;
      border: 0;
      background: #FAFAFA;
      outline: none;
      height: 35px;
      line-height: 35px;
      border-left: 1px solid $lightGrey;
      text-align: right;
      font-size: 1rem;
    }
    strong {
      padding-right: 1rem;
    }
    &:first-child {
      border-top: 1px solid $lightGrey;
    }

    .pill {
      margin-left: .5rem;
    }

    &__right {
      button {
        margin: 0 .5rem 0 0;
        padding: 0;
        border: 0;
        background: transparent;
        color: $grey;
        outline: none;
        &:hover {
          color: $navy;
        }
      }
    }
  }
}

.settingsNotifications {
  &__header {
    @include flex;
    padding: 0 0 1rem 0;
    border-bottom: 1px solid $lightGrey;
    & > button {
      width: 49.5%;
      background: #FAFAFA;
      border-color: $grey;
      color: $grey;
      font-size: 1rem;
      font-weight: 700;

      &:hover {
        background: $navy;
        border-color: $navy;
        color: #FFF;
      }
    }
  }
  &__row {
    @include flex;
    padding: .5rem 0;
    border-bottom: 1px solid $lightGrey;

    &__left {
      h4 {
        margin: 0;
        color: $navy;
      }
    }

    &__right {
      @include flex;
      justify-content: flex-end;
      & > div {
        @include flex;
        margin-left: 1rem;
        label {
          font-size: .75rem;
          margin-right: .25rem;
        }
      }
    }
  }
}

.powerSurety {
  margin-bottom: 1rem;
  &__top {
    @include pod;
    gap: 1rem;
    margin: 0;
  }

  &__container {
    padding: 0 1rem;
  }

  .saveTile {
    position: absolute;
    top: 1.8rem; right: 1.5rem;
    background: $blue;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 700;
    padding: .5rem;
    color: #FFF;
    border-radius: .5rem;
    opacity: 0;
    transition: $base-transition;
    &--on {
      opacity: 1;
    }
  }

  button.add {
    width: 100%;
    background: #FAFAFA;
    border: 2px solid $lightGrey;
    padding: .25rem 0;
    font-size: 1.5rem;
    color: $grey;
    cursor: pointer;
    border-radius: 10px;
    &:hover {
      color: $navy;
    }
  }

  &__left {
    h4 {
      margin: 0;
      color: $navy;
    }
    p {
      margin: 0;
      font-size: .7rem;
    }
  }

  &__right {
    @include flex;
    justify-content: flex-end;
    gap: .5rem;
    min-width: 200px;
    .switchContainer {
      @include flex;
      flex-direction: column;
      label {
        display: inline-block;
        margin-top: .25rem;
        font-size: .65rem;
        text-transform: uppercase;
      }
    }
  }

  &__btm {
    @include pod;
    display: block;
    margin: 0 1rem;
    border-top: 0;
    background: #EFEFEF;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &__section {
      width: 100%;
      margin-bottom: 1rem;
      border-bottom: 2px solid $lightGrey;
      padding-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }

      h5 {
        
      }
      p {
        margin: 0 0 .5rem;
        font-size: .75rem;
      }
      ul.activeForms {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;
        border: 1px solid $lightGrey;
        border-radius: 5px;
        overflow: hidden;
        li {
          width: 100%;
          padding: .25rem;
          font-size: .825rem;
          border-bottom: 1px solid $lightGrey;
          // border-bottom: 0;
          background: #FFF;
          &:last-child {
            border-bottom: 0;
            // border-bottom: 1px solid $lightGrey;
          }

          .cb {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: center;
            p {
              margin: 0;
            }
            &__checkbox {
              position: relative;
              padding-right: 0;
              label {
                &:before {
                  position: absolute;
                  top: 50%; left: 0;
                  transform: translateY(-50%);
                  border-width: 1px;
                  width: 14px;
                  height: 14px;
                }
                i {
                  top: 3px; left: 3px; right: auto;
                  transform: none;
                  font-size: .65rem;
                }
              }
            }
          }
        }
      }
    }
  }

  &__rateInfo {
    @include flex;
    gap: 1rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid $lightGrey;
    padding-bottom: 1rem;
    .inputRow__block {
      flex: 1;
      margin: 0;

      input[type="text"] {
        text-align: center;
        font-size: 1.5rem;
        color: $grey;
        font-weight: 600;
      }

    }
  }

  &__actions {
    @include flex;
    justify-content: flex-end;
    gap: .5rem;
  }

  &__stats {
    @include flex;

    &__stat {
      width: 30%;
      border: 2px solid $lightGrey;
      background: #FFF;
      border-radius: 6px;
      text-align: center;
      label {
        display: block;
        padding: .2rem 0;
        border-bottom: 2px solid $lightGrey;
        font-size: .7rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $grey;
      }
      strong {
        display: block;
        padding: .25rem;
        font-size: 2rem;
        color: $grey;
      }
    }
  }

  &__customFields {
    &__add {
      @include flex;
      background: #FFF;
      margin-bottom: .5rem;
      padding: .5rem;
      border-radius: 5px;
      border: 1px solid $lightGrey;
      & > div {
        &:first-child {
          @include flex;
          width: calc(100% - 40px);
        }
        &:last-child {
          @include flex;
          width: 40px;
        }
      }
      .inputRow__block {
        margin-bottom: 0;
        width: 100%;
        margin-right: 1rem;
      }
      &__actions {
        @include flex;
        button {
          border: 0;
          background: transparent;
          padding: 1rem 0 0;
          margin-right: .5rem;
          outline: none;
          &:last-child {
            margin-right: 0;
          }
          i {
            font-size: 1rem;
            &.fa-check-circle {
              color: $teal;
            }
            &.fa-times-circle {
              color: $grey;
            }
            &.fa-trash-alt {
              color: $danger;
            }
          }
        }
      }
    }
    &__fields {
      margin: 0 0 1rem;
      padding: 0;
      list-style: none;
      background: #FFF;
      border: 1px solid $lightGrey;
      border-radius: 4px;
      overflow: hidden;
      li {
        @include flex;
        padding: .25rem;
        font-size: .825rem;
        border-bottom: 1px solid $lightGrey;
        &:last-child {
          border-bottom: 0;
        }

        button {
          border: 0;
          background: transparent;
          padding: 0;
          color: $grey;
          &:hover {
            color: $teal;
          }
        }
      }
    }
  }
}
