body.template-inventory {
  .layout {
    &.layout--powers {
      padding: 0 1rem !important;
      .layout__info {
      }
      .register__form {
        max-width: 100%;
        margin: 0;
        padding: 0;
      }
    }
    &.layout--powers {
      position: relative;
    }
  }
}

.template-bondsman #root div.main > div .layout--inventory {
  padding: 0;
  min-height: calc(var(--vh, 100vh) - 112px);
}

// .template-bondsman #root div.main>div .layout--tbd {
//   padding: 0;
//   min-height: calc(var(--vh, 100vh) - 112px);
// }

.template-bondsman #root div.main>div .layout--transferBonds {
  padding: 0;
}

.inventory {
  &__section {
    &--powerSheets, &--employees {
      header {
        margin-bottom: 1rem;
        button {
          margin-right: .5rem;
        }
      }
    }
    &__buttons {
      @include flex;
      justify-content: flex-start;
      gap: .25rem;
    }
  }
  &__powerSheets {
    &__form {
      .register__form {
        margin: 0;
        padding: 0;
        max-width: 100%;
      }
      &__actions {
        @include flex;
        padding-top: 1rem;
        & > button {
          width: 49.5%;
        }
      }
    }
    &__rows {
      &--error {
        background: #ffd3d3 !important;
      }
      &--warn {
        background: #fff6ad !important;
      }
    }
  }
}

.powerSheet {
  @include pod;
  cursor: pointer;
  &__left {
    h3 {
      margin: 0 0 .25rem;
      color: $navy;
    }
    p {
      font-size: .75rem;
    }
  }
  &__right {
    .badge {
      background: #FFF;
    }
    i {
      display: inline-block;
      padding: 0 .5rem;
      font-size: 1.5rem;
      color: $grey;
    }
  }
  &:hover {
    background: #FAFAFA;
    button {
      color: $navy;
    }
  }
}

.addPowersheet {
  @include flex;
  gap: 1rem;
  margin-bottom: 1rem;
  &__left {
    @include flex;
    gap: .5rem;
    width: calc(100% - 70px);
    & > div {
      margin: 0;
    }
  }
  &__right {
    @include flex;
    justify-content: flex-end;
    width: 70px;
    button {
      margin-left: .25rem;
      border: 0;
      background: transparent;
      padding: 0;
      color: $grey;
      font-size: 2rem;
      outline: none;
      &:hover {
        color: $navy;
      }

      i {
        &.fa-circle-check {
          color: $teal;
        }
      }
    }
  }

  &__addRow {
    width: 100%;
    background: #FAFAFA;
    border: 2px solid $lightGrey;
    padding: .25rem 0;
    font-size: 2rem;
    color: $grey;
    cursor: pointer;
    border-radius: 10px;
    outline: none;
    &:hover {
      color: $navy;
      background: #EFEFEF;
      border-color: $grey;
    }
  }
}

.inventoryTable {
  margin-bottom: 1rem;
  table {
    width: 100%;
    border-collapse: collapse;
  }
  table, th, td {
    border: 1px solid $lightGrey;
  }
  thead {
    tr {
      background: $grey;
      th {
        color: #FFF;
        padding: .5rem;
        font-size: .825rem;
      }
    }
  }
  tbody {
    tr:nth-child(odd) {
      background: #FAFAFA;
    }
  }
  td {
    padding: .25rem;
    font-size: 1rem;
    text-align: center;
    &.adding {
      input { background: #c4ffe4; }
    }
    &.subtracting {
      input { background: #ffd6d6; }
    }
  }
  .actions {
    width: 90px;
    a {
      display: inline-block;
      margin-right: .5rem;
      text-decoration: none;
      &:last-child {
        margin-right: 0;
      }
    }
    button {
      margin-right: .5rem;
      display: inline-block;
      padding: 0;
      background: transparent;
      border: 0;
      cursor: pointer;
      color: $teal;
      font-size: 1.5rem;
      outline: none;
      &:hover {
        color: $navy;
      }
      &:last-child {
        margin-right: 0;
      }
      i.fa-circle-xmark {
        color: $danger;
      }
    }
  }
}

$employeeWidth: 400px;

.employees {
  display: flex;
  align-items: stretch;
  scroll-snap-type: x mandatory;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  position: relative;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  &__container {
    height: calc(var(--vh, 100vh) - 57px - 52px - 163px - 50px);
    display: flex;
    border-top: 1px solid $lightGrey;
    background: #EFEFEF;
    &__options {
      width: 320px;
      border-right: 1px solid $lightGrey;
      background: #FAFAFA;
      padding: 1rem;
      button.redistribute {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
  &__wrapper {
    display: flex;
    padding: 1rem;
    width: calc(100% - 320px);
  }
  &__filterBar {
    @include flex;
    padding: 0 1rem;
    height: 50px;
    border-top: 1px solid $lightGrey;
    background: #FAFAFA;
    &__left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      & > * {
        margin-right: 1rem;
        padding-right: 1rem;
        border-right: 1px solid $lightGrey;
        &:last-child {
          margin: 0;
          padding: 0;
          border: 0;
        }
      }
    }
    &__right {
      @include flex;
      justify-content: flex-end;
      gap: .5rem;
      // button {
      //   margin-left: .5rem;
      //   &:first-child {
      //     margin-left: 0;
      //   }
      // }
    }
    &__dropdown {
      margin-bottom: .25rem;
      text-align: left;
      // @include flex;
      label {
        margin-bottom: .25rem;
        font-size: .75rem;
        color: $grey;
      }
      // .basic-multi-select {
      //   min-width: 200px;
      // }
    }
    &__tiles {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      & > div {
        margin-right: .25rem;
        @include unicorn;
        width: 36px; height: 36px;
        border-radius: 50%;
        cursor: pointer;
        span {
          font-weight: 700;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      &--active {
        & > div {
          opacity: .2;
          &.active {
            width: 42px; height: 42px;
            opacity: 1;
          }
        }
      }
    }
    button {
      padding: 0 .5rem;
    }
  }
  &__employee {
    margin-right: 1rem;
    border: 2px solid $lightGrey;
    border-radius: 10px;
    scroll-behavior: smooth;
    scroll-snap-align: start;
    background: #FFF;
    &__inner {
      padding: .5rem;
      width: $employeeWidth;
      height: 100%;
      // min-height: 700px;
    }
    header {
      h3 {
        margin: 0;
        color: $grey;
      }
    }
    &__powers {
      position: relative;
      white-space: nowrap;
      overflow-y: auto;
      height: calc(var(--vh, 100vh) - 615px);
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
      
      &__power {
        @include flex;
        margin-bottom: .5rem;
        padding: .25rem;
        border: 1px solid $lightGrey;
        border-radius: 4px;
        color: $grey;
        &__right {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        &__actions {
          margin-left: .5rem;
          button {
            padding: 0;
            border: 0;
            background: transparent;
            color: $grey;
            cursor: pointer;
            &:hover {
              color: $navy;
            }
          }
        }
      }

      &--undistributed {
        height: calc(var(--vh, 100vh) - 365px);
        // background: #F30;
        background: #EFEFEF;
        border-radius: 10px;
        padding: .25rem;
        .employees__employee__powers__power {
          background: #FFF;
        }
      }
    }
  }


}

.minimalistHeader--on {
  .employees__container {
    height: calc(var(--vh, 100vh) - 57px - 52px - 38px - 50px);
  }
  .employees__employee__powers {
    height: calc(var(--vh, 100vh) - 490px);
    &--undistributed {
      height: calc(var(--vh, 100vh) - 310px);
    }
  }
}

.distribution {
  .inventoryTable {
    position: fixed;
    top: 57px; left: 0;
    z-index: 10;
    width: 100%; min-height: calc(var(--vh, 100vh) - 57px);
    background: #FFF;
    &__header {
      @include flex;
      margin: 0;
      & > div {
        padding: .5rem;
        &:first-child {
          @include flex;
          justify-content: flex-start;
          & > button {
            margin-right: .5rem;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        &:last-child {
          @include flex;
          justify-content: flex-end;
          & > .badge {
            width: 70px;
            margin-right: .5rem;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
    .separator {
      width: 2px;
      background: #EFEFEF;
      border-color: #EFEFEF;
      padding: 0;
    }
    th {
      font-size: .7rem;
    }
    td {
      padding: 0;
      height: 36px;
      &:first-child {
        width: 150px
      }
      p {
        margin: 0;
        padding: .25rem;
      }
      span {
        width: 100%;
        height: 100%;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          text-align: center;
          font-size: 1rem !important;
          border: 0 !important;
        }
      }
      &.name {
        font-size: .825rem;
      }
    }
  }
}

.sheet {
  &__header {
    margin-bottom: 2rem;

    & > p {
      margin: 0;
      font-size: .825rem;
    }
  }
  &__filterBar {
    display: flex;
    justify-content: space-between;
    height: 50px;
    border-top: 1px solid $lightGrey;
    border-bottom: 1px solid $lightGrey;
    background: #EFEFEF;
    padding: 0 1rem;
    &__left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    &__right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      & > * {
        margin-right: 1rem;
        padding-right: 1rem;
        border-right: 1px solid $lightGrey;
        &:last-child {
          margin: 0;
          padding: 0;
          border: 0;
        }
      }
    }
    &__dropdown {
      @include flex;
      label {
        margin-right: .25rem;
        font-size: .75rem;
      }
      .basic-multi-select {
        min-width: 200px;
      }
    }
  }
  &__dates {
    margin-bottom: 1rem;
    @include flex;
    gap: 1rem;
    & > div {
      width: 100%;
    }
  }
  &__powerGroups {
    @include flex;
    justify-content: flex-start;
    gap: .5rem;

    border: 1px solid $lightGrey;
    border-radius: 10px;
    padding: .5rem;
    background: #FAFAFA;

    & > div {
      width: 100%;
      max-width: 150px;
    }
  }
  &__form {
    margin-bottom: 1rem;
  }
  &__powers {
    &__power {
      @include flex;
      border: 1px solid $lightGrey;
      border-radius: 4px;
      padding: .25rem;
      margin-bottom: .5rem;
      &__left {
        h3 {
          // margin: 0 0 .25rem;
          margin: 0;
          color: $navy;
        }
        // p {
        //   font-size: .75rem;
        // }
      }
      &__right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        & > * {
          margin-right: .5rem;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.packagePod {
  @include pod;
  background: #FAFAFA;

  &__left {
    @include flex;
    justify-content: flex-start;

    h4 {
      font-size: 1rem;
      margin-bottom: .25rem;
      color: $navy;
    }
    p {
      font-size: .825rem;
    }
  }

  &__right {
    .flyout {
      width: 250px;
    }
  }

  &__content {
    margin-left: 1rem;
  }

  &__tile {
    min-width: 80px;
    border: 1px solid #DFE6F4;
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    label {
      display: block;
      background: $grey;
      font-size: .75rem;
      text-transform: uppercase;
      color: #FFF;
      padding: 3px 0;
      font-weight: 700;
    }
    strong {
      display: block;
      padding: 0.5rem;
      font-size: 2rem;
      color: $grey;
      background: #FFF;
    }

    &--blue {
      border-color: $blue;
      label {
        background: $blue;
      }
      strong {
        color: $blue;
      }
    }
    &--orange {
      border-color: $orange;
      label {
        background: $orange;
      }
      strong {
        color: $orange;
      }
    }
    &--yellow {
      border-color: $warning;
      label {
        background: $warning;
      }
      strong {
        color: $warning;
      }
    }
    &--green {
      border-color: $completed;
      label {
        background: $completed;
      }
      strong {
        color: $completed;
      }
    }
    &--red {
      border-color: $danger;
      label {
        background: $danger;
      }
      strong {
        color: $danger;
      }
    }
  }
}