.companyProfile {
  margin: 0 auto;
  max-width: 600px;

  &__header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include bp(Mama) {
      flex-direction: row;
    }

    &__logo {
      text-align: center;
      @include bp(Mama) {
        text-align: left;
      }
      img {
        max-width: 300px;
        height: auto;
      }
    }

    &__info {
      text-align: center;
      @include bp(Mama) {
        text-align: left;
      }
      h1 {
        margin-bottom: 0;
        text-align: center;
        color: $navy;
        @include bp(Mama) {
          text-align: right;
        }
      }
    }
  }

  &__location {
    margin: 0;
    text-align: center;
    @include bp(Mama) {
      text-align: right;
    }
  }

  &__address {
    margin-top: 1em;
    text-align: center;
    @include bp(Mama) {
      text-align: right;
    }
    p {
      margin: 0;
      font-weight: 700;
    }
  }
  
  &__content {
    padding: 2rem 1.5rem;
    @include bp(Mama) {
      padding: 2rem 0;
    }

    a {
      color: $black;
      text-decoration: none;

      &:hover {
        color: $teal;
        text-decoration: underline;
      }
    }
  }

  &__actions {
    margin: 0 auto 2rem;
    padding: 0 1.5rem;
    text-align: center;
    @include bp(Mama) {
      padding: 0;
    }
    button {
      padding: 0 1.5rem;
      margin: 0 auto 2rem;
      display: block;
      @include bp(Mama) {
        padding: 0 2rem;
      }

      &.butt--teal {
        font-size: 1rem;
        font-weight: 700;
        height: 50px;
        @include bp(Mama) {
          font-weight: 400;
          font-size: 1.5rem;
        }
      }
    }
    a {
      color: $navy;
    }
    // display: flex;
    // justify-content: center;
    // padding: 0 0 2rem 0;
    // button {
    //   margin-right: 1rem;
    //   width: 110px;
    //   &:last-child {
    //     margin-right: 0;
    //   }
    // }
  }

  &--anytime-bail-bonds {
    .companyProfile__header {
      @include unicorn;
      &__info {
        display: none;
      }
    }
  }
}

.reviews {
  & > header {

  }

  .review {
    margin-bottom: 1rem;
    border-bottom: 2px solid #A2AFCD;
    padding-bottom: 1rem;

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }

    & > header {
  
    }

    &__content {

    }

    & > footer {
      p {
        font-size: .85rem;
      }
    }
  }
  
}