@import "styles/basics/mixins";
@import "styles/basics/vars";

.paymentForm {
  &__row {
    @include flex;
    margin-bottom: 1rem;
    gap: .5rem;

    &>div {
      flex: 1;
      margin: 0;
    }

    &__address {
      &>div {
        flex: 0 1 auto;

        &:first-child {
          width: 70%;
        }

        &:last-child {
          width: 30%;
        }
      }
    }
  }
}

.actions {
  margin-top: 1rem;
  @include flex;
  gap: .5rem;

  button {
    flex: 1;
  }
}