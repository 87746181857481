.edit {
  position: absolute;
  top: 50%; right: 10px;
  transform: translateY(-50%);
  padding: 0;
  border: 0;
  background: transparent;
  font-size: 1.25rem;
  color: $grey;
  &:hover {
    color: $navy;
  }
}

.checkIt {
  display: inline-block;
  width: 16px; height: 16px;
  input {
    display: none;
    & + label {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0; left: 0;
        display: inline-block;
        border: 1px solid $grey;
        width: 16px; height: 16px;
        border-radius: 3px;
      } 
      &:after {
        display: none;
        content: '\f00c';
        position: absolute;
        top: 2px; left: 3px;
        width: 16px; height: 16px;
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
        color: $grey;
      } 
    }
    &:checked + label {
      &:before {
        background: $grey;
      } 
      &:after {
        display: inline-block;
        color: #FFF;
      } 
    }
  }
}

.costBreakdown {
  margin-top: .5rem;
  margin-bottom: .5rem;
  &__row {
    @include bp(Mama) {
      @include flex;
      margin-bottom: .5rem;
    }
    &__left {
      width: 100%;
      @include flex;
      flex-direction: column;
      @include bp(Mama) {
        @include flex;
        flex-direction: row;
        margin-bottom: .5rem;
      }
      & > * {
        flex: 1;
        width: 100%;
      }
    }
    &__right {
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: .5rem;
      i {
        color: $grey;
        margin-right: .25rem;
        font-size: 1.25rem;
      }
    }
  }
  &__breakdown {
    &__fees  {
      height: 0;
      border: 1px solid $lightGrey;
      border-top: 0;
      border-bottom: 0;
      transition: height 0.3s ease-out;
      overflow: hidden;
      button {
        position: relative;
        width: 100%; height: 40px;
        padding: 0;
        background: $lightGrey;
        border: 0;
        font-size: 1.5rem;
        outline: none;
        i {
          position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%, -50%);
          color: $navy;
        }
        &:hover {
          background: $navy;
          i {
            color: #FFF;
          }
        }
      }
    }
    .addFeeAdder {
      position: relative;
      .addFeeAdder__adder {
        position: absolute;
        top: -11px; left: 50%;
        transform: translateX(-50%);
        width: 24px;
        height: 24px;
        background: #FFF;
        border: 1px solid #CCC;
        border-radius: 50%;
        color: $grey;
        &:hover {
          background: $navy;
          color: #FFF;
        }
        i {
          position: absolute;
          top: 50%; left: 50%;
          margin-top: 1px;
          transform: translate(-50%, -50%);
          font-size: .95rem;
        }
      }
    }
    &__feesEdit {
      position: relative;
      @include flex;
      flex-direction: column;
      row-gap: .5rem;
      border: 1px solid $lightGrey;
      background: #FFF;
      border-top: 0;
      padding: .5rem;
      background: #EFEFEF;

      @include bp(Mama) {
        flex-direction: row;
      }

      .deleteConfirm {
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background: rgba(255,255,255,.9);

        @include flex;
        padding: 0 1rem;

        &__left {
          position: relative;
          padding-left: 3rem;
          h3 {
            margin: 0;
            color: $teal;
          }
          p {
            margin: 0;
            font-size: .85rem;
          }
          i {
            position: absolute;
            top: 0; left: 0;
            font-size: 2.5rem;
            color: $teal;
          }
        }
        &__right {
          button {
            margin-right: .5rem;
            outline: none;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .options {
        width: 100%;
        label {
          display: block;
          color: #6F7F9F;
          margin-bottom: 4px;
          font-size: .875rem;
        }
        & > div {
          @include bp(Mama) {
            max-width: 280px;
          }
        }
      }
        
      &__options {
        @include bp(Kid) {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
    
        & > div {
          @include bp(Mama) {
            margin-right: 1.5rem;
          }
          &:first-child {
            width: 100%;
            @include bp(Mama) {
              width: 45%;
            }
          }
          &:last-child {
            margin-right: 0;
          }
          .inputRow__block {
            margin-bottom: 0;
          }
        }
      }

      &__left {
        width: 100%;
        @include bp(Mama) {
          width: calc(100% - 85px);
        }

        .tags {
          margin: .5rem 0 0;
          padding: 0;
        }
      }

      &__right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        @include bp(Mama) {
          width: 85px;
        }
        button {
          border: 0;
          background: transparent;
          padding: 0;
          margin-right: .5rem;
          outline: none;
          &:last-child {
            margin-right: 0;
          }
          i {
            font-size: 1.5rem;
            &.fa-check-circle {
              color: $teal;
            }
            &.fa-times-circle {
              color: $grey;
            }
            &.fa-trash {
              color: $danger;
              font-size: 1.25rem;
            }
          }
        }
      }
    }

    &__row {
      @include flex;
      align-items: stretch;
      // margin: 1rem 0;

      @include bp(Kid) {
        align-items: center;
        margin: 0;
      }

      border: 1px solid $lightGrey;
      background: #FFF;
      border-top: 0;
      label {
        @include unicorn;
        padding: .5rem;
        font-size: .75rem;
        @include bp(Mama) {
          font-size: 1rem;
        }
        span {
          color: $lightGrey;
        }
        div.pill {
          display: none;
          @include bp(Mama) {
            display: inline-block;
          }
        }
      }

      &--collateralType {
        padding: 0 .5rem;
        .inputRow__block {
          margin: 0;
        }
      }
      
      input {
        padding: 0 1rem 0 0;
        border: 0;
        background: #FAFAFA;
        outline: none;
        height: 35px;
        line-height: 35px;
        border-left: 1px solid $lightGrey;
        text-align: right;
        font-size: 1rem;
      }
      strong {
        padding-right: 1rem;
      }
      &:first-child {
        border-top: 1px solid $lightGrey;
      }

      .pill {
        margin-left: .5rem;
      }

      &__right {
        button {
          margin: 0 .5rem 0 0;
          padding: 0;
          border: 0;
          background: transparent;
          color: $grey;
          outline: none;
          &:hover {
            color: $navy;
          }
        }
      }

      &.isRebate {
        color: $danger;
        input {
          color: $danger;
        }
      }

      .bondPremium {
        @include flex;
        justify-content: flex-end;

        &__quick {
          display: none;
          margin-right: .25rem;
          border: 1px solid $grey;
          background: $grey;
          border-radius: 3px;
          @include bp(Mama) {
            display: block;
          }
          button {
            width: 35px; height: 24px;
            padding: 0;
            border: 0;
            border-right: 1px solid $lightGrey;
            background: transparent;
            font-weight: 700;
            color: #FFF;
            outline: none;
            &.active {
              background: $navy;
            }
            &:last-child {
              border-right: 0;
            }
            &:hover {
              background: $navy;
            }
          }
        }
      }
    }
    &__actions {
      @include flex;
      padding: .5rem 0 1.5rem;
      button {
        width: 49%;
        outline: none;
      }
    }
  }
}