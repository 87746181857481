@import "styles/basics/vars";

.buttHelp {
  margin-left: 0;
  font-size: 1.5rem;
  border: 2px solid $navy;
  background: $navy;
  border-radius: 5px;
  padding: 4px 8px 0;

  &:hover {
    background: $white;
    color: $grey;
  }

  // when the body has the .widget--on class (global, no funky css
  // module suffixes), don't display this component
  body:global(.widget--on) & {
    display: none !important;
  }
}
