.layout--magicPayment {
  main {
    padding-top: 0 !important;
  }
}

.paymentPage {
  margin: 0 auto;
  max-width: 664px;
  padding: 0 1rem;
  @include bp(Mama) {
    padding: 0 2rem;
  }

  header {
    margin-bottom: 1rem;

    .companyProfile__header__info {
      h1 {
        text-align: center;
      }
      .companyProfile__address {
        p {
          text-align: center;
        }
      }
    }
  }

  &__received {
    @include flex;

    & > div {
      width: 50%;
    }
  }

  &__detailsPod {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    border: 2px solid $lightGrey;
    background: #fff;
    border-radius: 5px;
    padding: 1rem;

    input {
      @include flex;
      @include input;
    }

    &__left {
      width: 60%;
    }

    &__right {
      width: 40%;
      @include flex;
      margin-left: 1rem;
      input {
      }
      button {
        margin-left: 0.5rem;
      }
    }

    &--added {
      @include flex;
      font-size: 1.25rem;
      color: $grey;
    }
  }

  &__cardForm {
    padding: 0 1rem;
  }
}

.paymentForm {
  border: 2px solid #dfe6f4;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 0;
  padding: 1rem;
  background: #fafafa;

  .formRow {
    .inputRow__block {
      width: 100%;
    }

    &.formRow--2up {
      .inputRow__block {
        width: 100%;
        &:first-child {
          margin-right: 0.5rem;
        }
        &:last-child {
          margin-left: 0.5rem;
        }
      }
    }
  }

  &__actions {
    @include flex;

    button {
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.paymentPagePod {
  margin-bottom: .5rem;
  .customers__customer--date {
    position: absolute;
    top: 50%; left: 0;
    transform: translateY(-50%);
    margin-left: 0;
  }
  &__top {
    @include pod;
    margin: 0;
    background: #FAFAFA;
    &__left {
      position: relative;
      padding-left: 3rem;

      h4 {
        font-weight: 400;
        strong {
          font-weight: 700;
        }
      }

      p {
        font-size: 0.75rem;
      }
    }
    &__right {
      @include flex;
      justify-content: flex-end;
      .tags {
        margin: 0 0.5rem 0 0;
      }
    }
  }

  &__btm {
    @include flex;
    margin: 0 1rem;
    border: 2px solid $lightGrey;
    border-top: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: .5rem;

    .customers__container {
      width: 100%;
    }

    p {
      margin: 0;
      font-size: .825rem;
    }

    &--noPadding {
      padding: 0;
      .customers__container {
        .ais-SearchBox {
          padding: .5rem;
          background: #FAFAFA;
        }
      }
    }
  }

  &__expand {
    padding: 0;
    background: transparent;
    border: 0;
    color: $grey;
    font-size: 1rem;
    cursor: pointer;
  }

  &--assigned {
    .paymentPagePod__top {
      border-color: $teal;
    }
  }

  &--notAssigned {
    .paymentPagePod__top {
      border-color: $danger;
    }
  }
}

.assignHits {
  border-top: 1px solid $lightGrey;
  ul li {
    border-bottom: 1px solid $lightGrey;
    &:last-child {
      border-bottom: 0;
    }
  }
  &__hit {
    @include flex;
    padding: .5rem;
    h4 {
      margin: 0;
    }
    &__right {
      @include flex;
      justify-content: flex-end;
      gap: .5rem;
      .tags {
        margin: 0;
      }
    }
  }
  &__actions {
    @include flex;
    padding: .5rem;
    gap: .5rem;
    border-top: 1px solid $lightGrey;
    & > button {
      flex: 1;
    }
  }
}
