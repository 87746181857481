$max-width: 664px;

.infoBox {
  padding: .5rem;
  // background: #EFEFEF;
  label {
    display: block;
    font-size: .75rem;
    color: $grey;
  }
  p {
    margin: 0;
  }
  &__container {
    margin-bottom: 1rem;
    // padding: 1rem;
    background: #FAFAFA;
    border: 2px solid $lightGrey;
    border-radius: 10px;
    overflow: hidden;
  }
  &__row {
    border-bottom: 1px solid $lightGrey;
    &:last-child {
      border-bottom: 0;
    }
    &--2up {
      @include flex;
      & > div {
        width: calc(100% / 2);
        border-right: 2px solid $lightGrey;
        &:last-child {
          border-right: 0;
        }
      }
    }
    &--3up {
      @include flex;
      flex-direction: column;
      @include bp(Mama) {
        flex-direction: row;
        align-items: stretch;
      }
      & > div {
        width: 100%;
        border-bottom: 2px solid $lightGrey;
        @include bp(Mama) {
          width: calc(100% / 3);
          border-bottom: 0;
          border-right: 2px solid $lightGrey;
        }
        &:last-child {
          border-right: 0;
          border-bottom: 0;
        }
      }
    }
    &--courtBlock {
      overflow: hidden;
      .courthouseBlock {
        margin: 0;
        border: 0;
        background: transparent;
        button {
          display: none;
        }
      }
    }
  }

  ol {
    margin: .25rem 0 0;
    padding: 0 0 0 1.2rem;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    row-gap: 1rem;

    > div {
      width: 100% !important
    }

    button {
      @include bp(Kid) {
        min-width: 100px;
        margin-left: .5rem;
      }  
    }
  }
  &--places {
    padding: .5rem;
  }
}

.inmateList {
  &__container {
    padding: 1rem 0 3rem;
    h5 {
      margin: 0 0 .5rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $grey;
    }
  }
  &__row {
    @include pod;
    &__left {
      p {
        font-size: .75rem;
      }
    }
    &__right {
      p {
        font-size: .75rem;
      }
    }
  }
}

.inmateDetail {
  header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: .5rem;
    border: 2px solid $lightGrey;
    background: #FFF;

    .inmateDetail__header__top {
      @include flex;
      span {
        font-size: .7rem;
        color: $grey;
        strong {
          display: block;
          font-size: 1rem;
          color: #000;
        }
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
      }
    }

    h2 {
      margin: 0;
      padding-bottom: .5rem;
      text-align: center;
    }
  }
  &__warrants {
    border: 2px solid $lightGrey;
    border-top: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    h5 {
      padding: .5rem;
      border-bottom: 2px solid $lightGrey;
      background: $grey;
      color: #FFF;
    }
  }
  &__warrant {
    @include pod;
    border-radius: 0;
    margin-bottom: 0;
    padding: .5rem .5rem;
    border: 0;
    border-bottom: 2px solid $lightGrey;
    &:last-child {
      border-bottom: 0;
    }
    &__left {
      p {
        font-size: .75rem;
      }
    }
    &__right {
      p {
        margin: 0;
        text-align: right;
        strong {
          display: block;
          font-size: .7rem;
          color: $grey;
        }
      }
    }
  }
  &__actions {
    @include flex;
    padding: 1rem 0;
    button {
      width: 49.5%;
    }
  }
}

.caseTabs {
  height: 41px;
  background: $grey;
  button {
    position: relative;
    border: 0;
    background: transparent;
    padding: .5rem 0;
    font-size: 1.25rem;
    font-weight: 500;
    color: #A2AFCD;
    outline: none;

    &.active {
      color: #061387;
    }
  }

  &__sections {
    // max-width: $max-width;
    width: 100%;
    margin: 0 auto;
    background: #FFF;
    // border-left: 1px solid #DFE6F4;
    // border-right: 1px solid #DFE6F4;
    
    &__container {
      display: flex;
      min-height: calc(100vh - 57px - 113px);
      // max-width: 864px;
      max-width: 100%;
      border-right: 1px solid $lightGrey;
    }
  }
  &__section {
    padding: 1rem;
    @include bp(Mama) {
      padding: 2rem;
    }

    &__nav {
      @include flex;
      align-items: center;
      margin-bottom: 1rem;
      button {
        min-width: 70px;
      }
      & > div {
        &:last-child {
          button {
            margin-left: .5rem;
          }
        }
      }
    }

    &--status {
      padding: 0;

      p {
        padding: 0 1rem;
      }

      .mask__container {
        p {
          padding: 0;
        }
      }
    }

    h3 {
      @include flex;
      margin: 0 0 1rem;
      color: $navy;

      strong.red {
        color: $danger;
      }

      button {
        height: 30px;
      }
    }

    &--boxItOut {
      margin-top: 10px;
      padding: 1rem;
    }
    &--wizard {
      padding: 0;
    }
    &--boxItOutTop {
      border-top: 0;
    }
    &--noPadding {
      padding: 1rem 0;
      @include bp(Mama) {
        padding: 2rem 0;
      }
      h3 {
        padding: 0 1rem;
        @include bp(Mama) {
          padding: 0 2rem;
        }
      }
    }

    &__defendant {
      border-bottom: 0;
      h3 {
        & > div {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          button.edit {
            position: static;
            margin-left: 1rem;
            transform: none;
          }
        }
      }
      .register__form {
        max-width: 100%;
        margin: 0; padding: 0;
      }
    }

    &__finances {
      border-top: 0;
      padding-top: 0;
      h5 {
        position: relative;
        margin: 0 0 1rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $grey;
        button {
          right: 0;
        }
      }
    }

    &__courtHouse {
      h3 {
        position: relative;
      }
    }
  }
}

.officeContainer {
  margin-bottom: 1rem;
  padding: 1rem;

  &__inner {
    @include flex;
    padding: .5rem;
    border-radius: 5px;
    background: $navy;

    h5 {
      margin: 0 0 .25rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #FFF;
    }

    p {
      margin: 0;
      font-size: .85rem;
      color: #FFF;
    }
  }

  &__left {
    width: calc(100% - 180px);
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    min-width: 180px;

    .gatewayTile {
      padding: .25rem .5rem;
      background: $teal;
      border-radius: 2px;
      text-transform: uppercase;
      font-size: .85rem;
      color: #FFF;
      font-weight: 700;
      margin-right: 0;

      &--invalid {
        background: $danger;
      }
    }
  }

  &--multiple {
    .officeContainer__inner {
      display: block;

      .officeContainer__top {
        @include flex;
      }

      .officeContainer__btm {
        margin-top: .5rem;

        .inputRow__block {
          margin: 0;
        }
      }

      .inputRow__block__selectAnswer {
        background: #FFF;
      }
    }
  }

  &--invalid {
    .officeContainer__inner {
      background: $danger;

      .gatewayTile {
        background: #FFF;
        color: $danger;
      }
    }
  }
}

.defendantCourthouse, .defendantHighLevelInfo, .defendantCharges {
  padding: 0 1rem;
  @include bp(Mama) {
    padding: 0;
  }
}

.defendantTasks {
  margin-top: 2rem;
  & > p {
    font-size: .825rem;
  }
  &__inner {
    position: relative;
    margin-bottom: 1rem;
    padding: 1rem;
    // border: 2px solid $warning;
    background: $teal;
    border-radius: 10px;
    color: #FFF;
    padding: 1rem 10rem 1rem 5rem;

    i {
      position: absolute;
      top: 50%; left: 1rem;
      transform: translateY(-50%);
      font-size: 3rem;
    }

    button {
      position: absolute;
      top: 50%; right: 1rem;
      transform: translateY(-50%);
    }
  }

  &__content {
    h3 {
      margin: 0 0 .5rem;
    }
    p {
      margin: 0;
      font-size: .825rem;
    }
  }
}

.checkInSchedule {
  position: relative;
  overflow-y: hidden;
  height: 140px;
  &:after {
    content: '';
    position: absolute;
    bottom: 0; left: 0;
    width: 100%; height: 60px;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
  }
  &--viewMore {
    height: auto;
    overflow-y: visible;
    padding-bottom: 30px;
    &:after {
      display: none;
    }
  }
  button.viewMore {
    position: absolute;
    bottom: 0; left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
  }
  table, th, td {
    border: 1px solid $lightGrey;
  }
  thead {
    tr {
      background: $grey;
      th {
        color: #FFF;
        padding: .5rem;
        font-size: .825rem;
      }
    }
  }
  tbody {
    tr:nth-child(odd) {
      background: #FAFAFA;
    }
  }
  td {
    // border-right: 1px solid #DFE6F4;
    padding: 5px;
    font-size: .85rem;
    color: $grey;
    &:nth-child(1) { width: 120px; }
    &:nth-child(3) { width: 80px; text-align: center; }
  }
  p.noResults {
    margin-bottom: 1rem;
    padding: 0 0 1rem;
    border-bottom: 1px solid $lightGrey;
  }


  &--modal {
    height: 200px;
    overflow-y: scroll;
  }
}

.switch--notifyIndemnitor {
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  div.switch {
    margin-right: .5rem;
  }
  p {
    margin: 0;
  }
}

.courtHouse {
  &__dates {
    & > p {
      font-size: .825rem;
    }
    &__date {
      // @include pod;
      // cursor: pointer;
      // @include bp(Mama) {
      //   padding: 0;
      // }
      &:hover {
        box-shadow: 0 0 5px rgba(43, 56, 102, .25),  0 0 5px rgba(43, 56, 102, 0);
      }
      &__left {
        width: calc(100% - 264px);
        p {
          margin: 0;
          font-size: .825rem;
        }
      }
      &__right {
        width: 240px;
        button {
          border: 0;
          background: transparent;
          padding: 0;
          color: $grey;
          font-size: 1.5rem;
          cursor: pointer;
          &:hover {
            color: $navy;
          }
        }
      }
      .dateTile {
        margin-right: 0.5rem;
        padding: 0.25rem 0.5rem;
        // min-width: 120px;
        background: $teal;
        border-radius: 2px;
        text-transform: uppercase;
        font-size: .85rem;
        color: #FFF;
        font-weight: 700;
        text-align: center;
        white-space: nowrap;
        &--known {
          background: $navy;
        }
        &--unknown {
          background: $danger;
        }
      }
    }
    &__add {
      font-family: Rubik;
      width: 100%;
      background: #FAFAFA;
      border: 2px solid $lightGrey;
      padding: .5rem 0;
      font-size: 2rem;
      color: $grey;
      cursor: pointer;
      border-radius: 5px;
      &:hover {
        color: $navy;
        background: #EFEFEF;
        border-color: $grey;
      }
    }
  }
  // .infoBox__row--3up {}
  .notifications {
    margin-bottom: 1rem;
    padding: .5rem;
    border: 2px solid $lightGrey;
    background: #FAFAFA;
    p {
      margin: 0 0 .5rem;
      font-size: .85rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__intro {
    p {
      font-size: .85rem;
    }
    &__actions {
      button {
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }
  &__details {
    p {
      font-size: .85rem;
    }
    .register__form {
      max-width: 100%;
      margin: 0; 
      padding: 0;
      .infoBox__actions {
        @include flex;
      }
    }
  }
  &__info {
    & > p {
      font-size: .85rem;
    }
    &__editActions {
      padding-bottom: 1rem;
      text-align: right;
      button {
        margin-right: .5rem;
      }
    }
  }
  &__checkin {
    margin-top: 1rem;
    border-top: 2px solid $lightGrey;
    padding-top: 1rem;

    header {
      position: relative;
      h5 {
        @include flex;
      }
      p {
        font-size: .825rem;
      }
      button {
        position: absolute;
        top: 0; right: 0;
        padding: 0;
        background: transparent;
        border: 0;
        font-size: 1.25rem;
        color: $grey;
        &:hover {
          color: $navy;
        }
      }
    }

    .noResults {
      margin: 0;
      padding-bottom: .5rem;
      font-size: .825rem;
      &__container {
        text-align: center;
      }
    }

    .infoBox__actions {
      justify-content: space-between;
    }
  }
  &__additional {
    margin-top: 1.5rem;

    header {
      p {
        margin-bottom: 0;
        font-size: .825rem;
      }
    }

    .noResults {
      margin: 0;
      padding-bottom: 2rem;
      font-size: .825rem;
    }

    .error {
      border-radius: 3px;
      padding: 8px 10px
    }

    &__list {
      margin-top: 1rem;
      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 1rem;
      }
      table, th, td {
        border: 1px solid $lightGrey;
      }
      thead {
        tr {
          background: $grey;
          th {
            color: #FFF;
            padding: .5rem;
            font-size: .825rem;
          }
        }
      }
      tbody {
        tr:nth-child(odd) {
          background: #FAFAFA;
        }
      }
      td {
        // border-right: 1px solid #DFE6F4;
        padding: 5px;
        font-size: .85rem;
        color: $grey;
        &:nth-child(1) { width: 150px; }
        &:nth-child(2) {
          @include flex;
          button {
            border: 0;
            background: transparent;
            padding: 0;
            color: $grey;
            &:hover {
              color: $navy;
            }
          }
        }
      }
    }

    .register__form {
      margin: 0 0 1rem;
      padding: 0;
      max-width: 100%;
      .form-row {
        @include flex;
        .inputs {
          @include flex;
          width: 87%;
          & > div {
            &:nth-child(1) { width: 30% }
            &:nth-child(2) { width: 69% }
          }
          &.inputs--other {
            & > div {
              &:nth-child(1) { width: 20% }
              &:nth-child(2) { width: 30% }
              &:nth-child(3) { width: 48% }
            }
          }
        }
        .actions {
          @include flex;
          width: 11%;
          padding-top: 1.25rem;
          button {
            padding: 0;
            border: 0;
            background: transparent;
            font-size: 1.25rem;
            color: $grey;
            &:hover {
              color: $navy;
            }
          }
        }
        .inputRow__block {
          margin: 0;
        }

        &--adding {
          .inputs {
            width: 91%;
          }
          .actions {
            width: 7%;
            button.delete {
              display: none;
            }
          }
        }
      }
    }

  }
}

.courthouseBlock {
  position: relative;
  @include flex;
  align-items: stretch;
  margin-bottom: 1rem;
  border: 1px solid $lightGrey;
  background: #FAFAFA;

  & > button {
    position: absolute;
    top: 50%; right: .5rem;
    transform: translateY(-50%);
    width: 80px;
  }
  
  &__left {
    @include unicorn;
    padding-top: .25rem;
    width: 70px;
    background: $teal;
    // img {
    //   width: 40px;
    //   height: 40px;
    // }
    i {
      font-size: 2.5rem;
      color: #FFF;
    }
  }

  &__right {
    width: calc(100% - 50px);
    padding: .5rem;
    h5 {
      margin: 0;
    }
    p {
      margin: 0;
    }
  }
}

.places {
  &__container {

  }
  &__autocomplete {
    label {
      display: block;
      color: #6F7F9F;
      margin-bottom: 4px;
      font-size: .75rem;
      @include bp(Mama) {
        font-size: .875rem;
      }
    }
  }
  &__map {
    
  }
  &__office {
    @include flex;
    align-items: flex-start;
    border: 1px solid $lightGrey;
    background: #FAFAFA;
    margin-bottom: 1rem;
    & > div {
      width: 50%;
      padding: .5rem;
      &:first-child {
        // padding-right: .5rem;
        border-right: 1px solid $lightGrey;
      }
      // &:last-child {
      //   padding-left: .5rem;
      // }
      p {
        margin: 0;
      }
    }
    h5 {
      @include flex;
      margin: 0 0 .5rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $grey;

      button {
        border: 0;
        background: transparent;
        padding: 0;
        color: $grey;
        &:hover {
          color: $navy;
        }
      }
    }
    &__actions {
      @include flex;
      margin-top: 1rem;
      & > button {
        flex: 1;
        margin-right: .5rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.infoPacket__timeline {
  padding-bottom: 10rem;
  @include bp(Mama) {
    padding-bottom: 0;
  }
}

.infoPacket--editor {

  width: 100%;

  .caseTabs__section {
    padding: 0;
  }
}

.ruleViolations {
  margin-bottom: 0;
  padding: 1rem 0;
  border-top: 1px solid $lightGrey;
  border-bottom: 6px solid #EFEFEF;
}

.radNotice {
  position: relative;
  display: block;
  width: 100%;
  padding: 1rem 7.4rem 1rem 4.75rem;
  text-align: left;
  border-radius: 4px;
  border: 0;
  margin-bottom: .5rem;
  background: $navy;

  strong {
    display: block;
    font-size: 1.25rem;
    color: #FFF;
  }
  p {
    margin: 0;
    color: #FFF;
    font-size: .85rem;
  }

  i {
    position: absolute;
    top: 50%; left: 1rem;
    margin-top: 2px;
    transform: translateY(-50%);
    color: #FFF;
    font-size: 3rem;
  }

  button {
    position: absolute;
    top: 50%; right: 1rem;
    transform: translateY(-50%);
  }

  &--documents {
    margin-top: 1rem;
    background: $danger;
  }
}

.question__container {
  margin: 0 0 1.5rem;
  padding: 0 0 0 20px;
  li {
    margin: 0 0 4px;
    text-align: left;
    font-size: .85rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  // border-top: 6px solid #EFEFEF;
  border-bottom: 1px solid $lightGrey;
  background: #FFF;

  &__container {
    padding: 0 6px;
    background: #EFEFEF;
    border-top: 6px solid #EFEFEF;
    // border-bottom: 6px solid #EFEFEF;
    // body.notifierOn & {
    //   border-top: 6px solid #EFEFEF;
    // }
  }

  &__toggle {
    @include flex;
    display: none;
    margin-top: 6px;
    padding: 1rem;
    background: #FFF;
    border: 4px solid $blue;
    @include bp(Mama) {
      display: flex;
    }

    h4 {
      margin: 0 0 5px;
      .pill {
        margin-left: .5rem;
        i { padding-right: 1px; }
      }
    }
    p {
      margin: 0;
      font-size: .825rem;
    }

    &__left {
      width: calc(100% - 91px);
    }

    &__right {
      width: 75px;
    }
  }

  h4 {
    margin: 0 0 5px;
    &.action {
      color: $teal;
    }
  }
  p {
    margin: 0;
  }

  &--isActionable {
    // padding: 0 .5rem;
    border: 4px solid $teal;
  }

  &--isActionableError {
    // padding: 0 .5rem;
    border: 4px solid $danger;
    h4.action {
      font-weight: 700;
      color: $danger;
    }
  }
  
  & > div {
    &:first-child {
      @include bp(Mama) {
        width: calc(100% - 70px);
      }
      p {
        margin: 0 0 1rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &:last-child {
      width: 50px;
      display: none;
      @include bp(Mama) {
        display: block;
      }
      i {
        font-size: 3rem;
        color: $grey;
      }
    }
  }
}

.finalize {
  &__status {
    margin-bottom: 1rem;

    label {
      display: block;
      margin-bottom: .25rem;
      font-size: .75rem;
      color: $grey;
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      width: 49.5%;
      margin: 0;
      font-size: .85rem;
      font-weight: 700;
    }
  }
}

.checklist__container {
  border-top: 6px solid #EFEFEF;
  padding: 1rem;
}

.case {
  &.indemnitors--6up {
    .nav_container nav {
      max-width: 664px;
    }
  }
  &.indemnitors--7up {
    .nav_container nav {
      max-width: 730px;
    }
  }
  &.indemnitors--8up {
    .nav_container nav {
      max-width: 790px;
    }
  }
  &.indemnitors--9up {
    .nav_container nav {
      max-width: 850px;
    }
  }
  &.indemnitors--10up {
    .nav_container nav {
      max-width: 850px;
    }
  }
}

.viewPdfModal {
  margin: 0 auto;
  max-width: 964px;
  border-left: 2px solid $lightGrey;
  border-right: 2px solid $lightGrey;
  padding: 90px 1rem 1rem;
  background: #EFEFEF;
  overflow-y: scroll;
  height: calc(100vh - 57px);
  @media screen and (min-width: 964px) {
    padding: 2rem;
  }

  p {
    display: none;
    margin: 0 0 2rem;
    @media screen and (min-width: 964px) {
      display: block;
    }
  }

  &__close {
    position: fixed;
    top: 65px; left: 1rem;
    z-index: 1005;
    background: #CCC;
    border: 0;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    color: #FFF;

    @media screen and (min-width: 964px) {
      left: 50%;
      transform: translateX(-50%);
      margin-left: -430px;
    }

    &:hover {
      background: $navy;
    }

    i {
      font-size: 2rem;
      display: block;
    }
  }

  &__container {
    position: fixed;
    top: 57px; left: 0;
    z-index: 1002;
    width: 100%; height: calc(100vh - 57px);
    background: rgba(255,255,255,.7);
  }

  .react-pdf__Page {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.agentFeeBlock {
  margin-right: .5rem;
  border: 2px solid $navy;
  border-radius: 4px;
  overflow: hidden;

  &__container {
    display: flex;
    justify-content: space-between;

    input[type="text"] {
      width: 100px !important;
    }
  }

  button {
    border: 0;
    border-right: 2px solid $navy;
    margin: 0;
    padding: 0 8px;
    height: 34px;
    background: #FFF;
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: 700;
    color: $navy;
    &:hover {
      background: $navy;
      color: #FFF;
    }
    &:last-child {
      border: 0;
    }
  }
}

button.editButton {
  margin-left: .5rem;
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  i {
    color: $grey;
  }
  &:hover i { color: $navy }
}

.adjustmentRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__editor {
    textarea, input[type="password"], input[type="text"], input[type="number"], input[type="tel"], input[type="email"], .DayPickerInput input {
      padding: 10px;
      width: 100%;
      border: 1px solid #DFE6F4;
      border-radius: 5px;
      font-size: 1rem;
      outline: none;
      -webkit-appearance:none;
      @include bp(Mama) {
        font-size: .875rem;
      }
  
      &:focus {
        border-color: #24C0AC;
      }
  
      &.formBlock--valid {
        border-color: $blue;
      }
      &.formBlock--warning {
        border-color: $warning;
      }
      &.formBlock--danger {
        border-color: $danger;
      }
    }

    textarea {
      min-height: 100px;
    }
    &__actions {
      display: flex;
      justify-content: flex-end;
      margin-top: .5rem;
      button {
        margin-right: .5rem;
        height: 28px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &--edit {
    display: block;
    padding: 1rem 0;
    border-top: 1px solid $lightGrey;
    border-bottom: 1px solid $lightGrey;
    .adjustmentRow__title {
      font-size: .85rem;
      margin-bottom: 1rem;
      display: block;
    }
    .adjustmentRow__content {
      button {
        display: none;
      }
    }
  }

  &__holdingJail {
    position: relative;
    padding: 8px 1rem 8px 1rem;
    margin-bottom: 0;
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    transition: $base-transition;
    &:hover {
      border: 1px solid $lightGrey;
    }

    // .savedAlert {
    //   opacity: 0;
    // }

    &--saved {
      .savedAlert {
        opacity: 1;
      }
    }

    &__left {
      p {
        margin: 0;
      }
    }
    &__right {
      padding-right: 1.5rem;
      .search_site_url {
        color: $teal;
        font-size: .75rem
      }
      button.editButton {
        position: absolute;
        top: 50%;
        right: 11px;
        transform: translateY(-50%);
        pointer-events: none;
        font-size: .85rem;
        color: #6F7F9F;
        pointer-events: none;
      }
    }

    &--active {
      margin-bottom: 1rem;
      &:hover {
        border: 1px solid transparent;
      }
      .adjustmentRow__holdingJail__left {
        width: calc(100% - 120px);

        & > div.inputRow__block {
          width: 100%;
          margin-right: 1rem;
          &:last-child {
            margin-right: 0;
          }
        }

        @include bp(Kid) {
          display: flex;
          justify-content: space-between;
          align-items: center;

          & > div {
            width: 49%;
            margin-bottom: 0;
            margin-right: 0;
          }  
        }    

      }
      .adjustmentRow__holdingJail__right {
        padding: 20px 0 0;
        button {
          margin-right: 5px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    &--city {
      flex-direction: column;
      .adjustmentRow__holdingJail__left {
        width: 100%;
        .inputRow__block {
          width: 32%;
        }
      }
      .adjustmentRow__holdingJail__right {
        width: 100%;
        padding-top: .5rem;
        button {
          width: 49%;
        }
      }
    }
  }
}

.financeRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
  padding: .5rem 0;
  
  &__content {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &--rebate {
      span {
        color: $danger;
      }
    }
  }

  &--enabled {
    display: block;
    strong {
      margin-bottom: .5rem;
    }
  }

  &__form {
    padding: .5rem 0;
    input[type="password"], input[type="text"], input[type="number"], input[type="tel"], input[type="email"], .DayPickerInput input {
      padding: 10px;
      width: 100%;
      border: 1px solid #DFE6F4;
      border-radius: 5px;
      font-size: 1rem;
      outline: none;
      -webkit-appearance:none;
      @include bp(Mama) {
        font-size: .875rem;
      }
  
      &:focus {
        border-color: #24C0AC;
      }
  
      &.formBlock--valid {
        border-color: $blue;
      }
      &.formBlock--warning {
        border-color: $warning;
      }
      &.formBlock--danger {
        border-color: $danger;
      }
    }

    &__actions {
      margin-top: .5rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        margin-right: .5rem;
        height: 30px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &--enabled {
    border-top: 1px solid #DFE6F4;
    border-bottom: 1px solid #DFE6F4;
  }
}

.caseInitialDecision {
  border-bottom: 1px solid $lightGrey;
  padding: 1rem;
  @include bp(Mama) {
    padding: 2rem;
  }
  &__container {
    border-bottom: 6px solid #EFEFEF;
    border-top: 6px solid #EFEFEF;
  }
  h2 {
    margin: 0 0 1rem;
  }
  p {
    margin: 0 0 1rem;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      width: 49%;
      font-size: 1rem;
      font-weight: 700;
    }
  }
}

.employeeSelect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 1rem;
  padding: .5rem 1rem;
  border-bottom: 1px solid $lightGrey;

  label {
    display: block;
    font-size: .85rem;
  }

  & > label {
    width: 150px;
  }
  & > div#assignee {
    width: calc(100% - 150px);
  }
}

.case {
  background: #FAFAFA;
  min-height: calc(100vh - 57px);

  button {
    cursor: pointer;
  }

  .caseTabs__sections__container {

  }
  
  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: .5rem;
    button {
      width: 49%;
      &:last-child {
        margin-right: 0;
      }
    }
    &--side {
      justify-content: flex-end;
      button {
        width: auto;
      }
    }
  }

  .logs {

    &_top-filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin:0 1rem 1% 0;

    }

    &_toggleButt{
      background-color: $grey;
      color: white;
      height: 25px;
      width: 25px;
      border: none;
      border-radius: 5px;
    }

    &_hide {
      display: none;
    }

    &_cell{
      padding: 0.875rem;
      border: $lightGrey solid 2px;
      border-radius: 5px;
      margin:0 1rem 1% 1rem;
    }

    &_names {
      font-size: 0.675rem;
    }

    &_logitem{
      display: flex;
      width: 250px;
    }

    &_title {
      margin-left: 1rem;
      font-size: 2rem;
      color: #2B3866;
    }

    &_filter-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &_top {
      display: flex;
      justify-content: space-between;
      align-self: center;
    }

    &_subtype {
      color: $teal;
      font-size: 0.75rem;
      font-weight: 600;
    }

    &_infobox{
      display: flex;
      justify-content: space-between;      
      border-top: rgba(0, 0, 0, 0.10) solid 1px;
      margin: 2% 0 0 0;
      padding: 5px 0 0 0;
    }

    &_label {
      font-size: 0.8rem;
      margin: 4px 0 0 0;
      padding: 0;
      color: $grey;
    }
  }
  
  .checklist {
    &__container {
      margin: 0 auto;
    }
    h3 {
      margin-bottom: .7rem;
    }
    &__row__actions {
      i {
        font-size: 3rem;
        &.fa-check-circle, &.fa-arrow-circle-right {
          color: $teal;
        }
        &.fa-clock, &.fa-cogs {
          color: $warning;
        }
        &.fa-times-circle {
          color: $danger;
        }
        &.fa-circle-exclamation {
          color: $orange;
        }
      }
    }

    &__row {
      &--indemnitorRow {
        display: block;
      }

      &__top {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      &__bottom {
        margin-top: 1rem;
        border-top: 1px solid #DFE6F4;
        padding-top: 1rem;
      }
      &__indemnitorOptions {
        position: relative;
        margin-bottom: 1rem;
        padding-left: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:before {
          content: '';
          position: absolute;
          top: 4px; left: 16px;
          z-index: 1;
          background: #DFE6F4;
          width: 3px; height: 10px;
        }

        &:after {
          content: '';
          position: absolute;
          top: 4px; left: 12px;
          z-index: 2;
          background: #DFE6F4;
          width: 11px; height: 11px;
          border-radius: 50%;
        }

        h4 {
          margin: 0 0 .5rem;
        }
        
        p {
          margin: 0;
        }

        &:first-child {
          &:before {
            top: 4px;
            height: 52px;
          }
          &:after {
            top: 4px;
          }
        }

        &:last-child {
          margin-bottom: 0;
          &:before {
            top: 0;
            height: 6px;
          }
          &:after {
            top: 4px;
          }
        }

        button {
          margin-left: .5rem;
        }
      }
    }

    .closeCase, .passOnCase, .radBox {
      position: relative;
      display: block;
      width: 100%;
      padding: 1rem 7.4rem 1rem 4.75rem;
      text-align: left;
      border-radius: 4px;
      border: 0;
      margin-bottom: .5rem;

      strong {
        display: block;
        font-size: 1.25rem;
        color: #FFF;
      }
      p {
        margin: 0;
        color: #FFF;
      }

      i {
        position: absolute;
        top: 50%; left: 1rem;
        margin-top: 2px;
        transform: translateY(-50%);
        color: #FFF;
        font-size: 3rem;
      }

      button {
        position: absolute;
        top: 50%; right: 1rem;
        transform: translateY(-50%);
      }

      &--grey {
        background: $grey;
  
        button {
          background: #FFF;
          color: $grey;
          border-color: #FFF;
          &:hover {
            background: #EFEFEF;
            border-color: #EFEFEF;
          }
        }
      }
    }

    .closeCase {
      background: $navy;
      p {
        padding: 0;
      }
    }

    .passOnCase {
      background: $danger;

      button {
        background: #FFF;
        color: $danger;
        border-color: #FFF;
        &:hover {
          background: #EFEFEF;
          border-color: #EFEFEF;
        }
      }
    }

    &__footer {
      &--tiles {
        @include flex;
        flex-direction: column;
        align-items: stretch;
        @include bp(Mama) {
          flex-direction: row;
        }
        & > div {
          @include bp(Mama) {
            width: 49%;
          }
        }
      }
    }

    // OLD
    // &__footer {
    //   @include flex;
    //   flex-direction: column;
    //   align-items: stretch;
    //   @include bp(Mama) {
    //     flex-direction: row;
    //   }

    //   &--2up {
    //     & > div {
    //       @include bp(Mama) {
    //         width: 49%;
    //       }
    //     }
    //   }
    // }
  }


}

.indemnitor__actions {
  position: absolute;
  top: 120px; left: 700px;
  z-index: 2;
  width: 100px;
  
  button {
    display: block;
    margin-bottom: 1rem;
    background: #CCC;
    border: 0;
    width: 100px; height: 100px;
    border-radius: 50%;
    &:hover {
      background: $navy;
    }
    span {
      color: #FFF;
    }
    i {
      display: block;
      font-size: 2rem;
      color: #FFF;
    }
    &:disabled {
      margin: 0 auto;
      width: 75px; height: 75px;
      font-size: .65rem;
      background: #DDD;
      &:hover {
        background: #DDD;;
      }
      i {
        font-size: 1.5rem;
      }
    }
  }

  body.notifierOn & {
    // display: none;
    top: 310px;
  }
}

// Layout Specific

body.template-case {
  #root main {
    padding-top: 57px;
    padding-bottom: 0;
    min-height: 100vh;
  }
  .caseTabs__section--indemnitor {
    background: #FAFAFA;
  }
  .case__section--status {
    padding: 0;
  }

  #visualForm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
}

.adjustmentRow--charges {
  display: block;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 8px 1rem;
  cursor: pointer;
  margin-bottom: 0;
  &:hover {
    border-color: $lightGrey;
  }
  .savedAlert {
    right: 16px;
  }
  .adjustmentRow__title {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .5rem;

    .editButton {
      position: absolute;
      top: 50%;
      right: -6px;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }
  .adjustmentRow__content {
    display: block;
    span {
      display: block;
    }
    ol {
      margin: 0;
      padding: 0 0 0 1.2rem;
    }
  }
  .adjustmentRow__form {
    p.small {
      margin: 0;
      text-align: left;
    }
  }
  &.isSaved {
    .savedAlert {
      opacity: 1;
    }
  }
}

.caseFinalize {
  padding: 0 1rem 1rem;
}

.flyout__mask {
  position: fixed;
  top: 0; left: 0;
  width: 100%; height: 100vh;
  z-index: 8;
  background: rgba(255,255,255,.1);
}

.consentPopup {
  position: relative;
  margin-bottom: 1rem;
  padding: 1rem 1rem 1rem 4rem;
  background: $grey;
  border-radius: 10px;
  color: #FFF;
  h3 {
    margin: 0 0 .25rem;
  }
  p {
    margin: 0;
    font-size: .825rem;
  }
  i {
    position: absolute;
    top: 1rem; left: .5rem;
    font-size: 3rem;
    color: #FFF;
  }
  &__actions {
    margin-top: .5rem;
    button {
      margin-right: .5rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &--error {
    background: $danger;
    button {
      border-color: #FFF;
      background-color: #FFF;
      color: $danger;
      &:hover {
        background-color: $danger;
        color: #FFF;
      }
    }
  }
}

.infoPacket {
  padding: 0;
  min-height: 1250px;

  @include bp(Mama) {
    padding: 0;
  }

  &__fieldDisabled {
    margin-left: .5rem;
    font-size: .75rem;
    font-weight: 700;
    color: $danger;
    text-transform: uppercase;
  }

  &__isPayer {
    margin-top: 1rem;
  }

  &__inOfficeCode {
    display: block;
    margin: 1.5rem 0 0;
    padding: .5rem;
    background: $teal;
    border-radius: 4px;
    color: #FFF;
    @include bp(Mama) {
      display: inline-block;
    }

    a {
      margin-left: .5rem;
      color: #FFF;
      &:hover {
        color: $lightGrey;
      }
    }
  }

  .timeline {
    margin-top: 0;
  }

  &__header {
    padding: 1rem;
    border: 1px solid $lightGrey;
    border-radius: 10px;
    background-color: white;

    @include bp(Mama) {
      padding: 2rem;
    }

    &__titleActions {
      margin: 0 0 1rem;
      h2 {
        display: flex;
        justify-content: space-between;
        margin: 0 0 .5rem;
        align-items: flex-start;
        color: $navy;
        @include bp(Mama) {
          margin: 0;
        }

        .pill {
          padding-left: 26px;
          @include bp(Mama) {
            margin-left: .5rem;
          }
          &--red {
            cursor: pointer;
          }
          &--yellow {
            cursor: pointer;
            background-color: $orange;
            &:hover {
              background-color: $warning;
            }
          }
          & > span {
            position: absolute;
            top: 50%; left: 2px;
            transform: translateY(-50%);
          }
        }
      }
    }
    &__actions {
      position: relative;
      // body.beta-layout-temp-body & {
      //   @include bp(BigPapa) {
      //     display: none;
      //   }
      // }
      button {
        border: 0;
        background: transparent;
        font-size: 2rem;
        color: $grey;
        outline: none;
        &:hover {
          color: $navy;
        }
      }
      .flyout {
        position: absolute;
        top: 0; right: 0;
        z-index: 9;
        border: 1px solid $lightGrey;
        background: #FAFAFA;
        width: 200px;

        header {
          text-align: right;
          button {
            // float: right;
            font-size: 1.5rem;
          }
        }

        & > button, & > a {
          display: block;
          width: 100%;
          padding: .5rem;
          font-size: 1rem;
          text-align: left;
          border-bottom: 1px solid $lightGrey;
          text-decoration: none;
          color: $grey;
          outline: none;
          &:hover {
            color: $navy;
          }
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        width: 49.5%;

        p {
          margin: 0 0 1rem;
          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          color: $teal;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      &--form {
        display: block;
        .notice {
          position: absolute;
          top: 50%; left: 0;
          transform: translateY(-50%);
          width: 10px;
          height: 10px;
          background: $danger;
          border-radius: 50%;
          &__container {
            position: relative;
            padding-left: .825rem;
          }
          &--on {
            background: $completed;
          }
        }
        & > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          gap: .5rem;
          width: 100%;

          @include bp(Toddler) {
            flex-direction: row;
            align-items: center;

            .formBlock {
              width: 49.5%;
            }

            &:last-child {
              .formBlock {
                margin-bottom: 0;
              }
            }
          }
        }
        .formBlock--phone {
          position: relative;
          button.resendConsent {
            display: none;
            position: absolute;
            bottom: 4px; right: 4px;
            height: 30px;
            @include bp(Mama) {
              display: block;
            }
          }
        }
      }
      &__actions {
        @include flex;
        & > button {
          width: 49.5%;
        }
      }
    }
    &__status {
      padding-top: 1.5rem;

      &__block {
        
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        @include bp(Mama) {
          flex-direction: row;
        }

        width: 100%; min-height: 74px;

        margin-bottom: 1rem;
        padding: 1rem;
        border: 1px solid $lightGrey; 
        border-radius: 10px;
        background: #FAFAFA;

        &:last-child {
          margin-bottom: 0;
        }

        .content {
          width: 100%;
          h4 {
            margin: 0 0 .25rem;
          }
          p {
            margin: 0;
            font-size: .75rem;
          }
        }

        &:hover .btm {
          margin-top: 72px;
        }

        .actions {
          width: 100%;
          margin-top: .5rem;
          @include bp(Mama) {
            width: auto;
            margin-top: 0;
          }
          .viewButton {
            display: inline-block;
            width: 100%;
            // @include bp(Mama) {
            //   display: none;
            // }
          }
          .downloadButton {
            display: none;
            @include bp(Mama) {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  &__information {
    padding: 1rem 1rem 2rem;
    margin: 10px 0;
    background-color: white;
    border-radius: 10px;
    border: 1px solid $lightGrey;
    @include bp(Mama) {
      padding: 1.25rem 2rem 2rem;
    }

    h5 {
      @include flex;
      button {
        // border: 0;
        // padding: 0;
        // background: transparent;
        // font-size: 1.25rem;
        // color: $grey;
        &:hover {
          color: $navy;
        }
      }
    }
    &__actions {
      @include flex;
      padding-top: 1rem;
      & > button {
        width: 49.5%;
      }
    }
  }

  &__uploads {
    padding: 1rem 1rem 2rem;
    background-color: white;
    border-radius: 10px;
    border: 1px solid $lightGrey;
    @include bp(Mama) {
      padding: 1.25rem 2rem 2rem;
    }

    h5 {
      @include flex;
      flex-direction: column;
      align-items: flex-start;

      @include bp(Kid) {
        flex-direction: row;
        align-items: center;
      }
  
      button {
        height: 30px;
        margin-top: 0.5rem;

        @include bp(Kid) {
          margin-top: 0;
        }
      }
    }
  }

  &__documents {
    background-color: white;
    border-radius: 10px;
    border: 1px solid $lightGrey;
    padding: 1rem 1rem 2rem;
    margin: 10px 0;
    @include bp(Mama) {
      padding: 1.25rem 2rem 2rem;
    }
    .filesArea__files {
      padding: 0;
    }

    h5 {
      @include flex;
      flex-direction: column;
      align-items: flex-start;

      @include bp(Kid) {
        flex-direction: row;
        align-items: center;
      }
  
      button {
        height: 30px;
        margin-top: 0.5rem;

        @include bp(Kid) {
          margin-top: 0;
        }
      }
    }
  }

  &__finalize {
    border-top: 6px solid #EFEFEF;
    padding: 2rem;
    border-bottom: 1px solid $lightGrey;

    p {
      padding: 0;
    }
  }

  &__row {
    position: relative;
    @include flex;
    text-align: left;
    padding: .3rem 0;

    &:before {
      display: none;
      content: '';
      position: absolute;
      top: 50%; left: 0;
      width: 99%; height: 0;
      border: 1px dotted $lightGrey;
    }

    &:hover {
      &:before {
        @include bp(Mama) {
          display: block;
        }
      }
    }

    .numCompleted {
      margin-right: .25rem;
      font-size: .75rem;
      color: $grey;
    }

    &__label {
      position: relative;
      z-index: 1;
      background: #FFF;
      padding-right: 1rem;
      i {
        margin-right: .5rem;
        color: $grey;
        cursor: pointer;
      }

      &--uploads {
        h4 {
          margin: 0 0 4px;
        }
        p {
          margin: 0;
          font-size: .7rem;
        }
      }
    }

    &__actions {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: #FFF;
      min-width: 101px;
      // margin: 0.25rem 0 0.5rem;

      @include bp(Kid) {
        padding-left: 1rem;
        margin: 0;
      }

      .tags {
        margin: 0;
        .tag {
          line-height: 2;
        }
        &.tagsMandatory {
          margin-left: 5px;
        }
        &.tagsFlags {
          margin-right: 5px;
        }
      }
      .resetButton {
        margin-right: 4px;
        height: 24px;
      }
    }
  }

  &__viewTypeActions {
    @include flex;
    padding: 1rem;
    background: #EFEFEF;

    body.beta-layout-temp-body & {
      display: none !important;
    }

    button {
      position: relative;
      width: 49.5%;
      &.active {
        &:after, &:before {
          top: 100%;
          left: 50%;
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-color: rgba(239, 239, 239, 0);
          border-top-color: $blue;
          border-width: 10px;
          margin-left: -10px;
        }

        &:before {
          border-color: rgba(223, 230, 244, 0);
          border-top-color: $blue;
          border-width: 11px;
          margin-left: -11px;
        }
      }
    }
  }

  &__viewable {
    background: #EFEFEF;
    &__buttonContainer {
      position: relative;
    }
    &__menu {
      position: absolute;
      top: 34px; right: 4px;
      z-index: 100;
      min-width: 250px;
      min-height: 100px;
      border: 2px solid $lightGrey;
      border-radius: 4px;
      background: #FFF;
      padding: .5rem;

      button {
        display: block;
        border: 0;
        background: transparent;
        padding: 0;
        margin-bottom: .5rem;
        line-height: 1;
        width: 100%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &__add {
      display: flex;
      justify-content: flex-end;
      padding: 0 1rem;
      button {
        @include flex;
        margin-right: .25rem;
        height: 30px;
      
        &:last-child {
          margin-right: 0;
        }
      
        span {
          font-weight: 700;
        }
      
        i {
          margin-right: .25rem;
          font-size: 1rem;
        }
      }
    }
  }
}

.caseNotifier {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem;
  color: #FFF;
  @include bp(Mama) {
    padding: 1.5rem;
    flex-direction: row;
  }

  &__container {
    background: #FFF;
    border-bottom: 1px solid $lightGrey;
    background: $grey;
  }

  & > div {
    @include bp(Mama) {
      width: calc(100% - 126px);
    }
  }

  h2 {
    margin: 0 0 .5rem;
  }
  p {
    margin: 0;
  }

  button {
    width: 110px;
    font-size: 1rem;
  }

  &__actions {
    button {
      width: 100%;
    }
  }

  &--decision {
    display: block;
    & > div:first-child {
      margin-bottom: .5rem;
    }
    button {
      width: 100%;
      margin-bottom: .5rem;
      @include bp(Mama) {
        width: auto;
        margin-right: 1rem;
        margin-bottom: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

$buttonWidth: 60px;

.infoNav {
  @include flex;
  align-items: stretch;
  width: 100%;
  margin-top: 1rem;
  padding-bottom: .5rem;

  button {
    position: relative;
    padding: .5rem 1rem;
    width: $buttonWidth;
    color: #FFF;
    background: $grey;
    border: 0;
    text-align: left;
    opacity: .7;
    border-right: 1px solid $lightGrey;
    flex-grow: 1;
    cursor: pointer;

    @include bp(Mama) {
      padding: 1rem 1.5rem;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 0;
    }

    &:hover {
      opacity: 1;
    }

    strong {
      position: relative;
      font-size: 1.25rem;
      left: 2.25rem;
    }
    
    p {
      position: relative;
      margin: 0;
      font-size: .7rem;
      left: 2.25rem;
    }
    
    i {
      position: absolute;
      top: 0.5rem; left: 50%;
      font-size: 2rem;
      top: 50%; left: 2rem;
      transform: translate(-50%, -50%);
    }

    span {
      position: absolute;
      top: 50%; right: 10px;
      transform: translateY(-50%);
      z-index: 1;
      width: 26px; height: 26px;
      background: $danger;
      border-radius: 50%;
      color: #FFF;
      text-align: center;
      line-height: 25px;
      font-weight: 700;
      font-size: 1rem;
      border: 1px solid #FFF;
    }

    &.active {
      background: $navy;
      opacity: 1;

      // strong,
      // p {
      //   display: block;
      // }
      // i {
      //   left: .5rem;
      //   transform: translateY(-50%);
      // }

      &:after,
      &:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &:after {
        border-color: rgba(239, 239, 239, 0);
        border-top-color: $navy;
        border-width: 10px;
        margin-left: -10px;
      }

      &:before {
        border-color: rgba(223, 230, 244, 0);
        border-top-color: $navy;
        border-width: 11px;
        margin-left: -11px;
      }
    }
  }

  &--hide {
    display: none;
  }
}

.caseTabs__section--paymentPlan {
  h3 {
    @include flex;

    & > div {@include flex;
      justify-content: flex-start;
      span {
        display: inline-block;
        margin-right: 1rem;
      }
    }

    // button {
    //   border: 0;
    //   background: transparent;
    //   padding: 0;
    //   line-height: 0;
    //   color: $grey;
    //   i {
    //     font-size: 2rem;
    //   }
    //   &:hover {
    //     color: $navy;
    //   }
    // }
  }

  .invalidNotice {
    text-align: left;
  }

}

.imageUploads {
  &__files {
    &__fileGroup {
      &--2up {
        @include bp(Mama) {
          @include flex;
        }

        & > div {
          @include bp(Mama) {
            width: 49%;
          }
        }

      }
      &__file {
        margin-bottom: 1rem;
        @include bp(Mama) {
          margin-bottom: 0;
        }
        img {
          width: 100%;
          height: auto;
        }
        a.pdfImage {
          @include unicorn;
          flex-direction: column;
          padding: 2rem;
          max-width: 400px;
          border: 2px solid $grey;
          background: #FAFAFA;
          border-radius: 10px;
          color: $navy;
          i {
            display: block;
            font-size: 3rem;
          }
        }
      }

      &--single {
        .imageUploads__files__fileGroup__file img {
          display: block;
          width: auto;
          max-width: 100%;
          margin: 0 auto;
        }
      }
    }
  }
}

.editContent {
  &__section {
    border-bottom: 1px solid $lightGrey;
    &:last-child {
      border-bottom: 0;
    }

    h4 {
      position: relative;
      @include flex;
      margin: 0;
      padding: 1rem;
      cursor: pointer;

      i {
        position: absolute;
        top: 50%; right: 1rem;
        transform: translateY(-50%);
        color: $grey;
      }

      .editContent__section__title__right {
        @include flex;
        padding-right: 2rem;
        margin-right: .25rem;
        font-size: .75rem;
        font-weight: 300;
        color: $grey;

        .tags {
          margin: 0 0 0 .5rem;
        }
      }

      &:hover {
        background: #FAFAFA;
      }
    }

    &__questions {
      display: none;
      background: #FAFAFA;
      padding: 1rem;
      margin: 0 1rem;
      border-left: 1px solid $lightGrey;
      border-right: 1px solid $lightGrey;
    }

    &__actions {
      @include flex;
      padding: 0 1rem 1rem;
      margin: 0 1rem;
      background: #FAFAFA;
      border-left: 1px solid $lightGrey;
      border-right: 1px solid $lightGrey;
      display: none;
      & > button {
        width: 49%;
      }
    }

    &--open {
      h4 {
        border-bottom: 1px solid $lightGrey;
        background: $grey;
        color: #FFF;
        .editContent__section__title__right {
          color: #FFF;
        }
        i {
          color: #FFF;
        }
        &:hover {
          background: $grey;
        }
      }
      .editContent__section__questions {
        display: block;
      }
      .editContent__section__actions {
        display: flex;
      }
    }
  }
  &__input {
    position: relative;
    margin-bottom: .65rem;

    @include bp(Kid) {
      padding-right: 2.5rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
    & > label {
      display: block;
      margin-bottom: 4px;
      font-size: .75rem;
      color: $grey;
      line-height: 24px;
      // padding: 6px 30px 6px 0;

      @include bp(Kid) {
        line-height: normal;
        padding: 0;
      }

      strong {
        color: #F30;
      }
    }
    .clearIt {
      position: absolute;
      top: 0; 
      right: 0;
      padding: 0;
      border: 0;
      background: transparent;
      outline: none;

      @include bp(Kid) {
        top: auto;
        bottom: 6px;
      }

      i {
        font-size: 1.5rem;
        color: $grey;
        &:hover {
          color: $navy;
        }
      }
    }
    .savedAlert {
      top: auto; bottom: 8px; right: 5px;
      transform: none;
      z-index: 99;
      i {
        margin-top: 1px;
      }
    }
    &__content {
      textarea, input[type="password"], input[type="text"], input[type="number"], input[type="tel"], input[type="email"], .DayPickerInput input {
        padding: 10px;
        width: 100%;
        border: 1px solid #DFE6F4;
        border-radius: 5px;
        font-size: 0.75rem;
        outline: none;
        -webkit-appearance:none;

        @include bp(Kid) {
          font-size: 1rem;
        }

        @include bp(Mama) {
          font-size: .875rem;
        }
    
        &:focus {
          border-color: #24C0AC;
        }
      }

      // &--invalid {
      //   input {
      //     background: #F30;
      //   }
      // }
      
      &--address {
        & > div {
          width: 100%;
        }
      }
      
      &--fullname {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include bp(Mama) {
          flex-direction: row;
        }

        input[type="text"] {
          width: 100%;
          margin-bottom: .5rem;
          @include bp(Mama) {
            width: 32%;
            margin-bottom: 0;
          }
        }
      }

      &--name {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include bp(Mama) {
          flex-direction: row;
        }

        input[type="text"] {
          &:first-child {
            margin-right: 1rem;
          }
        }
      }
      
      &--date {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include bp(Mama) {
          flex-direction: row;
        }

        & > div {
          margin-bottom: .5rem;
          font-size: 0.75rem;

          @include bp(Kid) {
            font-size: 1rem;
          }

          @include bp(Mama) {
            margin-bottom: 0;
          }
          &:nth-child(1) { 
            @include bp(Mama) {
              width: 50%;
            }
          }
          &:nth-child(2) { 
            @include bp(Mama) {
              width: 22%;
            }
          }
          &:nth-child(3) { 
            @include bp(Mama) {
              width: 25%;
            }
          }
        }
      }
      
      &--location {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include bp(Mama) {
          flex-direction: row;
        }
        input[type="text"] {
          margin-bottom: 1rem;
          @include bp(Mama) {
            margin-bottom: 0;
            width: calc(100% - 216px);
          }
        }
        & > div {
          margin-bottom: 1rem;
          width: 100%;
          font-size: 0.75rem;
          @include bp(Kid) {
            font-size: 1rem;
          }
          @include bp(Mama) {
            margin-bottom: 0;
            width: 200px;
          }
        }
      }

      &--lengthOfTime {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include bp(Mama) {
          flex-direction: row;
        }
        & > div {
          margin-bottom: 1rem;
          width: 100%;
          font-size: 0.75rem;
          @include bp(Kid) {
            font-size: 1rem;
          }
          @include bp(Mama) {
            margin-bottom: 0;
            width: 49%;
          }
        }
      }

      &--radio {
        @include flex;
        align-items: stretch;
        flex-direction: column;
        row-gap: 4px;

        @include bp(Kid) {
          flex-direction: row;
          align-items: center;
        }

        .radio {
          width: 140px;
          &__answer {
            @include bp(Kid) {
              width: calc(100% - 140px);
            }
          }
        }
      }

      &--address {
        @include flex;
        align-items: stretch;
        flex-direction: column;
        row-gap: 4px;

        @include bp(Kid) {
          flex-direction: row;
          align-items: center;

          input {
            &:first-child {
              width: calc(78%);
            }
            &:last-child {
              width: calc(20%);
            }
          }  
        }
      }
    }

    &--viewing {
      background: #EFEFEF;
      padding: .5rem;
      @include bp(Mama) {
        @include flex;
        background: transparent;
        padding: 0;
      }
      p {
        margin: 0;
      }
    }

    &--isError {
      label {
        color: $danger;
      }
      .editContent__input__content {
        textarea, input {
          border-color: $danger;
          &:focus {
            border-color: $danger;
          }
        }
      }
    }
    &--isSaved {
      .savedAlert {
        opacity: 1;
      }
    }
  }
}

.indemnitorBar {
  position: relative;
  @include flex;
  background: #FAFAFA;

  &__container {
    padding: 6px;
    background: #EFEFEF;
  }

  &__buttons {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: calc(100% - 80px);
  }

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding: 1rem 0;
    background: transparent;
    border: 0;
    color: $grey;
    color: #C9CDD7;
    text-align: center;
    max-width: 80px;
    border-right: 1px solid #EFEFEF;
    // &:last-child {
    //   border-right: 0;
    // }

    i {
      font-size: 2.5rem;
    }

    strong {
      margin-top: .25rem;
      display: inline-block;
      font-size: .75rem;
    }

    &.active {
      position: relative;
      color: #FFF;
      background: $grey;

      &:after, &:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      
      &:after {
        border-color: rgba(239, 239, 239, 0);
        border-top-color: $grey;
        border-width: 10px;
        margin-left: -10px;
      }
      &:before {
        border-color: rgba(223, 230, 244, 0);
        border-top-color: $lightGrey;
        border-width: 11px;
        margin-left: -11px;
      }

    }
    &:hover:not(.active) {
      color: $grey;
      background: #EFEFEF;
      &.active {

      }
    }
    &.addUser {

    }
    &.notify {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        margin: -15px 0 0 17px;
        z-index: 1;
        width: 14px; height: 14px;
        background: $danger;
        border-radius: 50%;
        border: 2px solid #FFF;
      }
    }
    &.message {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        bottom: 16px; right: 5px;
        z-index: 1;
        width: 16px; height: 16px;
        background: $danger;
        border-radius: 50%;
        border: 2px solid #FFF;
      }
    }
  }

  &__add {
    position: absolute;
    top: 0; right: 0;
    width: 80px; height: 100%;
    display: flex;
    border-left: 6px solid #EFEFEF;
    border-right: 0;
    
    button {
      &:hover {
        background: #FAFAFA;
      }
    }
  }
}

.userSearch {
  padding: 0 1rem 1rem;
  border-bottom: 1px solid $lightGrey;
  header {
    @include flex;
    padding: 1rem 0;
    h3 {
      margin: 0 0 .25rem;
      font-size: 1rem;
      color: $navy;
    }
    p {
      margin: 0;
      font-size: .85rem;
    }

    & > div {
      width: calc(100% - 100px);
    }
    button {
      width: 80px;
    }
  }
  form {
    display: block;
    position: relative;
    input[type="search"] {
      @include input;
    }
    button[type="submit"] {
      position: absolute;
      top: 4px; right: 4px;
      width: 30px; height: 30px;
      font-size: 1rem;
      text-align: center;
    }
  }
  &__users {
    margin: 0 .5rem;
    // padding-top: .5rem;
    border: 1px solid $lightGrey;
    border-top: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
  }
  &__user {
    @include pod;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid $lightGrey;
    margin-bottom: 0;
    &:last-child {
      border-bottom: 0;
    }
    p {
      font-size: .85rem;
    }
  }
}

.casePayments {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #DFE6F4;
  background: #EFEFEF;
  border-radius: 5px;
  width: 100%;

  h5 {
    @include flex;
    strong {
      letter-spacing: 1px;
      color: #000;
      font-weight: 700;
    }
  }

  &__payments {
    @include flex;
    justify-content: flex-start;
    gap: .5rem;
    padding: .5rem;
    background: #FFF;
    border-radius: 5px;
    margin-bottom: .5rem;
    h5 {
      margin-bottom: .5rem;
    }
  }

  &__cardInfo {
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 140px;
    padding: 0.5rem;
    text-align: right;
    label {
      display: inline-block;
      margin-bottom: 0.5rem;
      font-size: 0.75rem;
      color: #6F7F9F;
    }
    strong {
      display: inline-block;
      margin: 0;
      font-size: 1.25rem;
    }
    i {
      position: absolute;
      bottom: .5rem; left: .5rem;
      font-size: 1.5rem;
      color: $grey;
    }
  }

  &__tiles {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    &__tile {
      &__top {
        @include flex;
        padding: 0;
        border: 1px solid #DFE6F4;
        background: #FAFAFA;
        border-radius: 5px;
        text-align: right;
        &__left {
          display: flex;
          justify-content: flex-start;
          align-items: stretch;

          .receiptButton {
            position: relative;
            display: inline-block;
            width: 50px;
            border: 0;
            background: transparent;
            text-decoration: none;
            color: $grey;
            font-size: 1.5rem;
            border-right: 1px solid $lightGrey;
            &:hover {
              background: $grey;
              color: #FFF;
            }

            i {
              position: absolute;
              top: 50%; left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
        &__right {
          @include flex;
          gap: .25rem;
          padding: 0 0.5rem;
          .tags {
            display: flex;
            margin: 0;
            .tag:after {
              display: none;
            }
          }
        }

      }

      &__btm {
        margin: 0 1rem;
        padding: .5rem;
        border: 1px solid $lightGrey;
        border-top: 0;
        .caseTransactions {
          margin: 0 !important;
        }
        
      }

      &__bypass {
        .casePayments__tiles__tile__top {
          background: $blue;
          color: #FFF;
          label {
            color: #FFF;
          }
          i {
            color: #FFF;
          }
        }
      }
      &__right {
        @include flex;
        gap: .25rem;
        padding: 0 0.5rem;
        .tags {
          margin: 0;
          display: flex;
          .tag:after {
            display: none;
          }
        }
        button, a {
          height: auto;
          padding: .25rem .5rem;
          font-size: .825rem;
          font-weight: 700;
          text-decoration: none;
        }
      }
      &__bypass {
        background: $blue;
        color: #FFF;
        label {
          color: #FFF;
        }
        i {
          color: #FFF;
        }
      }
    }
  }
}

.caseTransactions {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #DFE6F4;
  background: #EFEFEF;
  border-radius: 5px;
  width: 100%;

  &__transactions {
    padding: .5rem;
    background: #FFF;
    border-radius: 5px;
    h5 {
      margin-bottom: .5rem;
    }
  }

  .transactions {
    padding: 0;
    &__transaction {
      &>div {
        &:first-child {
          .tags {
            margin: 0 .5rem 0 0;

            .tag::after {
              display: none;
            }
          }

          span {
            font-size: .825rem;
          }
        }

        &:last-child {
          font-size: .825rem;
        }
      }
    }
  }
}

.caseInvoices {
  margin-top: 1rem;
  &__invoice {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: 1px solid #DFE6F4;
    background: #EFEFEF;
    border-radius: 5px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    .instructions {
      margin: .5rem 0 0;
      background: #FFF;
    }
    
    p.noResultsFound {
      margin: 0;
      font-size: 1.1rem;
      color: $grey;
    }

    .transactions {
      padding: 0;
      &__transaction {
        & > div {
          &:first-child {
            .tags {
              margin: 0 .5rem 0 0;
              .tag::after {
                display: none;
              }
            }
            span {
              font-size: .825rem;
            }
          }
          &:last-child {
            font-size: .825rem;
          }
        }
      }
    }

    header {
      @include flex;
      margin-bottom: .5rem;
      & > div {
        &:first-child {
          @include flex;
          justify-content: flex-start;
          h4 {
            margin-left: .5rem;
          }
        }
        &:last-child {
          @include flex;
          justify-content: flex-end;
          button {
            height: 26px;
            margin-right: .5rem;
          }
          strong {
            font-weight: 700;
          }
          span {
            font-size: .75rem;
            margin-right: .5rem;
          }
          button {
            margin: 0 0 0 .5rem;
          }
        }
      }
      h4 {
        margin: 0;
      }
      div.tags {
        margin: 0;
        div.tag {
          font-size: .825rem;
          &:after {
            display: none;
          }
        }
      }
    }

    &__payments, &__transactions {
      padding: .5rem;
      background: #FFF;
      border-radius: 5px;
      h5 {
        margin-bottom: .5rem;
      }
    }

    &__payments {
      margin-bottom: .5rem;
    }

    &__actions {
      @include flex;
      // padding-top: .5rem;
      // & > button {
      //   width: 32%;
      // }
    }
  }

  .blockAction {
    padding: 1rem 10rem 1rem 5.5rem
  }
}

.case_payments {
  padding-top: 1rem;
  p.instructions {
    border-radius: 4px;
    padding: .5rem;
  }
  &__tiles {
    @include flex;
    justify-content: flex-start;
    .tile {
      position: relative;
      max-width: calc(100% / 3);
      i {
        position: absolute;
        bottom: .5rem; left: .5rem;
        font-size: 1.5rem;
        color: $grey;
      }
    }
    .tile:not(.tile--bypass) {
      background: #EFEFEF;
    }
    &--files {
      margin-top: .5rem;
      justify-content: space-between;
      .tile {
        max-width: 49.5%;
        cursor: pointer;
        transition: $base-transition;
        &:hover {
          border-color: $navy;
          box-shadow: 0 0 20px rgba(43, 56, 102, .25),  0 0 20px rgba(43, 56, 102, 0);
        }
      }
    }
  }
}

.notes {
  .timeline {
    padding: 0;
    &:after {
      top: 48px; left: 32px;
    }
    h3 {
      display: none;
    }
  }
}
