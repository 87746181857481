.releases {
  position: relative;
  max-width: 800px;
  padding: 0 2rem;
  margin: 0 auto;

  &__sidebar {
    width: 200px;
    position: fixed;
    padding: 3rem 0 0;
  }

  &__main {
    width: calc(100% - 200px);
    margin-left: 216px;
  }

  &__tab {
    border-bottom: 1px solid #DFE6F4;

    &:last-child {
      border-bottom: 0;
    }

    a {
      display: inline-block;
      font-size: 1.5rem;
      padding: 1rem 0;
      text-decoration: none;
      color: #6F7F9F;

      &:hover {
        color: $navy;
      }
    }
  }

  &__entry {
    padding: 3rem 0;
    border-bottom: 1px solid #DFE6F4;

    h2 {
      margin: 0 0 1rem;
      font-size: 1.5rem;
      color: $navy;
    }

    .block-img {
      background: #FAFAFA;
      border: 2px solid #EFEFEF;
      line-height: 0;
      padding: 1rem;
      text-align: center;
      height: 350px;
      overflow: hidden;

      img {
        max-height: 100%;
        width: auto;
      }
    }

    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}