@import "styles/basics/mixins";

.footerContainer {
  background: #e8ebfc;
  margin: 0 auto;
  padding: 2rem;
  max-width: 1200px;
}

.footerBottom {
  @include flex;
  flex-direction: column;
  text-align: center;
  @include bp(Mama) {
    flex-direction: row;
    text-align: right;
  }
  padding-top: 2rem;
}

.copyright {
  font-size: 0.85rem;
}

.legal {
  margin: 1rem 0 4rem;
  @include bp(Mama) {
    text-align: right;
    margin: 0;
  }
}
