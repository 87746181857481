@import "styles/basics/vars";
@import "styles/basics/mixins";

// NOTE: these styles are modeled after the Onboarding component's LoginButton
// we might want to adjust based on common primary button styles needed across the app
.butt {
  height: 36px;
  padding: 0 1rem;
  border: 2px solid $navy;
  background: $navy;
  font-size: 1rem;
  font-weight: 700;
  color: #FFF;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  font-family: Rubik !important;

  &:hover {
    background: #FFF;
    color: $navy;
  }

  &:disabled {
    opacity: 0.3;

    &:hover {
      background: #FFF;
      color: $grey;
    }
  }

  &__icon {
    @include unicorn;
    gap: .5rem;
    &__reverse {
      flex-direction: row-reverse;
    }
  }
  
  &__iconOnly {
    svg {
      margin-right: 0 !important;
    }
  }
  
  &__full {
    width: 100%;
  }

  &__small {
    height: 28px;
    font-size: .825rem;
    padding: 0 .5rem;
  }

  &__medium {
    height: 32px;
    font-size: .825rem;
    padding: 0 .5rem;
    svg {
      margin-right: .25rem;
    }
  }

  &__white {
    border-color: #fff;
    background: #fff;
    color: $grey;
    &:hover {
      background: $grey;
      color: #fff;
    }
  }

  &__red {
    border-color: $danger;
    background: $danger;
    color: #fff;
    &:hover {
      background: #fff;
      color: $danger;
    }
  }

  &__teal {
    border-color: #24c0ac;
    background: #24c0ac;
    color: #fff;
    &:hover {
      background: #fff;
      color: #24c0ac;
    }
  }

  &__navy {
    border-color: $navy;
    background: $navy;
    color: #fff;
    &:hover {
      background: #fff;
      color: $navy;
    }
  }

  &__grey {
    border-color: #999;
    background: #999;
    color: #fff;
    &:hover {
      background: #fff;
      color: #999;
    }
  }

  &__blue {
    border-color: $blue;
    background: $blue;
    color: #fff;
    &:hover {
      background: #fff;
      color: $blue;
    }
  }

  &__orange {
    border-color: $orange;
    background: $orange;
    color: #fff;
    &:hover {
      background: #fff;
      color: $orange;
    }
  }

  &__purple {
    border-color: $purple;
    background: $purple;
    color: #fff;
    &:hover {
      background: #fff;
      color: $purple;
    }
  }

  &__green {
    border-color: $green;
    background: $green;
    color: #fff;
    &:hover {
      background: #fff;
      color: $green;
    }
  }

  &__mutedRed {
    border-color: $mutedRed;
    background: $mutedRed;
    color: #fff;
    &:hover {
      background: #fff;
      color: $mutedRed;
    }
  }

  &__clear {
    padding: 0;
    background: transparent;
    border: 0;
    color: $grey;
    &:hover {
      color: $navy;
    }
  }
  
  &__whiteRed {
    border-color: #FFF;
    background: #FFF;
    color: $danger;
    &:hover {
      background: $danger;
      color: #FFF;
      border-color: #FFF;
    }
  }
  
  

  &__pulse {
    transform: scale(1);
    animation: pulse-orange 2s infinite;
  }
}

a.butt {
  @include unicorn;
}

@keyframes pulse-orange {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 171, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 171, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 171, 0, 0);
  }
}