.warehouseInvalidMessage {
  padding: 1rem;
  text-align: center;
  border: 2px solid $lightGrey;
  background: #FAFAFA;
  border-radius: .5rem;
}

body.template-warehouse {
  #root div.main > div {
    .layout {
      &__info {
        padding: 1.5rem 1rem;
      }
    }
  }
  div.main {
    & > div {
      background: #EFEFEF;
      .layout__info {
        max-width: 100%;
        padding-right: 30%;
      }
      .layout__info, .mainSearch {
        background: #FFF;
      }
    }
  }
}

.noSections {
  margin: 5rem auto 0;
  max-width: 400px;
  border: 4px solid $lightGrey;
  background: #FFF;
  padding: 2rem;
  text-align: center;
  border-radius: 10px;

  h3 {
    margin: 0;
    font-size: 1.5rem;
    color: $grey;
  }

  &__actions {
    @include flex;
    width: 100%;
    button {
      width: 49.5%;
    }

    &--1up {
      button {
        width: 100%;
      }
    }
  }
}

.warehouse {
  &__add {
    @include bigAddButton;
  }

  &__sections {
    @include flex;
    padding: .5rem;
    button {
      position: relative;
      padding: .5rem;
      width: 49.5%;
      background: #FFF;
      border: 2px solid $grey;
      border-radius: 4px;
      font-size: 1.1rem;
      font-weight: 600;
      color: $grey;
      outline: none;
      cursor: pointer;

      &.active {
        &:after, &:before {
          top: 100%;
          left: 50%;
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
  
        &:after {
          border-color: rgba(255, 255, 255, 0);
          border-top-color: #FFF;
          border-width: 15px;
          margin-left: -15px;
        }
  
        &:before {
          border-color: rgba(111, 127, 159, 0);
          border-top-color: $grey;
          border-width: 18px;
          margin-left: -18px;
        }
      }
    }
  }

  &__header {
    padding: 2rem 1rem;
    h2 {
      margin: 0 0 .5rem;
      color: $navy;
    }
    p {
      margin: 0;
      color: $grey;
    }

    &--flex {
      @include flex;
    }

    &__left {
      width: calc(100% - 110px);
    }
    &__right {
      width: 110px;
      text-align: right;
    }
  }

  &__options {
    margin-bottom: 1rem;
    padding: 0 1rem;
  }

  &__area {
    padding: 0 1rem 10rem;
  }

  &__section {
    &__top {
      @include pod;
      &__left {
        @include flex;
        justify-content: flex-start;
        button {
          margin-right: .5rem;
          // width: 20px;
          border: 0;
          background: transparent;
          padding: 0;
          color: $grey;
          cursor: pointer;
          i {
            font-size: 1rem;
          }
          &:hover {
            color: $navy;
          }
        }
        h4 {
  
        }
        p {
          font-size: .825rem;
        }
      }
      &__right {
        position: relative;
        .tags {
          @include flex;
          justify-content: flex-end;
          margin: 0;
          .tag {
            margin-right: 4px;
            &:after {
              display: none;
            }
          }
        }
        & > button {
          // width: 20px;
          border: 0;
          background: transparent;
          padding: 0;
          cursor: pointer;
          color: $grey;
          &:hover {
            color: $navy;
          }
          i {
            font-size: 1.5rem;
          }
        }
  
        .flyout {
          position: absolute;
          top: 0; right: 0;
          z-index: 9;
          border: 1px solid $lightGrey;
          background: #FAFAFA;
          width: 200px;
  
          header {
            text-align: right;
            button {
              // float: right;
              font-size: 1.5rem;
            }
          }
  
          & > button, & > a {
            display: block;
            width: 100%;
            padding: .5rem;
            font-size: 1rem;
            text-align: left;
            border-bottom: 1px solid $lightGrey;
            text-decoration: none;
            color: $grey;
            outline: none;
            &:hover {
              color: $navy;
            }
            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
    }
    &__btm {
      @include pod;
      flex-direction: column;
      border-top: 0;
      margin: 0 1rem 1rem;
      & > div {
        width: 100%;
        .inputRow__block {
          margin-bottom: .5rem;;
        }
      }
    }

    &__switches {
      @include flex;
      &__left {
        @include flex;
        justify-content: flex-start;
        .switchPod {
          @include flex;
          margin-right: .5rem;
          label {
            display: inline-block;
            margin-left: .25rem;
            font-size: .75rem;
          }
        }
      }
      &__right {
        button {
          height: 30px;
          margin-right: .5rem;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    &__add {
      @include bigAddButton;
    }

    &__addSection {
      @include pod;
      &__left {
        width: 90%;
        .inputRow__block {
          margin: 0;
          width: 100%;
        }
      }
      &__right {
        width: 10%;
        button {
          border: 0;
          background: transparent;
          padding: 0;
          font-size: 1.25rem;
          cursor: pointer;
          i {
            &.fa-circle-check { color: $teal; }
            &.fa-circle-xmark { color: $grey; }
          }
        }
      }
    }

    &--editing {
      .warehouse__section__top {
        margin-bottom: 0;
      }
    }
  }

  // &__area {
  //   @include flex;
  //   align-items: flex-start;
  //   background: #FFF;
  //   border-top: 1px solid $lightGrey;
  //   &__sections {
  //     width: 200px;
  //     min-height: 100%;
  //     button {
  //       display: block;
  //       width: 100%;
  //       padding: .5rem;
  //       border: 0;
  //       background: transparent;
  //       border-bottom: 1px solid $lightGrey;
  //       text-align: left;

  //       h4 {
  //         margin: 0 0 .25rem;
  //       }
  //       p {
  //         margin: 0;
  //         padding: 0;
  //       }
  //     }
  //   }
  //   &__questions {
  //     padding: 1rem;
  //     width: calc(100% - 200px);
  //     border-left: 1px solid $lightGrey;

  //     &__header {
  //       margin-bottom: 1rem;
  //       h4, p {
  //         margin: 0;
  //       }
  //     }
  //   }
  // }

  // &__question {
  //   @include pod;

  //   .tags {
  //     margin: 0 1rem 0;
  //     .tag {
  //       &:after {
  //         display: none;
  //       }
  //     }
  //   }
  // }
}

.question_builder {
  max-width: 100%;
  padding-bottom: 0;
  
  background: #FFF;
  border-radius: 10px;
  padding: 1rem;

  &__container {
    padding: 0 1rem;
  }

  &__header {
    margin-bottom: 1rem;
    h2 {
      color: $grey;
    }
    p {
      margin: 0 0 1rem;
      font-size: .825rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__content {
      background: #FFF;
      border-radius: 10px;
      padding: 1rem;
    }
  }

  &__sample {
    border: 1px solid $lightGrey;
    padding: 1rem;
    margin-bottom: 1rem;
    background: #FFF;
    border-radius: 10px;
    .questionnaire__question {
      max-width: 100%;
    }
  }

  &__actions {
    @include flex;
    button {
      width: 49%;
    }
  }

  .radio {
    height: 38px;
    display: flex;
    align-items: center;
  }
}

.securityBanner {
  @include flex;
  padding: 1rem;
  background: $blue;
  color: #FFF;

  &__content {
    position: relative;
    padding-left: 4rem;
    i {
      position: absolute;
      top: 50%; left: 0;
      transform: translateY(-50%);
      font-size: 3rem;
    }
    h3 {
      margin: 0 0 .5rem;
      font-size: 1.25rem;
    }
  
    p {
      margin: 0;
    }
  }

  button {
    width: 80px;
    font-weight: 700;
    color: $danger;
    &:hover {
      background: $danger;
      color: #FFF;
    }
  }

  &--invalid {
    background: $danger;
    .securityBanner__content {
      width: calc(100% - 100px);
    }
  }
}

.warehouse__settings {
  padding: 1rem 1rem 4rem;

  &__actions {
    @include flex;
    & > button {
      width: 49.5%;
    }
  }
}

.settingsRow {
  margin-bottom: .5rem;
  @include flex;
  label {
    font-size: 0.825rem;
  }
  button {
    height: 24px;
  }
}