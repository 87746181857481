.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 57px;
  background: #fff;
  border-bottom: 1px solid #dfe6f4;

  body:global(.layout--magicCode) &,
  body:global(.layout--magicPayment) & {
    display: none;
  }
}
