@import '../../styles/basics/vars';
@import '../../styles/basics/mixins';

.paymentConfirm {

  max-width: 400px;
  background: #FFF;
  border-radius: 10px;
  border: 2px solid $lightGrey;
  padding: 2rem;

  h3 {
    margin: 0 0 .5rem;
    font-size: 1.25rem;
    color: $navy;
    text-align: center;
  }

  p:not(.instructions) {
    font-size: .825rem;
  }

  &__container {
    padding: 7rem 1rem 0;
  }

  &__total {
    margin-bottom: 1rem;
    padding: .5rem;
    border: 1px solid $lightGrey;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: $navy;
    background: #FAFAFA;
  }

  &__actions {
    @include flex;
    gap: .5rem;

    &>button {
      flex: 1;
    }
  }

  &__mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .4);

    @include unicorn;
    align-items: flex-start;
  }
}

.showMoreButton {
  border: 0;
  background: transparent;
  width: 100%;
  padding: .5rem 0;
  font-size: .825rem;
  font-weight: 700;
  color: $grey;
  cursor: pointer;
  &:hover {
    color: $navy;
  }
}

.bigTag {
  flex: 1;
  padding: .25rem .5rem;
  font-size: 1rem;
  font-weight: 700;
  color: #FFF;
  background: $blue;
  text-transform: uppercase;
  border-radius: 4px;
}

.publicKeyError {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  padding: 3rem 0 2rem;
  color: $danger;
}

.confirmPayment {
  width: 100%;
  padding: 1rem 0;

  & > div {
    text-align: center;

    h3 {
      margin: 1rem;
      font-size: 28px;
      color: $teal;
    }
  }

  svg {
    color: $teal;
    font-size: 64px;
    animation: pulse 2s;
    border-radius: 50%;
  }

  section {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  @keyframes pulse {
    0% {
        transform: scale(0.75);
        box-shadow: 0 0 0 0 $teal;
    }

    50% {
        transform: scale(1.25);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}
}

.paymentInfo {
  margin: 0;
  @include flex;
  align-items: center;
  font-family: "Inconsolata", monospace;
  font-size: 1.5rem;
  color: $grey;
  svg {
    font-size: 2rem;
  }
  span:first-child {
    @include flex;
    justify-content: flex-start;
    gap: .5rem;
  }

  &__container {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #EFEFEF;
  }
}

.billingInfo {
  font-family: "Inconsolata", monospace;
  font-size: 1.25rem;
  color: #181818;

  &__container {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #EFEFEF;
  }
}

.dialog {
  font-size: .75rem;
}

.paymentForm {
  &__row {
    @include flex;
    margin-bottom: 1rem;
    gap: .5rem;
    & > div {
      flex: 1;
      margin: 0;
    }

    &__address {
      & > div {
        flex: 0 1 auto;
        &:first-child {
          width: 70%;
        }
        &:last-child {
          width: 30%;
        }
      }
    }
  }
}

.paymentOptions {
  &__option {
    @include pod;
    &__left {
      p {
        font-size: .75rem;
      }
    }
    &__right {
      &>div {
        margin: 0;
      }
    }
  }
}

.paymentPage {
  margin-bottom: 1rem;
  padding: 1rem;
  max-width: 600px;
  width: 100%;
  background: #FAFAFA;
  // min-height: 300px;
  border: 1px solid $lightGrey;

  header {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #EFEFEF;
  }

  footer {
    margin-top: 1rem;
    button {
      font-size: 1rem;
      padding: 1.5rem 1rem;
      height: auto;
      width: 100%;
    }
  }

  &__actions {
    margin-top: 1rem;
    @include flex;
    gap: .5rem;
    button {
      flex: 1;
    }
  }

  &__totals {
    margin-top: 1rem;
    
    @include flex;
    gap: .5rem;
    & > div {
      flex: 1;
    }
  }

  &__profile {
    @include flex;
    margin-bottom: 1rem;
    &__left {
      @include flex;
      justify-content: flex-start;
      color: $grey;
      gap: .5rem;
      svg {
        font-size: 2.5rem;
        color: $grey;
      }
      h2 {
        margin: 0 0 .25rem;;
        font-size: 1.25rem;
      }
      p {
        margin: 0;
        font-size: .75rem;
      }
    }
  }

  &__detail {
    &__row {
      @include flex;
      & > div { flex-grow: 1; }
    }
    &__pod {
      font-family: "Inconsolata", monospace;
      label {
        font-size: .65rem;
      }
      p {
        margin: 0;
        font-weight: 700;
      }
    }
  }

  &__content {
    br {
      border-color: #000;
    }
  }

  &__payments {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: 0; left: 0;
      width: 100%;
      height: 60%;
      // background: #F30;
      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    }

    &__payment {
      margin: 0 0 .5rem;
      @include flex;
      gap: 1rem;
      font-family: "Inconsolata", monospace;

      &:last-child {
        margin-bottom: 0;
      }

      p {
        position: relative;
        margin: 0;
        padding-left: 1rem;
        font-size: .825rem;
      }
    }

    &__more {
      &::after {
        display: none;
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%; left: 0;
    transform: translateY(-50%);
    color: $teal;
    font-size: .75rem;

    &__pending {
      color: $orange;
    }

    &__error {
      color: $danger;
    }
  }

  &__template {
    background: #EFEFEF;
  }

  &__container {
    @include unicorn;
    align-items: flex-start;
    width: 100%;
    min-height: var(--vh, 100vh);
    padding: 3rem 1rem 1rem;
  }

  &__poweredBy {
    @include unicorn;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
    padding: 0 3rem;

    p {
      font-size: .825rem;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.totalRows {
  border-top: 2px solid #EFEFEF;
  padding-top: 1rem;

  p {
    font-size: 1.5rem;
  }
}

.invoiceInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    margin: 0;
    color: $grey;
  }

  h3 {
    margin: 0 0 10px;
  }

}



.lineItems {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px;

  border: 1px dashed $lightGrey;
  border-radius: 10px;

  h3 {
    padding: .75rem;
    width: 100%;
    border-bottom: 1px dashed $lightGrey;
    margin: 0;
    font-size: 16px;
  }

  &_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    padding: 0 .75rem;

    &:last-child {
      border-top: 1px dashed $lightGrey;
    }

    label {
      width: 100%;
      font-weight: 700;
      font-size: 14px;
      padding: .75rem 0;
    }

    h4 {
      font-size: 14px;
      text-transform: capitalize;
      margin: 0;
    }
  
    p {
      color: #3c3f45;
      font-weight: 100;
      margin: 0;
    }

  }


}

.surchargeNotice {
  font-size: 12px;
  color: grey;

  strong { color: black; }
}