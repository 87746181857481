.mainSearch {
  position: relative;
  background: #FFF;

  .ais-SearchBox {
    width: 100%;
    padding: .5rem;
    border-bottom: 1px solid $lightGrey;

    form {
      display: block;
      position: relative;

      input[type="search"] {
        @include input;
        -webkit-appearance:none;
        @include bp(Mama) {
          font-size: .875rem;
        }
        &:focus {
          border-color: $teal;
        }
      }

      button {
        &[type="submit"] {
          height: 36px;
          border: 2px solid $teal;
          background: $teal;
          border-radius: 5px;
          color: #FFF;
          outline: none;
          cursor: pointer;
  
          position: absolute;
          top: 4px; right: 4px;
          width: 30px; height: 30px;
          font-size: 1rem;
          text-align: center;
  
          svg {
            fill: #FFF;
          }
  
          &:hover {
            background: #FFF;
            color: $teal;
            svg {
              fill: $teal;
            }
          }
        }
        &[type="reset"] {
          height: 36px;
          border: 2px solid $teal;
          background: $teal;
          border-radius: 5px;
          color: #FFF;
          outline: none;
          cursor: pointer;
  
          position: absolute;
          top: 4px; right: 4px;
          width: 30px; height: 30px;
          font-size: 1rem;
          text-align: center;
  
          svg {
            fill: #FFF;
          }
  
          &:hover {
            background: #FFF;
            color: $teal;
            svg {
              fill: $teal;
            }
          }

          display: none
        }
      }
    }
  }

  &__index {
    h5 {
      margin: 0;
      background: #FAFAFA;
      padding: .25rem .5rem;
      border-bottom: 2px solid $lightGrey;
    }
  }

  &__item {
    border-bottom: 2px solid $lightGrey;
  }

  &__list {
    display: block;
    width: 100%;
    background: #FAFAFA;
    overflow: hidden;
    border-top: 1px solid $lightGrey;

    &__inner {
      padding: 0 .5rem;
    }

    &--results {
      
    }

    &--init {
      min-height: 100px;
      p {
        padding: 0 1rem;
        font-size: .85rem;
        color: $grey;
      }
    }
  }

  &__item {
    cursor: pointer;
    background: #FFF;
    &--outlined {
      border: 1px solid $lightGrey;
    }
    &:hover {
      background: #FAFAFA;
    }
    &__top {
      @include flex;
      padding: .5rem;
    }

    &__left {
      h4 {
        margin: 0;
      }
      p {
        @include flex;
        justify-content: flex-start;
        gap: .5rem;
        color: $grey;
        margin: 0;
        font-size: .75rem;
      }
      &--enhanced {
        p {
          margin: 0 0 .25rem;
          font-size: .75rem;
      
          span {
            margin-right: 1rem;
          }
        }
      }
    }
    &__right {
      
    }
    &--highlighted {
      background: #EFEFEF;
    }
  }
}
