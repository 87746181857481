body.layout--onboarding {
  height: 100%;
  #root {
    height: 100%;
    @include unicorn;
    padding: 2rem;
    @media screen and (max-height: 850px) {
      align-items: flex-start;
      padding: 0;
    }
    div.main {
      padding: 1rem;
    }
  }

  .onboarding--mobile {
    padding: 0 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @include bp(Mama) {
      display: none;
    }

    p {
      text-align: center;
    }
  }

  div.main {
    display: none;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    height: 100%;
    max-width: 800px;
    max-height: 800px;
    min-height: 800px;
    transition: $base-transition;
    background: rgba(255, 255, 255, .5); 
    box-shadow: 0 0 250px rgba(43, 56, 102, 0.4),  0 0 20px rgba(43, 56, 102, 0);
    border-radius: 10px;
    overflow: hidden;
    padding: 1rem;
    @include bp(Mama) {
      display: flex;
    }
    @media screen and (max-height: 850px) {
      box-shadow: none;
      max-height: auto;
      height: 100%;
      border-left: 2px solid #dfe6f4;
      border-right: 2px solid #dfe6f4;
      border-radius: 0;
    }
  }

  aside {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;
    background: #dfe6f4;

    & > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: 1px solid rgba(255, 255, 255, 0.155);
      padding: 0 1rem;
      line-height: 100%;
      font-size: 1rem;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.442);
      border-radius: 10px;
      margin: .5rem 0;
      background: $grey;
      
      &:last-child {
        border-bottom: 0;
      }

      i {
        margin-right: 0.5rem;
      }

      &.active {
        position: relative;
        background: #3A4B8A;
        border: 1px solid #6a84e4;
        box-shadow: 0 0 50px #6a84e449;
        color: rgb(255, 255, 255);
        cursor: pointer;
      }

      &.visited {
        background: rgba(24, 22, 36, 0.193);
        color: rgba(255, 255, 255, 0.694);
        cursor: pointer;
        border: 1px solid $teal;
        .completeCheck i{
          color: $teal;
          visibility: visible;
        }
      }

      &.locked {
        cursor: default;
        position: relative;
        background: rgba(111, 127, 159, 0.5);
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translateY(-50%);
          background: #fff;
          width: 44px;
          height: 44px;
          border-radius: 50%;
        }
        &:after {
          content: "\f30d";
          position: absolute;
          top: 50%;
          right: 18px;
          transform: translateY(-50%);
          color: $grey;
          font-family: "Font Awesome 5 Pro";
          font-weight: 900;
          background: transparent;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          border: 2px solid $grey;
          line-height: 36px;
          text-align: center;
        }
      }
    }

    &.tabContainer {
      background-color: $navy;
      border-radius: 10px;
    }

    &.tabSize--6 {
      padding: 1rem;
      & > div {
        height: calc(100% / 6);
      }
    }

    &.tabSize--7 {
      padding: 1rem;
      & > div {
        height: calc(100% / 7);
      }
    }

    &.tabSize--8 {
      & > div {
        height: calc(100% / 8);
      }
    }

    &.tabSize--9 {
      & > div {
        height: calc(100% / 9);
      }
    }

    &.tabSize--10 {
      & > div {
        height: calc(100% / 10);
      }
    }

    &.tabSize--11 {
      & > div {
        height: calc(100% / 11);
      }
    }

    &.tabSize--12 {
      & > div {
        height: calc(100% / 12);
      }
    }

    &.tabSize--14 {
      & > div {
        height: calc(100% / 14);
      }
    }

    &.tabSize--15 {
      & > div {
        height: calc(100% / 15);
      }
    }
  }

  .onboarding {
    @include unicorn;
    width: calc(100% - 300px);
  }

  h1 {
    margin: 0;
    a {
      display: inline-block;
      width: 223.38px;
      height: 38px;
      background: url(/assets/images/SimplyBail_logo_long.png) no-repeat;
      background-size: 223.38px, 38px;
      text-indent: -9000px;
      line-height: 1rem;

      img {
        display: none;
      }
    }
  }

  .questionnaireArea {
    padding: 2rem 0;
  }

  .finishUploadButton {
    position: absolute;
    bottom: 2rem;
    padding: 0 2rem;
    &--left {
      left: 2rem;
    }
    &--right {
      right: 2rem;
    }
  }

  .startMessage {
    display: block;
    color: white;
    font-size: 20px;
    font-weight: 900;
    padding: 1rem 1rem;
    margin: 0;
    border: none;
    margin-bottom: -20px;

    p {
      font-size: 14px;
      font-weight: 500;
      padding: 10px 0px;
      margin: 0;
    }
  }

}

.onboarding__login {
  height: 100%;
  @include unicorn;
  overflow-y: scroll;

  padding: 0 4rem;

  &__container {
    border: 3px solid #dfe6f4;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 4rem 2rem;

    h1 {
      margin: 0 auto;
    }
  }

  &__content {
    p {
      margin: 0 0 1rem;
      font-size: 0.85rem;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.onboarding__new {
  padding: 0 1rem;

  p {
    font-size: 0.85rem;
    a {
      color: $navy;
      &:hover {
        color: $teal;
      }
    }
  }
}

.onboarding__terms {
  height: 100%;
  @include unicorn;
  overflow-y: scroll;

  &_butt {
    color: rgb(255, 255, 255);
    padding: 2rem 2rem;
    border-radius: 10px;
    background-color: $navy;
    border: none;
    min-height: 60px;

    &:hover {
      color: rgba(255, 255, 255, 0.669);
    }
  }

  padding: 0 4rem;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      margin: 0 auto;
    }

    button {
      margin-top: 2rem;
      font-size: 1rem;
      font-weight: 700;
      padding: 0 2rem;
      cursor: pointer;
    }
  }

  &__content {
    p {
      margin: 0 0 1rem;
      font-size: 0.85rem;
      text-align: center;

      a {
        color: $navy;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      margin: 0 0 1.5rem;
      padding: 0;
      list-style: none;

      li {
        position: relative;
        margin-bottom: 0.25rem;
        padding-left: 2rem;
        color: $grey;
        font-size: 1.125rem;
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0.5rem;
          transform: translateY(-50%);
          width: 12px;
          height: 12px;
          background: #dfe6f4;
          border-radius: 50%;
        }
      }
    }
  }
}

// .onboarding__onlineSales {
//   &__content {
//     font-size: .85rem;
//     padding: 0 2rem;
//   }
//   &__options {
//     padding: 0 2rem;
//   }
//   .option {
//     position: relative;
//     display: block;
//     width: 100%;
//     margin-top: 1rem;

//     input {
//       display: none;
//     }

//     input + label {
//       display: block;
//       padding: 1rem 1rem 1rem 4.75rem;
//       border: 0;
//       border-radius: 4px;
//       border: 2px solid $grey;
//       cursor: pointer;

//       &::before {
//         content: '';
//         position: absolute;
//         top: 50%; left: 1rem;
//         transform: translateY(-50%);
//         border: 2px solid $grey;
//         width: 35px; height: 35px;
//         border-radius: 4px;
//       }

//       &::after {
//         display: none;
//         content: '\f00c';
//         position: absolute;
//         top: 50%; left: 1rem;
//         margin-left: 4px;
//         transform: translateY(-50%);
//         width: 35px; height: 35px;
//         border-radius: 4px;
//         font-size: 2rem;
//         font-family: "Font Awesome 5 Pro";
//         color: $grey;
//       }

//       h4, p {
//         margin: 0;
//         text-align: left;
//         color: $grey;
//         pointer-events: none;
//       }

//       h4 {
//         display: block;
//         font-size: 1.25rem;
//       }

//       .option__extra {
//         display: none;
//         margin-top: 1rem;
//         p {
//           margin-bottom: .5rem;
//         }
//         ul {
//           margin: 0;
//           li {
//             font-size: .85rem;
//             color: #FFF;
//           }
//         }
//       }
//     }

//     input:checked + label {
//       background: $grey;
//       h4, p {
//         color: #FFF;
//       }
//       .option__extra {
//         display: block;
//       }
//       &::before {
//         background: #FFF;
//       }
//       &::after {
//         display: block;
//       }
//     }
//   }
// }

.onboarding__changePassword {
  width: 100%;
  max-width: 300px;
  .register__form {
    margin-top: 2rem;
    max-width: 100%;
    .inputRow__block {
      width: 100%;
    }
  }
}

.onboarding__profile {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 2rem;
  @include unicorn;
  overflow-y: scroll;

  &__container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    h1 {
      margin: 0 auto 2rem !important;
    }

    .developerBypass {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.onboarding__files {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2rem;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .files {
    width: 100%;

    &__block {
      border-bottom: 2px solid #dfe6f4;

      @include bp(Mama) {
        padding: 0.75rem 0;
      }

      h3 {
        font-size: 1.5rem;
        line-height: 1.75rem;
        color: $navy;
      }

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    &__meta {
      position: relative;
      cursor: pointer;

      h3 {
        margin: 0;
      }
      p {
        margin: 0;
      }

      span.tag {
        display: none;
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translateY(-50%);
      }

      i {
        display: none;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%) rotate(180deg);
        width: 16px;
        height: 16px;
        font-size: 2rem;
        line-height: 22px;
        color: #dfe6f4;
        transition: $base-transition;
      }

      &:hover {
        i {
          color: #6f7f9f;
        }
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      height: 0;
      overflow: hidden;
      transition: $base-transition;
      div {
        width: 100%;
        border: 2px solid #dfe6f4;
        background: transparent;
        font-size: 1.25rem;
        color: #dfe6f4;
        padding: 1rem 0;
        outline: none;
        border-radius: 6px;
        text-align: center;
        cursor: pointer;

        &:hover {
          border-color: $navy;
          color: $navy;
        }
      }

      div.button--isUpdated {
        padding: 0.75rem 0;
        border-color: $navy;
        color: $navy;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:hover {
          border-color: $navy;
          color: $navy;
        }

        strong {
          font-size: 0.85rem;
          text-transform: uppercase;
        }

        button {
          margin-top: 2rem;
          padding: 0;
          border: 0;
          background: transparent;
          color: $navy;
          cursor: pointer;
          font-weight: 700;

          &:hover {
            color: $teal;
          }
        }
      }
    }

    &__fileNames {
      font-size: 0.75rem;
      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;
        &:last-child {
          margin-bottom: 0;
        }

        a {
          color: $teal;
        }
      }

      &__container {
        height: 0;
        overflow: hidden;
        transition: $base-transition;

        h5 {
          margin: 0 0 0.25rem;
        }
      }
    }

    .files__block--open {
      .files__meta {
        margin-bottom: 0.75rem;
        i {
          transform: translateY(-50%) rotate(0);
        }
      }
      .files__buttons {
        height: auto;
      }
      .files__fileNames__container {
        height: auto;
        padding: 1rem 0 0;
      }
    }

    .files__block--uploaded {
      .files__meta {
        span,
        i {
          display: inline-block;
        }
      }
    }

    .files__block--uploaded {
      .files__meta {
        span,
        i {
          display: inline-block;
        }
      }
    }

    .mask__container {
      p.lower {
        margin: 1em 0 0;
      }
    }
  }
}

.onboarding__review {
  height: 100%;
  @include unicorn;
  overflow-y: scroll;

  padding: 0 4rem;

  h3 {
    margin: 0 0 0.5rem;
    font-size: 1.5rem;
    color: $navy;
  }

  &__container {
    border: 3px solid #dfe6f4;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 4rem 2rem;

    h1 {
      margin: 0 auto;
    }

    button {
      margin-top: 2rem;
      font-size: 1rem;
      font-weight: 700;
      padding: 0 2rem;
      cursor: pointer;
    }
  }

  &__content {
    p {
      margin: 0 0 1rem;
      font-size: 0.85rem;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.onboarding__counties {
  position: relative;
  height: 100%;
  overflow-y: scroll;

  padding: 2rem;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      margin: 0 auto;
    }
  }

  &__content {
    p {
      margin: 0 0 1rem;
      font-size: 0.85rem;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .countiesManager {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;

    .register__form {
      width: 100%;
      max-width: 100%;
      padding-bottom: 0;
    }

    .countiesList {
      padding-top: 1rem;
      & > p {
        text-align: center;
      }
    }
  }

  .locationSettings {
    header {
      h3,
      p {
        padding: 0;
      }
    }
    .register__form__block.settings {
      padding: 0;
    }
    .register__form__actionsBar {
      &__actions {
        padding: 0;
      }
    }
  }
}

.onboarding__employees {
  position: relative;
  height: 100%;
  overflow-y: scroll;
  padding: 2rem;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      margin: 0 auto;
    }
  }

  &__content {
    p {
      margin: 0 0 1rem;
      font-size: 0.85rem;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .usersManager {
    width: 100%;
    padding-top: 1rem;

    .register__form__block.settings {
      padding: 0;
    }

    .users__table:not(.users__table--invites) {
      .users__table__header,
      .users__table__row {
        span:nth-child(1) {
          width: calc(100% - 180px);
        }
        span:nth-child(2) {
          display: none;
        }
      }
    }

    .register__form__actionsBar__actions {
      padding: 1rem 0 0;
    }
  }
}

.onboarding__seo {
  position: relative;
  height: 100%;
  overflow-y: scroll;
  padding: 2rem;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      margin: 0 auto;
    }
  }

  &__content {
    p {
      margin: 0 0 1rem;
      font-size: 0.85rem;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .seoManager {
    width: 100%;
    padding-top: 1rem;
    .register__form__block {
      padding: 0;
    }
    .register__form__actionsBar__actions {
      padding: 0;
      justify-content: center;
    }
  }
}

.onboarding__forms {
  position: relative;
  height: 100%;
  overflow-y: scroll;
  padding: 2rem;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      margin: 0 auto;
    }
  }

  &__content {
    p {
      margin: 0 0 1rem;
      font-size: 0.85rem;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .formsManager {
    width: 100%;
    padding-top: 1rem;
    .register__form__block {
      padding: 0;
    }
    .files__uploaderArea {
      margin-bottom: 1rem;
    }
  }

  .uploader {
    border: 1px solid #dfe6f4;
    background: #fff;
    color: #dfe6f4;
    font-size: 1.5rem;

    &:hover {
      border-color: $navy;
      color: $navy;
    }

    div {
      outline: none;
    }

    &__container {
    }
  }
}

.onboarding__uploads {
  position: relative;
  height: 100%;
  overflow-y: scroll;
  padding: 2rem;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      margin: 0 auto;
    }
  }

  &__content {
    p {
      margin: 0 0 1rem;
      font-size: 0.85rem;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .uploadsManager {
    width: 100%;
    padding-top: 1rem;
  }
}

.forms {
  background: #efefef;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0 1rem;
    background: #fff;
    border-bottom: 1px solid $lightGrey;

    h5 {
      margin: 0.5rem 0;
    }

    &__meta {
      .pill {
        margin-right: 0.5rem;
      }
    }
  }

  &__surety_section {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .uploadedFiles__file {
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__content {
    padding: 1rem;
    border-bottom: 1px solid $lightGrey;
    .noResults {
      margin-top: 0;
    }
    .uploader {
      border: 0;
      background: transparent;
      border-radius: 0;
      margin: 0;
      &__container {
        display: block;
      }
    }
  }

  &__add {
    button.add {
      width: 100%;
      background: #fafafa;
      border: 2px solid $lightGrey;
      padding: 1rem 0;
      font-size: 3rem;
      color: $grey;
      cursor: pointer;
      border-radius: 10px;
      &:hover {
        color: $navy;
      }
    }
  }

  // &__file {

  //   margin-bottom: .5rem;
  //   padding-bottom: .5rem;
  //   border-bottom: 2px solid #DFE6F4;

  //   &__top {
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //   }

  //   &__bottom {
  //     padding-left: 20px;
  //   }

  //   &:last-child {
  //     margin-bottom: 0;
  //     padding-bottom: 0;
  //     border: 0;
  //   }

  //   label {
  //     display: inline-block;
  //     margin: .25rem 0 0 0;
  //     font-size: .65rem;
  //     font-weight: 300;
  //     text-transform: uppercase;
  //     text-align: center;
  //   }

  //   &__meta {
  //     width: calc(100% - 150px);
  //     overflow: hidden;
  //     h4 {
  //       margin: 0 0 .25rem;
  //     }
  //     p {
  //       // display: none;
  //       margin: 0;
  //       font-size: .75rem;
  //     }

  //     .editName {
  //       margin-right: .5rem;
  //     }

  //     &__name {
  //       display: flex;
  //       justify-content: flex-start;
  //       align-items: center;
  //       a {
  //         color: #000;
  //         text-decoration: none;

  //         &:hover {
  //           color: $teal;
  //           text-decoration: underline;
  //         }
  //       }
  //     }
  //   }

  //   &__active, &__mandatory {
  //     @include unicorn;
  //     flex-direction: column;
  //     width: 70px;
  //   }
  // }
}

.uploadsManager {
  &__content {
    & > p {
      text-align: center;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__bottom {
    position: relative;
    &:before {
      position: absolute;
      top: 20px;
      left: 12px;
      width: 4px;
      height: 55%;
      background: #dfe6f4;
    }

    &--2up {
      &:before {
        content: "";
      }
    }
  }

  &__option {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      position: relative;
      width: calc(100% - 70px);
      margin: 0;
      font-size: 0.75rem;
      padding: 0.5rem 0 0.5rem 2rem;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0.5rem;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        background: #dfe6f4;
        border-radius: 50%;
      }
    }

    &__toggle {
      width: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  &__file {
    border-bottom: 2px solid #dfe6f4;

    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;

    &:last-child {
      border-bottom: 0;
    }

    label {
      display: inline-block;
      margin: 0.25rem 0 0 0;
      font-size: 0.65rem;
      font-weight: 300;
      text-transform: uppercase;
      text-align: center;
    }

    &__meta {
      width: calc(100% - 80px);
      h4 {
        margin: 0 0 0.25rem;
      }
      p {
        // display: none;
        margin: 0;
        font-size: 0.75rem;
      }
    }
    &__activate {
      @include unicorn;
      flex-direction: column;
      width: 70px;
    }
  }

}

.popUpBlocker {
  max-width: 300px;
  margin: 1.5rem auto 0;
  border: 2px solid $navy;
  text-align: center;
  padding: 2rem;

  h3 {
    margin: 0;
    font-size: 1.25rem;
    color: $navy;
  }
  p {
    margin: 0.5rem 0 1.5rem;
  }
  a {
    display: inline-block;
    font-size: 0.85rem;
    text-decoration: none;
    padding: 0 10px;
    line-height: 32px;
  }
}

.tabContents{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.completeCheck {
  visibility: hidden;
}

.tabComplete {
  text-align: center;
  padding: 2rem;
}