.layout--checkinDashboard {
  .layout__info {
    position: relative;
    p {
      margin: 0;
    }
  }
}

.template-checkins {
  #root div.main > div {
    .layout {
      &__info {
        padding: 1.5rem 1rem;
      }
    }
  }
}




.template-checkins {
  #root div.main > div {
    .layout {
      &__info {
        padding: 1.5rem 1rem;
      }
    }
  }
}

.layout--checkinUser {
  .layout__info {
    position: relative;
    max-width: 100%;
    p {
      max-width: 500px;
    }
  }
}

.layout--courtDates {
  max-width: 100%;
  .checkinDashboard__filters {
    margin-bottom: 2rem;
  }
}

.layout--appearances {
  max-width: 100%;
}

.bondAmount_tile {
  position: absolute;
  top: 50%; right: 1rem;
  transform: translateY(-50%);
  background: $blue;
  border-radius: 5px;
  min-width: 150px;

  label {
    display: block;
    text-align: center;
    padding: .5rem 0 .25rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: .65rem;
    color: #FFF;
  }
  strong {
    display: block;
    padding-bottom: .5rem;
    text-align: center;
    font-size: 2rem;
    line-height: 2rem;
    color: #FFF;
  }
}



.layout--checkinUser {
  .layout__info {
    position: relative;
    max-width: 100%;
    p {
      max-width: 500px;
    }
  }
}

.bondAmount_tile {
  position: absolute;
  top: 50%; right: 1rem;
  transform: translateY(-50%);
  background: $blue;
  border-radius: 5px;
  min-width: 150px;

  label {
    display: block;
    text-align: center;
    padding: .5rem 0 .25rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: .65rem;
    color: #FFF;
  }
  strong {
    display: block;
    padding-bottom: .5rem;
    text-align: center;
    font-size: 2rem;
    line-height: 2rem;
    color: #FFF;
  }
}

.caseTabs__section__courtHouse {
  position: relative;
  .checkinButton {
    position: absolute;
    top: 1.7rem; right: 2rem;
    margin: 0;
    padding: 0 0.5rem;
    height: 30px;
    font-size: .85rem;
    font-weight: 700;
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: 0;
    line-height: 26px;
    text-decoration: none;
    &:hover {
      border-color: $blue;
      color: $blue;
    }
  }  
}

.layout__info--checkinUser {
  position: relative;
  max-width: 100%;
}

.checkinDashboard {
  &__map {
    position: relative;
    .resetButton {
      position: absolute;
      top: .5rem; left: .5rem;
      background: #FFF;
      border: 0;
      border-radius: 1px;
      font-size: .825rem;
      font-weight: 400;
      color: $grey;
      padding: .5rem;
      box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
      cursor: pointer;
      appearance: none;
      outline: none;
      &:hover {
        background: #FAFAFA;
        color: #282828;
      }
    }
  }

  &__courtdates {
    padding: 0 1rem;
    .checkinDashboard__profile__courtDates {
      border-bottom: 0;
      margin-bottom: 0;
    }
    .courtHouse__dates__date__linkWrapper {
      display: block;
      text-decoration: none;
      color: #000;
    }
  }

  &__profile {
    padding: 1rem;
    &__details {
      @include flex;
      align-items: flex-start;
      padding-bottom: 1rem;
      border-bottom: 2px solid $lightGrey;
      margin-bottom: 1rem;
      &__left {
        h3 {
          margin: 0;
        }
        p {
          margin: 0;
          font-size: .825rem;
          color: $grey;
        }
      }
      &__right {
        .addressPod {
          border: 1px solid $lightGrey;
          border-radius: 5px;
          background: #FAFAFA;
          padding: .5rem;
          margin-bottom: .5rem;
          cursor: pointer;
          &:hover {
            background: #EFEFEF;
          }
          h6 {
            margin: 0 0 .25rem;
            letter-spacing: 1px;
            color: $grey;
          }
          p {
            margin: 0;
            font-size: .825rem;
          }
        }
      }
    }
    &__courtDates {
      padding-bottom: 1rem;
      border-bottom: 2px solid $lightGrey;
      margin-bottom: 1rem;
    }
    &__logs {
      
    }
  }

  &__filters {
    @include flex;
    padding: .5rem;
    background: #FAFAFA;
    border-top: 1px solid $lightGrey;
    border-bottom: 1px solid $lightGrey;
    & > div {
      width: 50%;      
      .inputRow__block {
        margin: 0;
      }
    }

    &--userFilter {
      @include flex;
      padding: .5rem;
      background: #FAFAFA;
      border-top: 1px solid $lightGrey;
      border-bottom: 1px solid $lightGrey;
      & > div {
        width: 50%;
        // .inputRow__block {
        //   margin: 0;
        // }
        &:first-child {
          @include flex;
          justify-content: flex-start;
          a, button {
            margin-right: .5rem;
          }
          a {
            display: inline-block;
            height: 36px;
            line-height: 32px;
            padding: 0 .5rem;
            text-decoration: none;
            font-size: .825rem;
          }
        }
        &:last-child {
          @include flex;
          justify-content: flex-end;
          div {
            @include unicorn;
            margin-right: .5rem;
            &:last-child {
              margin-right: 0;
            }
            label {
              font-size: .75rem;
            }
          }
        }
      }
    }
  }
  
  &__app {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid $lightGrey;
    &__content {
      @include flex;
    }
    &__settings {
      @include flex;
      justify-content: flex-start;
      div {
        @include unicorn;
        margin-right: .5rem;
        &:last-child {
          margin-right: 0;
        }
        label {
          font-size: .75rem;
        }
      }
    }
  }

  &__tiles {
    padding: 1rem;
  }
  
  &__pods {
    @include flex;
    align-items: flex-start;
    padding: 1rem;
    & > div {
      width: 49%;
      &:first-child h5 {
        @include flex;
        button {
          height: 24px;
        }
      }
    }
  }
  .checkinPod {
    @include pod;
    p {
      font-size: .825rem;
    }
    &--valid {
      background: #e2fff2;
    }
    &--alt {
      background: #fff3ba;
    }
  }
}

.checkinPod__key {
  @include flex;
  justify-content: flex-start;
  padding: 0 1rem;
  & > div {
    @include flex;
    justify-content: flex-start;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
    span {
      margin-right: .25rem;
      width: 50px;
      height: 20px;
      border: 1px solid $lightGrey;
      border-radius: 4px;
      &.green {
        background: #e2fff2;
      }
      &.yellow {
        background: #fff3ba;
      }
    }
    label {
      font-size: .825rem;
    }
  }
}

.checkinAnnouncement {
  margin-top: 2rem;
  & > p {
    font-size: .825rem;
  }
  &__inner {
    position: relative;
    @include flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem;
    // border: 2px solid $warning;
    background: $blue;
    border-radius: 10px;
    color: #FFF;
    padding: 1rem 1rem 1rem 4.5rem;
    @include bp(Mama) {
      flex-direction: row;
    }

    i {
      position: absolute;
      top: 50%; left: 1rem;
      transform: translateY(-50%);
      font-size: 3rem;
    }
  }

  &__content {
    width: 100%;
    @include bp(Mama) {
      width: calc(100% - 120px);
    }
    h3 {
      margin: 0 0 .5rem;
    }
    p {
      margin: 0;
      font-size: .825rem;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    width: 100%;
    @include bp(Mama) {
      width: 120px;
    }
    @include bp(Mama) {
      margin-top: 0;
    }
    a {
      display: block;
      cursor: pointer;
    }
    button {
      min-width: 100px;  
      width: 100%;
      cursor: pointer;
      @include bp(Mama) {      
        width: auto;
      }
    }
  }

  &--app {
    $actionsWidth: 250px;
    .checkinAnnouncement__content {
      width: 100%;
      @include bp(Mama) {
        width: calc(100% - $actionsWidth);
      }
    }
    .checkinAnnouncement__actions {
      @include flex;
      align-items: flex-end;
      width: 100%;
      @include bp(Mama) {
        width: $actionsWidth;
        justify-content: center;
      }
      .appCode {
        display: flex;
        flex-direction: column;
        position: relative;
        top: -5px;
        label {
          font-size: .65rem;
          text-transform: uppercase;
        }
        strong {
          display: inline-block;
          height: 38px;
          font-size: 1.5rem;
          line-height: 38px;
          padding: 0 .75rem;
          border-radius: 6px;
          background: #FFF;
          color: $blue;
          font-weight: 700;
          margin-right: 1rem;
        }
      }
      .appStore_image {
        // display: none;
        padding-top: 4px;
        svg {
          margin: 0; padding: 0;
        }
      }
    }
  }
}

.checkIn {
  background: #FAFAFA;
  border: 2px solid $lightGrey;
  border-radius: 5px;
  margin-bottom: 1rem;

  &__back {
    margin: 1.5rem 0 1rem;
    button {
      border: 0;
      background: transparent;
      padding: 0;
      margin: 0 0 .5rem;
      font-size: .85rem;
      letter-spacing: 2px;
      color: $grey;
      text-transform: uppercase;
      i {
        margin-right: .5rem;
      }
    }
  }

  &__instructions {
    margin-top: 2rem;
    border-top: 2px solid $lightGrey;
    padding-top: 1rem;
    header {
      h2 {
        font-size: 1.25rem;
      }
      p {
        font-size: .825rem;
      }
    }

    button {
      width: 100%;
      padding: .5rem 0;
      border: 0;
      background: transparent;
      font-size: 1.25rem;
      border-top: 1px solid $lightGrey;
      border-bottom: 1px solid $lightGrey;
      text-align: left;
      background: #FAFAFA;
      &.topLineOff {
        border-top: 0;
      }
    }

    &__content {
      width: 100%;
      @include bp(Mama) {
        width: calc(100% - 120px);
      }
      h3 {
        font-size: 1.1rem;
      }
      p {
        font-size: 1rem;
      }
    }
  }

  header {
    padding: 1rem;
    border-bottom: 1px solid $lightGrey;
    p {
      margin: 0;
      font-size: .825rem;
    }
  }

  section {
    padding: 1rem;
    border-bottom: 1px solid $lightGrey;
    button { width: 100%; }
  }

  footer {
    padding: 1rem;
    button { width: 100%; }
  }

  h2 {
    margin: 0;
  }
  h3 {
    margin: 0 0 .5rem;
    color: $navy;
  }

  &__container {
    padding: 0 1rem;
    @include bp(Mama) {
      padding: 0;
    }
  }

  &__selfie {
    &__image {
      border: 2px solid $lightGrey;
      border-radius: 3px;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .errorMessage {
    font-weight: 700;
    color: $danger;
  }

  &__location {
    @include flex;
    &__staticMap {
      display: none;
      border: 2px solid $lightGrey;
      border-radius: 3px;
      overflow: hidden;
      line-height: 0;
      img {
        width: 100%;
        height: auto;
      }
    }
    &__left {
      width: calc(100% - 120px);
    }
    &__right {
      width: 100px;
    }
    p {
      margin: 0;
      font-size: .7rem;
    }
    &--local {
      margin-bottom: .5rem;
    }
    // &--loggedAt {

    // }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    & > div {
      @include unicorn;
      width: 49%;
      border: 2px solid #DFE6F4;
      background: transparent;
      font-size: 2rem;
      color: #DFE6F4;
      padding: 2rem 0;
      outline: none;
      border-radius: 6px;
      text-align: center;

      &:hover {
        border-color: $navy;
        color: $navy;
      }

      .secureUpload__buttons__image {
        padding: 1rem;
        img {
          max-width: 100%;
          height: auto;
          max-height: 200px;
        }
      }
    }

    div.button--isUpdated {
      padding: .75rem 0;
      border-color: $navy;
      color: $navy;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:hover {
        border-color: $navy;
        color: $navy;
      }

      strong {
        font-size: .85rem;
        text-transform: uppercase;
      }

      button {
        // margin-top: 2rem;
        padding: 0;
        border: 0;
        background: transparent;
        color: $navy;
        cursor: pointer;
        font-weight: 700;

        &:hover {
          color: $teal;
        }
      }
    }
  }

  &__block--full {
    .checkIn__buttons {
      div {
        width: 100%;
      }
    }
  }
}

.template-checkins-live {
  div#root div.main {
    max-width: 100%;
    aside {
      transform: none;
      left: 0;
    }
  }

  .checkinLive {
    &__map {
      width: calc(100% - 400px);
      height: calc(var(--vh, 100vh) - 57px - 52px);
    }
    &__sidebar {
      position: fixed;
      top: calc(57px + 52px + 3px); right: 0;
      height: calc(var(--vh, 100vh) - 57px - 52px - 3px);
      width: 400px;
      background: #FFF;
      border-left: 2px solid $lightGrey;

      & > header {
        @include flex;
        background: #FAFAFA;
        border-bottom: 1px solid $lightGrey;
        padding: .25rem;
        
        &.checkLive__options {

        }
        &.checkLive__filters {
          border-top: 1px solid $lightGrey;
        }
      }

      .courtHouse__additional {
        padding: 0 .5rem;
        &__list {
          tr {
            th, td {
              &:first-child {
                width: 70px;
              }
            }
          }
        }
      }

    }
    &__messages {
      padding: 1rem;
      background: #EFEFEF;
      border-bottom: 1px solid $lightGrey;
      p {
        font-size: .825rem;
      }
      .register__form {
        padding: 0;
        .form-actions {
          @include flex;
          & > button {
            width: 49%
          }
        }
      }
    }
    &__settings {
      padding: 1rem;
      background: #EFEFEF;
      border-bottom: 1px solid $lightGrey;
      p {
        font-size: .825rem;
      }
      .register__form {
        padding: 0;
        .form-actions {
          @include flex;
          & > button {
            width: 49%
          }
        }
      }
      &__delete {
        margin-top: .5rem;
      }
      &__content {
        margin-bottom: 1rem;
        padding: .5rem;
        border: 1px solid $lightGrey;
        background: #FFF;
        border-radius: .5rem;
      }
      &__dropdown {
        @include flex;
        margin-bottom: .5rem;
        &:last-child {
          margin-bottom: 0;
        }
        & > label {
          font-size: .825rem;
        }
      }
    }
    &__notice {
      @include unicorn;
      margin-bottom: .5rem;
      background: $danger;
      border-radius: 6px;
      p {
        font-weight: 700;
        color: #FFF;
      }
      &--on {
        background: $completed;
      }
    }
    &__profile {
      @include pod;
      &__container {
        padding: .5rem;
      }
      &__left {
        h3 {
          margin: 0 0 .25rem;
        }
        p {
          margin: 0;
          font-size: .825rem;
        }
      }
      &__right {
        .bondAmount_tile {
          position: relative;
          top: auto; right: auto;
          transform: none;
          min-width: auto;
          padding: 0 .5rem;
          strong {
            font-size: 1.5rem;
            line-height: 1.5rem;
          }
        }
      }
    }
    &__markers {
      &__container {
        padding: .5rem;
      }
      &__marker {
        @include pod;
        cursor: pointer;
        background: #FAFAFA;
        & > div {
          &:first-child {
            h3 {
              margin: 0 0 .25rem;
            }
            p {
              margin: 0;
              font-size: .75rem;
            }
          }
          &:last-child {
            
          }
        }
      }
    }
  }
}

.checkin__settings {
  padding: 0 1rem;

  &__actions {
    @include flex;
    & > button {
      width: 49.5%;
    }
  }
}