.wizard {
  display: flex;
  justify-content: flex-start;
  height: calc(var(--vh, 100vh) - 57px - 56px);
  font-family: 'Rubik', sans-serif;

  button, a {
    font-family: 'Rubik', sans-serif !important;
  }

  &__addNew {
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-grow: 1;
    &--form {
      width: 700px;
    }
  }

  &__nump {
    background-color: rgba(117, 118, 121, 0.117);
    padding: 2px 7px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid $lightGrey;
  }

  &_payment_plan {
    margin-bottom: 5px;

    @include bp(Kid) {
      @include flex;
      gap: 5px;
    }  

    &_alert{
      color: red;
      font-size: 12px;
    }

    &_secondary {
      flex-grow: 1;
      font-size: 12px;
    }

    &_item {
      border: 1px solid $lightGrey;
      border-radius: 5px;
      flex-grow: 1;
      margin-bottom: 1rem;

      @include bp(Kid) {
        margin-bottom: 0;
      }
    

      &_title {
        color: white;
        font-size: 11px;
        text-transform: uppercase;
        padding-bottom: 10px;
        font-weight: 900;
        padding: 0;
        background-color: $navy;
        width: 100%;
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: 0 0 5px 5px;
      }

      &_payment {
        color: green;
        font-size: 18px;
        font-weight: 900;
        padding: 0 1rem 0.75rem 1rem;
      }

      &_date {
        font-size: 16px;
        font-weight: 900;
        padding: 0 1rem 0.75rem 1rem;

        @include bp(Mama) {
          font-size: 18px;
        }    

        i {
          font-size: 13px;
          color: #000;
        }

        &_small {
          padding: 0.75rem 1rem 0.25rem 1rem;
          font-size: 12px;
          color: $grey
        }

      }

    }

  }

  &_collectionsButton {
    font-family: 'Rubik', sans-serif;
    border: none;
    background-color: red;
    color: white;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 5px;
    width: 150px;
    margin-right: 5px;
    i {
      font-size: 14px;
      color: white;
      margin-left: 5px;
    }

    &:hover {
      background-color: #1c2543;
      cursor: pointer;
    }

  }

  &_addButton {
    font-family: 'Rubik', sans-serif;
    border: none;
    background-color: $navy;
    color: white;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 5px;
    width: auto;
    position: absolute;
    top: .5rem; right: .5rem;
    text-align: center;
    // margin-top: -10px;    
    i {
      font-size: 14px;
      color: white;
      margin-left: 5px;
    }

    &:hover {
      background-color: #1c2543;
      cursor: pointer;
    }

    &_menu {
      display: block;
      background-color: white;
      border: 1px solid #1b2e4b3a;
      padding: 0.75rem;
      border-radius: 5px;
      box-shadow: 0px 0px 40px #1b2e4b3a;
      position: absolute;
      margin-top: 30px;
      width: auto;
      text-decoration: none;

      button {
        text-decoration: none;
      }

      a {
        text-decoration: none;
      }

      &_item {
        display: block;
        width: 100%;
        padding: 0.45rem 0.75rem;
        border: 1px solid #1b2e4bb3;
        border-radius: 5px;
        font-size: 14px;
        margin-top: 5px;
        color: #1b2e4bb3;
        text-decoration: none;
        font-weight: 600;
        text-align: left;
        i {
          font-size: 14px;
          color: #1b2e4bb3;
          margin-right: 10px;
        }

        &:hover {
          border: 1px solid #1b2e4b;
          color: #1b2e4b;
          cursor: pointer;
        }
      }

      &_section {
        font-size: 12px;
        font-weight: 900;
        margin-bottom: 10px;
        padding: 0;
        color: rgba(27, 46, 75, 0.555);

        p {
          padding: 0;
          margin: 0;
        }
      }

    }


  }

  &_snapshot {
    padding: 1rem;

    &_section_info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      padding: 0 0 0.5rem 0;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 900;
      letter-spacing: 0.75px;
      color: rgb(113, 115, 134);
      &_card {
        padding: 1rem;
        border-radius: 10px;
        border: 1px solid $lightGrey;
        color: rgba(12, 13, 16, 0.764);
        font-weight: 100;
        background-color: white;
        margin-bottom: 1.5rem;

        &_smol {
          font-size: 12px;
          color: rgb(113, 115, 134);
        }

        &_transactions {
          margin: 10px 0px;
          padding: 10px 0 10px 0;
          border-top: 1px solid $lightGrey;
          border-bottom: 1px solid $lightGrey;
          width: 100%;

          &_info {
            font-size: 14px;
            color: rgb(113, 115, 134);
          }

          &_row {
            color: rgb(113, 115, 134);
            padding: 5px 0 0 0;
            margin-bottom: 1rem;

            @include bp(Kid) {
              display: flex;
              justify-content: space-between;
              gap: 10px;
              margin-bottom: 0;
            }          
          }

          &_heading {
            padding: 0 0 5px 0;
            font-size: 14px;
            font-weight: 900;
          }
        }

        &_courtdate {

          &_row {
            color: rgb(113, 115, 134);
            padding: 5px 0 0 0;

            @include bp(Toddler) {
              display: flex;
              justify-content: space-between;
              gap: 10px;
            }
          
          }

          &_address{
            padding: 1.5rem 0 1rem 0;
            border-top: 1px solid $lightGrey;

            @include bp(Kid) {
              display: flex;
              justify-content: space-between;
            }  
          }

          &_edit {
            border: none;
            background-color: transparent;
            font-size: 14px;
          }

          &_date {
            display: flex;
            justify-content: flex-start;
            padding: 5px 0 0 0;
            gap: 10px;

            &:hover {
              cursor: pointer;
              color: $navy;
            }

            &_location {
              font-size: 12px;
              font-weight: 100;

              @include bp(Mama) {
                font-size: 14px;
              }          
            }

            &_time {
              padding: 0.25rem;
              color: $navy;
              font-size: 11px;
              font-weight: 900;
              background-color: rgba(0, 0, 0, 0.072);
              border-radius: 3px;
              text-decoration: none;
              
              &:hover {
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.12);
              }

              @include bp(Mama) {
                font-size: 13px;
              }          
            }
          }

          &_cadence {
            border-top: 1px solid $lightGrey;
            margin-top: 1rem;
            padding-top: 1rem;
          }

          &_heading {
            padding: 0 0 5px 0;
            font-size: 16px;
            font-weight: 900;
          }
        }

        &_header {
          padding: 0 0 15px 0;
          width: 100%;

          @include bp(Kid) {
            display: flex;
            justify-content: space-between;
            align-items:center;
            gap: 10px;
          }        

          &_title {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 900;
            color: rgba(0, 0, 0, 0.547);
          }
        }

        &_footer {
          display: flex;
          border-top: 1px solid $lightGrey;
          justify-content: space-between;
          margin-top: 15px;
          padding-top: 10px;
        }

        &_cc {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          gap: 10px;

          &_captured {
            display: block;
            color:#008000;
            margin-top: 10px;

            @include bp(Kid) {
              text-align: right;
              margin-top: 0;
            }          
          }

          &_images {
            display: flex;
            justify-content: flex-start;
            align-self: center;
            border-radius: 5px;
            gap: 5px; 
            margin-top: 5px;

            &_item {
              display: flex;
              justify-content: flex-start;
              align-self: center;
              padding: 5px;
              border-radius: 5px;
              background-color: rgba(128, 124, 155, 0.096);
              gap: 5px;          
              font-size: 12px;
              
              &:hover {
                cursor: pointer;
                background-color: rgba(70, 68, 83, 0.268);
              }
            }

            i {
              margin-top: 2px;
              font-size: 12px;
              color: rgb(143, 147, 175);
            }
          }

          &_info{
            @include bp(Toddler) {
              display: flex;
              justify-content: flex-start;
              gap: 10px;
            }          
          }

          &_type {
            font-size: 18px;
            color: rgb(109, 111, 138);
          }

        }

        &_title {
          font-size: 18px;
          font-weight: 900;
        }

        &_subtitle {
          padding-top: 5px;
          font-size: 12px;
          font-weight: 100;
          color: rgba(113, 115, 134, 0.778);

          @include bp(Mama) {
            font-size: 14px;
          }
        }

        &_text {
          font-size: 12px;
          font-weight: 100;
          color: rgba(113, 115, 134, 0.778);

          @include bp(Mama) {
            font-size: 14px;
          }
        }

        &_butt {
          cursor: pointer;
        }

      }

      &_title {
        font-size: 18px;
        font-weight: 900;
        color:$navy;
        text-transform: capitalize;
        letter-spacing: 0;
      }

      &_text {
        font-size: 14px;
        font-weight: 100;
        color: rgb(113, 115, 134);

        a {
          color: rgb(86, 86, 194);
        }
      }
      
    }

    &_container { 
      @include flex;

      &_sections{
        display: flex;
        justify-content: space-between;
        padding-top: 1.5rem;
        gap: 20px;

        &_item {
          width: 100%;

          &_number {
            font-size: 14px;
            font-weight: 900;
          }

          &_title {
            font-size: 14px;
          }
        }

      }

      &_power {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        margin-bottom: 0.75rem;

        &__wrapper {
          display: flex;
          justify-content: space-between;
          width: 100%;
          gap: 20px;

          &_item {
            width: 100%;
          }
        }

        &_container {
          @include flex;
          flex-wrap: wrap;
        }

        &_dropdown {
          width: 100%;

          &_invoice {
            width: 100%;
            margin-bottom: 0.75rem;
            margin-top: 0.75rem;
            color: #000;

            &:hover {
              cursor: pointer;
              color: $navy;
              transition-duration: 0.2s;
            }

            @include bp(Kid) {
              @include flex;
              gap: 10px;
            }          

            &_number {
              font-size: 14px;
              font-weight: 900;
              color: #000;

              @include bp(Mama) {
                font-size: 16px;
              }           
            }

            &_container {
              padding: 0;
              margin: 0;
            }

            &_card_info {
              padding: 0.75rem;
              border: 1px solid $lightGrey;
              border-radius: 10px;
              margin: 5px 0px;
              width: 100%;

              @include bp(Kid) {
                @include flex;
              }

              &_methods {

              }

              &_number {
                font-size: 16px;
                font-weight: 900;
                color: $teal;

                @include bp(Mama) {
                  font-size: 18px;
                }            
              }

              &:hover {
                cursor: pointer;
                border: 1px solid $navy;
              }
            }

            &_date {
              font-size: 12px;
              font-weight: 100;
              display: flex;
              justify-content: space-between;
              padding-right: 10px;

              i{
                font-size: 12px;
              }

              @include bp(Kid) {
                display: block;
                padding-right: 0;
                white-space: nowrap;
              }            

              @include bp(Mama) {
                font-size: 14px;

                i{
                  font-size: 14px;
                }  
              }            
            }

            &_smol {
              font-size: 12px;
              font-weight: 100;
            }

          }

          &_switch {
            display: flex;

            &_selected {
              transform: rotate(95deg);
            }

            &_toggle {
              color: $navy;
              background-color: $lightGrey;
              border-radius: 99%;
              padding: 0.5rem;
              width: 30px;
              height: 30px;

              &:hover {
                cursor: pointer;
                color: $lightGrey;
                background-color: $navy;          
                transition-duration: 0.2s;
              }

              &_show{
                display: flex;
              }
  
              &_hide {
                display: none;
              }

            }

          }

          &_show_section {
            display: block;
          }
    
          &_hide_section {
            display: none;
          }

          &_button {
            border: none;
            background-color: transparent;
          }

        }

        &__each {
          @include flex;
          width: 100%;
          border-radius: 10px;
          gap: 10px;
        }

        &_value_toggle {
          display: flex;
          justify-content: center;
          align-content: center;
          width: 10%;
        }

        &_item {
          width: 100%;

          &_number {
            font-size: 16px;
            font-weight: 900;

            @include bp(Mama) {
              font-size: 18px;
            }        
          }

          &_title {
            font-size: 12px;

            @include bp(Mama) {
              font-size: 14px;
            }        
          }

          &_value_toggle {
            display: flex;
            justify-content: center;
            align-content: center;
            width: 10%;
          }



          &_value_plain {
            background: transparent;
            color: $teal;

            &_smol {
              font-size: 11px;
              color: rgba(0, 0, 0, 0.67);
            }

          }

          &_value_totals {
            padding: 1rem;
            border-radius: 10px;
            background: transparent;
            color: $navy;
            border: 1px solid $navy;
          }

          &_value_teal {
            padding: 1rem;
            border-radius: 10px;
            background: $teal;
            color: white;
          }
          
          &_value_due {
            padding: 1rem;
            border-radius: 10px;
            background: rgb(224, 30, 30);
            color: white;
          }

          &_value_one {
            padding: 1rem;
            border-radius: 10px;
            background: rgb(71, 172, 235);
            color: white;
          }

          &_value_two {
            padding: 1rem;
            border-radius: 10px;
            background: $navy;
            color: white;
          }

          &_value_three {
            padding: 1rem;
            border-radius: 10px;
            border: 1px solid $lightGrey;
            background: rgb(255, 255, 255);
          }

          &_value_four {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem;
            border-radius: 10px;
            border: 1px solid $lightGrey;
            background: rgb(255, 255, 255);
            color: $navy;
          }

        }

      }

    }

  }

  &__options {
    position: absolute;
    top: 112px; left: 150px;
    height: calc(100vh - 57px - 55px);
    border-right: 1px solid $lightGrey;
    width: 400px;
    background: #FFF;
    // overflow-y: scroll;

    section {
      padding: 1rem;
      border-bottom: 1px solid $lightGrey;
      // &.active {
      //   background: $grey;
      //   h5 {
      //     color: #FFF;
      //   }
      // }
    }

    button.add {
      position: relative;
      width: 100%; min-height: 50px;
      background: #FAFAFA;
      border: 2px solid $lightGrey;
      padding: .25rem 0;
      color: $grey;
      cursor: pointer;
      border-radius: 10px;
      &:hover {
        color: $navy;
        background: #EFEFEF;
        border-color: $grey;
        p {
          color: $navy;
        }
      }
  
      i {
        position: absolute;
        top: 50%; left: .5rem;
        transform: translateY(-50%);
        margin-top: 1px;
        font-size: 2rem;
      }
  
      p {
        margin: 0;
        font-size: 1.25rem;
      }
    }
  }

  &__content {
    width: 100%;
    // height: calc(var(--vh, 100vh) - 57px - 52px);
    // padding: 1rem;
    // background: #FAFAFA;

    &--indemnitorsPannel {
      display: flex;
      height: calc(var(--vh, 100vh) - 57px - 52px);
      overflow-y: scroll;
      padding-bottom: 10px;

      &__editButton {
        font-size: 12px;
        padding: 6px 12px;
        border: 2px solid $navy;
        border-radius: 5px;
        font-family: 'Rubik', sans-serif;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 400;
        background-color: $navy;
        color: white;

        &:hover {
          background-color: transparent;
          color: $navy;
        }

        i {
          font-size: 12px;
          margin-right: 5px;
        }
      }


      &--infoCollect {

      }

      &--timeline {
        background: #fff;
        position: sticky;
        top: 0;
        align-self: flex-start;
        height: calc(var(--vh, 100vh) - 14vh);
        overflow-y: scroll;
        padding: 2rem;
        width: 30%;
        border: 1px solid $lightGrey;
        border-radius: 10px;
        flex-grow: 1;
        margin: 0px 10px;
        i {
          margin-right: 5px;
        }
      }

    }

    &--snapshot {
      width: 100%;
      padding: 0;
    }
    &--indemnitorsNew {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .wizard__snapshot {
        display: none;
      }
    }
  }
  &__userSearch {
    // margin-top: 100px;
    width: 100%;
    max-width: 500px;
    // border: 4px solid $lightGrey;
    // background: #FFF;
    // border-radius: 10px;
    // padding: 1rem;

    &__action {
      margin-top: .5rem;
    }

    header {
      h3 {
        margin: 0 0 .5rem;
        font-size: 1.5rem;
        color: $navy;
      }
      p {
        margin: 0 0 .5rem;
        font-size: .825rem;
      }
    }

    .userSearch {
      padding: 0;
      border-bottom: 0;
    }
  }

  body.full-width & {
    display: block;
    padding: 0;
    &__content {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }
  body.has-error & {
    min-height: calc(100vh - 57px - 55px - 30px);
    .wizard__options {
      top: 142px;
      height: calc(100vh - 57px - 55px - 30px);
    }
  }

  &_table {
    font-size: 12px;
    text-align: left;
    width: 100%;
    margin-bottom: 1rem;

    @include bp(Mama) {
      font-size: 14px;
    }

    th {
      font-size: 14px;
      font-weight: 600;
      color: $navy;
    }

  }

}

.wizard__options {
  .sidebarPod {
    @include pod;
    &--defendant {
      margin-bottom: 0;
      h4 {
        font-size: 1.25rem;
      }
      p {
        font-size: .825rem;
        color: $grey;
      }
    }
  }
}

.userSearch {
  position: relative;

  &__index {
    margin-top: 1rem;
  }

  .addButton {
    margin-top: 1rem;
    font-size: 1.5rem;
  }

  .ais-SearchBox {
    width: 100%;
    // margin-bottom: 1rem;

    form {
      display: block;
      position: relative;

      input[type="search"] {
        @include input;
        -webkit-appearance:none;
        @include bp(Mama) {
          font-size: .875rem;
        }
        &:focus {
          border-color: $navy;
        }
      }

      button {
        &[type="submit"] {
          height: 36px;
          border: 2px solid $grey;
          background: $grey;
          border-radius: 5px;
          color: #FFF;
          outline: none;
          cursor: pointer;
  
          position: absolute;
          top: 4px; right: 4px;
          width: 30px; height: 30px;
          font-size: 1rem;
          text-align: center;
  
          svg {
            fill: #FFF;
          }
  
          &:hover {
            background: #FFF;
            color: $navy;
            svg {
              fill: $navy;
            }
          }
        }
        &[type="reset"] {
          height: 36px;
          border: 2px solid $grey;
          background: $grey;
          border-radius: 5px;
          color: #FFF;
          outline: none;
          cursor: pointer;
  
          position: absolute;
          top: 4px; right: 4px;
          width: 30px; height: 30px;
          font-size: 1rem;
          text-align: center;
  
          svg {
            fill: #FFF;
          }
  
          &:hover {
            background: #FFF;
            color: $navy;
            svg {
              fill: $navy;
            }
          }

          display: none
        }
      }
    }
  }

  .ais-Hits {
    ul {
      margin: .5rem 0 0;
      padding: 0;
      list-style: none;
    }
    li {
      margin-bottom: .5rem;
    }
  }

  .mainSearch__item {
    background: #FAFAFA;
    border: 1px solid $lightGrey;
    cursor: default;
  }

  .noResults {
    padding: 0;
  }
  button.add {
    width: 100%;
    background: #FAFAFA;
    border: 2px solid $lightGrey;
    padding: .5rem 0;
    font-size: 3rem;
    color: $grey;
    cursor: pointer;
    border-radius: 10px;
    &:hover {
      color: $navy;
    }
  }
}

form.register__form--defendantInfo {
  display: block;
  margin-top: 2rem;
  padding-bottom: 0;

  .register__form__actions {
    width: 100%;
    @include flex;
    & > button {
      width: 49.5%;
    }
  }
}

.wizard__notify {
  @include flex;
  padding: 1rem;
  background: $grey;
  border-bottom: 6px solid #DFDFDF;
  &__container {
    border-bottom: 1px solid #FAFAFA;
  }

  &__left {
    color: #FFF;
    h2 {
      margin: 0 0 .25rem;
      font-size: 1.25rem;
    }
    p {
      margin: 0;
      font-size: .825rem;
    }
  }
}

.wizard__snapshot {
  padding: 1rem 1rem 0;
  .section__container {
    @include flex;
    padding: 1rem;
    border: 2px solid $lightGrey;
    background: #FFF;
    border-radius: 5px;
  }

  .content {
    position: relative;
    padding-left: 86px;
    h3 {
      margin: 0 0 .25rem;
      button {
        margin-left: .5rem;
        padding: 0;
        background: transparent;
        border: 0;
        color: $grey;
        cursor: pointer;
        &:hover {
          color: $navy;
        }
        font-size: 1rem;
      }
    }
  }

  &__info {
    @include flex;
    margin-bottom: .5rem;
    font-size: .75rem;
    color: $grey;
  }

  .snapshot {
    &__picture {
      position: absolute;
      top: 50%; left: 0;
      transform: translateY(-50%);
      @include unicorn;
      width: 70px; height: 70px;
      border: 2px solid $lightGrey;
      background: #FAFAFA;
      border-radius: 50%;
      overflow: hidden;
      i {
        font-size: 2.75rem;
        color: $lightGrey;
      }
    }
    &__details {
      @include flex;
      justify-content: flex-start;
      & > div {
        margin-right: 1.5rem;
        p {
          margin: 0 0 .25rem;
          font-size: .75rem;
          &:last-child {
            margin: 0;
          }
          a {
            color: $blue;
          }
        }
      }
    }
    &__charges {
      margin-top: 1rem;
      h5 {
        margin: 0 0 .25rem;
      }
      p {
        margin: 0;
        font-size: .825rem;
      }
    }
    &__actions {
      @include flex;
      justify-content: flex-end;
      button {
        margin-left: 1rem;
        padding: 0;
        background: transparent;
        border: 0;
        font-size: 2rem;
        color: $grey;
        cursor: pointer;
        &:hover {
          color: $navy;
        }
      }
    }
  }

  .amountDue {
    background: $blue;
    border-radius: 4px;
    color: #FFF;
    text-align: center;
    padding: .5rem;

    label {
      display: block;
      font-size: .75rem;
      text-transform: uppercase;
    }

    strong {
      display: block;
      font-size: 2rem;
    }
  }
}

.wizard__status {
  padding: 1rem;
  section {
    margin-bottom: 1rem;

    &.split {
      @include flex;

      & > div {
        width: 49.5%;
      }
    }

    div.section__container {
      padding: 1rem;
      border: 2px solid $lightGrey;
      background: #FFF;
      border-radius: 5px;

      .content {
        .noResults {
          margin: 0;
        }
      }
    }
  }

  &__indemnitors {
    .content {
      button {
        margin-right: .5rem;
        border: 2px solid $lightGrey;
        background: #FAFAFA;
        border-radius: 5px;
        width: 100px;
        & > div {
          font-size: 3rem;
        }
        label {
          display: block;
          padding: .25rem 0;
          color: $grey;
          font-size: .75rem;
          font-weight: 700;
          text-transform: uppercase;
        }
      } 
    } 
  }
}

.defendant {
  border-right: 1px solid $lightGrey;
  padding:1rem;
  min-width: 18vw;
  font-family: 'Rubik', sans-serif;

  &_info {
    align-content: center;

    &_progress {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      margin: 1.5rem 0 1.5rem 0;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.025);
    }

    &_details {
      margin-top: 1rem;
      font-size: 14px;

      &_row {
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin-bottom: 10px;

        &_options {
          display: flex;
          justify-content: flex-end;
          gap: 5px;
          width: 33%;
          position: relative;
        }

        &_status {
          margin: 0;
          padding: 0 0 0 0;

          &:hover {
            color: #000;
            cursor: pointer;
          }

          @include bp(Kid) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
          }          

          &_info {
            font-size: 14px;
            margin: 0;
            padding: 0;
          }

          &_icon_neutral {
            margin-left: 5px;
            font-size: 16px;
            color: rgba(134, 136, 163, 0.591);

            &:hover {
              color: rgba(41, 41, 45, 0.885);
            }
          }



          &_icon_success {
            @include flex;
            font-size: 16px;
            color: green;
            span {
              font-family: 'Rubik', sans-serif !important;
              font-size: 10px;
              letter-spacing: 1px;
              margin: 0px 5px;

              @include bp(Mama) {
                font-size: 12px;
              }          

            }
          }

          &_icon_pending {
            @include flex;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.205);
            span {
              font-family: 'Rubik', sans-serif !important;
              font-size: 10px;
              letter-spacing: 1px;
              margin: 0px 5px;

              @include bp(Mama) {
                font-size: 12px;
              }          
            }
          }
          
          &_icon_alert {
            @include flex;
            font-size: 16px;
            color: rgb(207, 40, 60);
            span {
              font-family: 'Rubik', sans-serif !important;
              font-size: 12px;
              letter-spacing: 1px;
              margin: 0px 5px;
            }
          }
      
        }
      }

    }

    &_meta {
      font-size: 12px;
      padding: 0;
      margin: 0; 
    }
  }

  &_butt {
    cursor: pointer;

    &_primary {
      font-family: 'Rubik', sans-serif;
      background-color: $navy;
      border: none;
      border-radius: 5px;
      color: white;
      padding: 7px 10px 7px 10px;
      width: 100%;

      &:hover {
        cursor: pointer;
        color: rgb(255, 255, 255);
        box-shadow: 0px 0px 40px rgba(90, 109, 228, 0.42);
        background-color: #2B3866;
        transition-duration: 0.25s;
      }

    }

    &_alert {
      font-family: 'Rubik', sans-serif;
      background-color: rgb(255, 46, 70);
      border-radius: 5px;
      color: rgb(255, 255, 255);
      padding: 7px 10px 7px 10px;
      border: none;
      width: 100%;

      &:hover {
        cursor: pointer;
        color: rgb(255, 255, 255);
        box-shadow: 0px 0px 40px rgba(247, 52, 27, 0.226);
        background-color: #df3048;
        transition-duration: 0.25s;
      }
    }

  }

  h4 {
    padding: 0;
    margin: 0;
    font-size: 16px;
    color: rgb(0, 0, 0);
    font-weight: 600;
  }

}

.indem {
  @include flex;
  flex-grow: 1;

  &__container{
    background-color: white;
    padding: 1rem;
    margin: 1rem 0;
    border: 1px solid $lightGrey;
    border-radius: 10px;

    &__info {
      padding: 0.5rem 0rem;;

    }

    &__user{
      @include flex;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid $lightGrey;
      font-weight: 900;

      &__status {
        background-color: $lightGrey;
        color: $navy;
        font-weight: 900;
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 5px;
      }
    }
  }
}

.wizard__automation {

}