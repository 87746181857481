.caseTabs__sections__container--fileStore, .caseTabs__sections__container--notes, .caseTabs__sections__container--logs {
  .status__container {
    display: none;
  }
}

.fileStore {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  padding-bottom: 2rem;

  &__wrapper {
    .viewPdfModal {
      p.big {
        display: none !important;
      }
      .imageUploads {
        min-height: 100%;
        @include unicorn;
      }
    }
  }

  &__container {
    .defendant__header {
      padding: 0 2rem;
    }
    p.small {
      margin-top: 0;
    }
  }

  &__uploader {
    margin-bottom: 2rem;

    @include bp(Kid) {
      padding: 0 2rem;
    }
  
    .uploader {
      margin: 0;
      cursor: pointer;
      overflow: hidden;
    }
  }

  &__editor {
    margin: 0 0 1rem 0;
    padding: 1rem 0;
    border-top: 1px solid $lightGrey;
    border-bottom: 1px solid $lightGrey;
    background: #FAFAFA;

    h5 {
      margin: 0 0 1rem;
      padding: 0 2rem;
      font-size: .85rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $navy;
    }

    .register__form {
      max-width: 100%;
      padding: 0 1rem;

      @include bp(Kid) {
        padding: 0 2rem;
      }
    }

    .infoBox__actions {
      @include flex;
      flex-direction: column-reverse;

      @include bp(Kid) {
        flex-direction: row;
      }
    
      & > div {
        width: 100%;
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;

          button {
            margin: 0.5rem auto;
          }
        }

        button {
          display: block;
          margin: 0 auto;
        }

        @include bp(Kid) {
          width: 50%;

          &:first-child, &:last-child {
            button {
              display: inline-block;
              margin: 0.25rem;
            }
          }
        }    
      }
    }
  }

  &__file {
    display: inline-block;
    margin: 0 1rem 1rem 0;
    width: 47%;
    border: 1px solid $lightGrey;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    @include bp(Mama) {
      width: 30%;
    }

    &:nth-child(2n) {
      margin-right: 0;
      @include bp(Mama) {
        margin-right: 1rem;
      }
    }

    &:nth-child(3n) {
      @include bp(Mama) {
        margin-right: 0;
      }
    }

    &__image {
      padding: 2rem 0;
      background: #FAFAFA;
      text-align: center;
      & > i {
        font-size: 7rem;
        color: $grey;
      }
    }

    &:hover {
      .fileStore__file__image {
        background: #EFEFEF;
        i {
          color: $navy
        }
      }
    }

    footer {
      padding: .5rem;
      border-top: 1px solid $lightGrey;

      h5 {
        margin: 0 0 .5rem;
        white-space: nowrap;
      }
    }
  }

  &__fileRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0 0 .5rem 0;
    padding: 0;
    border: 1px solid $lightGrey;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;

    &__container {
      width: 100%;
      @include flex;
      justify-content: flex-start;
    }

    &__printable {
      width: 100%;
      @include flex;
      gap: .5rem;
      & > * {
        &:nth-child(1) {
          flex: 0 0 calc(100% - 36px);
        }

        &:nth-child(2) {
          margin-top: -5px;
          flex: 0 0 20px;
          @include flex;
          justify-content: flex-end;
        }
      }
    }

    @include bp(Kid) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
    }
  
    &:hover {
      background: #FAFAFA;
      .fileStore__fileRow__image {
        background: #EFEFEF;
        i {
          color: $navy
        }
      }
    }

    &__image {
      // position: absolute;
      // top: 50%; left: 0;
      // transform: translateY(-50%);
      @include unicorn;
      background: #FAFAFA;
      margin: 1rem;

      @include bp(Kid) {
        width: 40px;
        margin: 0;
        border-right: 1px solid $lightGrey;
      }
  
      & > i {
        margin-top: 1px;
        font-size: 1.5rem;
        color: $grey;
      }
    }

    &__content {
      @include flex;
      flex-direction: column;
      // margin-left: 40px;
      padding: .5rem;

      @include bp(Kid) {
        flex-direction: row;;
        width: calc(100% - 40px);
      }

      h5 {
        margin: 0;
        text-align: center;

        @include bp(Kid) {
          text-align: left;
          white-space: nowrap;
        }  
      }
      p {
        margin: .25rem 0 0;
        font-size: .7rem;
      }

      &__right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        .tagsActions {
          margin-top: 1rem;
        }

        @include bp(Kid) {
          flex-direction: row;

          .tagsActions {
            margin-top: 0;
            margin-right: 1.5rem;
          }  
        }
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      margin-top: 1rem;

      @include bp(Kid) {
        justify-content: flex-end;
        margin-top: 0;
      }
    
      button {
        margin-right: .5rem;
        border: 0;
        background: transparent;
        padding: 0;
        font-size: 1.25rem;
        color: $grey;
        outline: none;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          color: $navy;
        }
        &:disabled {
          opacity: .4;
        }
      }
    }
  }

  &__requestFile {
    margin: 0 0 1rem 0;
    padding: 1rem 0;
    border-top: 1px solid $lightGrey;
    border-bottom: 1px solid $lightGrey;
    background: #FAFAFA;

    header {
      padding: 0 1rem;

      @include bp(Kid) {
        padding: 0 2rem;
      }
    
      h5 {
        margin: 0;
        font-size: .85rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $navy;
      }
      p:not(.error) {
        margin: .25rem 0 1rem;
        font-size: .75rem;
      }
    }

    .register__form {
      max-width: 100%;
      margin: 0;
      padding: 0;
    }

    .hightlighted {
      margin-bottom: 1rem;
      padding: 1rem 0 0;
      background: #edf5ff;
      border-top: 1px solid $lightGrey;
      border-bottom: 1px solid $lightGrey;
    }

    .form-row {
      padding: 0 1rem;

      @include bp(Kid) {
        padding: 0 2rem;
      }
      
      &.hightlighted {
        padding: 1rem 2rem 0;
      }
    }

    .actions {
      @include flex;
      padding: 0 1rem;

      @include bp(Kid) {
        padding: 0 2rem;
      }

      button {
        width: 49.5%;
      }
    }
  }

  &__requestReceipts {
    @include pod;
    &__container {
      // margin-bottom: 1rem;
      padding: 0 2rem 1rem;
    }
    &__left {
      width: calc(100% - 120px);
    }
    &__right {
      width: 120px;
    }
    p {
      font-size: .75rem;
    }
  }

  .tagsActions {
    .tags {
      overflow: hidden;
      margin: 0;
      padding: 0;
      white-space: nowrap;
      .tag {
        padding-right: 5px;
      }
    }
  }
}

.layout--documentUpload {
  #root main {
    background: #EFEFEF;
    padding-bottom: 0;
  }
}

.documentUpload {
  max-width: 700px;
  margin: 0 auto;
  min-height: calc(var(--vh, 100vh) - 57px);
  border-left: 1px solid $lightGrey;
  border-right: 1px solid $lightGrey;
  background: #FFF;

  body.header--user & {
    min-height: calc(var(--vh, 100vh) - 91px);
  }

  &--expired {
    padding: 2rem 1rem 0;
    @include bp(Mama) {
      @include unicorn;
      padding: 0 1rem;
    }
  }

  &__fileRequestAction {
    @include unicorn;
    padding: 0 1rem;
    button {
      margin: 0 auto;
      font-size: 1rem;
      font-weight: 700;
    }
  }

  &__expired {
    max-width: 400px;
    padding: 3rem;
    text-align: center;
    border: 2px solid $lightGrey;
    border-radius: 20px;

    h2 {
      margin: 0;
      font-size: 3rem;
      color: $navy;
    }

     p {
       margin: 1rem 0 0;
     }
  }

  &__details {
    padding: 2rem 0;

    header {
      padding: 0 2rem 2rem;
      text-align: center;
      h2 {
        margin: 0 0 .5rem;
        color: $navy;
      }
      p {
        margin: 0;
      }
      .expiresMessage {
        margin: 1rem 0 0;
        font-size: .75rem;
      }
    }

    .secureUpload__block.secureUpload__block--full {
      padding: 2rem 2rem;
    }
  }
}