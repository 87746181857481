@import "styles/basics/mixins";

.link {
  margin-left: 0.75rem;
  font-size: 1.25rem;
  color: #6f7f9f;
  background: transparent;
  border: 0;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  @include bp(Mama) {
    margin-left: 1.5rem;
  }

  &:hover {
    color: #4259ed;
  }
}
