@import "styles/basics/mixins";
@import "styles/basics/vars";

.user {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #dfe6f4;
  background: #fff;
  margin-top: 1px;
  @include bp(Mama) {
    justify-content: space-between;
  }

  body:global(.layout--learningCenter) &,
  body:global(.layout--signup) & {
    display: none;
  }

  p {
    margin: 0;
    font-size: 0.85rem;

    span {
      display: none;
      @include bp(Toddler) {
        display: inline;
      }
    }
  }

  a {
    color: $teal;
  }
}

.bondsman {
  display: none;
  @include bp(Mama) {
    display: block;
  }
}
