.main {
  padding-top: 57px;
  padding-bottom: 100px;

  body:global(.header--user) & {
    padding-top: 90px;
  }

  body:global(.is_viewing_signature) & {
    display: none;
  }
}
