.uploader {
  margin: 1rem 0 2rem;
  border: 1px solid $greyC;
  background: $greyEa;
  border-radius: 5px;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__progress {
    width: 100%;
    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      border-bottom: 1px solid $greyC;

      &--info {
        width: calc(100% - 300px);
      }
      &--progress {
        width: 300px;
      }
    }
  }
}

.uploadedFiles {

  &__switches {
    display: flex;
    justify-content: flex-end;

    button, a {
      position: absolute;
      top: 0; right: 0;
      width: 60px; height: 100%;
      border: 0;
      border-left: 2px solid #dfe6f4;
      background: #FFF;
      color: $grey;
      cursor: pointer;
      outline: none;

      @include unicorn;
      text-decoration: none;

      &:hover {
        color: $navy;
      }

      i {
        font-size: 1.5rem;
      }

      // &:first-child {
      //   right: 60px;
      // }

      // &--settings {
      //   right: 60px;
      // }
    }
  }

  // &__switch {
  //   margin-right: 1rem;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   &:last-child {
  //     margin-right: 0;
  //   }
  //   span {
  //     margin-top: .5rem;
  //     display: inline-block;
  //     font-size: .65rem;
  //     text-transform: uppercase;
  //     text-align: center;
  //   }
  // }

  &__file {
    position: relative;
    z-index: 1;
    margin: 0 auto 30px;
    padding: 10px 135px 10px 15px;
    border: 2px solid #dfe6f4;
    background: #FFF;
    border-radius: 5px;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      h3 {
        margin: 0;
        padding: 0;
      }

      p {
        margin: 0;
        font-size: .75rem;
      }

      .tags {
        margin-top: .25rem;
        margin-bottom: 4px;
      }

      &--actions {
        margin-top: .5rem;
        a, button {
          margin-right: .5rem;
          color: $teal;
          text-decoration: none;
          font-size: .75rem;
          font-weight: 600;
          background: transparent;
          border: 0;
          padding: 0;
          cursor: pointer;
          outline: none;

          &:hover {
            color: $navy;
          }
        }
      }
    }
  }

  &__editor {
    margin-bottom: .5rem;
    
    input {
      margin-right: 1rem;
      padding: 8px;
      border: 1px solid #6F7F9F;
      border-radius: 3px;
      width: calc(100% - 90px);
    }

    button.butt {
      width: 60px;
      height: 32px;
    }
  }
}

body.template-offices {
  .register__form__block--documents {
    padding: 0;
    .error__container {
      padding: 0 1rem .5rem;
    }
  }
  .fileSettings {
    max-width: 100%;
    padding: 0 0 4rem;
    border-top: 1px solid $lightGrey;
    header {
      margin: 0;
      padding: .75rem 0 2.5rem;
      h3 {
        margin: 0 0 .5rem;
        padding: 0;
      }
      p {
        margin: 0;
        padding: 0;
      }
    }
    .settings {
      padding: 0 1rem;
    }
    .register__form__actionsBar__actions {
      padding: 0 1rem;
      & > button {
        width: 32%;
        &.butt--red {
          border-color: $danger;
          background: $danger;
          color: #FFF;
          &:hover {
            background: #FFF;
            color: $danger;
          }
        }
      }
    }
  }
}

.fieldSettings {
  padding: 1rem;
  background: #EFEFEF;
  border-top: 1px solid $lightGrey;
  border-bottom: 1px solid $lightGrey;
  margin-bottom: 2rem;

  &__content {
    padding: .5rem;
    background: #FFF;
    border: 1px solid $lightGrey;

    & > div {
      margin-bottom: .5rem;
      &:last-child {
        margin: 0;
      }
    }
  }

  &__field {
    @include flex;
    border-bottom: 1px solid $lightGrey;
    &:last-child {
      border-bottom: 0;
    }
    & > div {
      &:first-child {
        width: 38%;
        padding: 0 .5rem;
      }
      &:last-child {
        width: 60%;
        padding: .5rem;
      }
    }
  }
}
