@import "styles/basics/vars";
@import "styles/basics/mixins";

@mixin can-toggle-branding(
  $can-toggle-off-color: #777, 
  $can-toggle-on-color: #47ACEB,
  $can-toggle-inactive-text: rgba(white, 0.5),
  $can-toggle-transition: cubic-bezier(0,1,0.5,1)
) {
  
  input[type="checkbox"] {
    
    &[disabled] ~ label {
      color: rgba($can-toggle-off-color, 0.5);
    }
    
    &:focus ~ label, &:hover ~ label {
      .canToggle__switch {
        background-color: $can-toggle-off-color;
        &:after { color: darken($can-toggle-off-color, 10%); }
      }
    }
    &:hover ~label { color: darken($can-toggle-off-color, 5%); }
    &:checked {
      ~ label {
        &:hover { color: darken($can-toggle-on-color, 3%); }
        
        .canToggle__switch {
          background-color: lighten($can-toggle-on-color, 5%);
          &:after { color: darken($can-toggle-on-color, 5%); }
        }
      }
      
      &:focus, &:hover {
        ~ label {
          .canToggle__switch {
            background-color: $can-toggle-on-color;
            &:after { color: darken($can-toggle-on-color, 10%); }
          }
        } 
      }
    }
  }
  
  label {
    
    .canToggle__label-text { flex: 1; }
    
    .canToggle__switch {
      transition: background-color 0.3s $can-toggle-transition;
      background: lighten($can-toggle-off-color, 5%);
      &:before { color: $can-toggle-inactive-text; }
      &:after {
        // Autoprefixer choked here, so making the prefixes explicit
        -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
        transition: transform 0.3s $can-toggle-transition;
        color: $can-toggle-off-color; 
      }
    }
  }
}

@mixin can-toggle-appearance 
( 
  $can-toggle-width: 90px,
  $can-toggle-height: 26px,
  $can-toggle-border-radius: 4px,
  $can-toggle-offset: 2px,
  $can-toggle-label-font-size: 14px,
  $can-toggle-switch-font-size: 12px,
  $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)
){
  $can-toggle-switch-width: calc($can-toggle-width / 2);
  
  input[type="checkbox"] {

    &:focus ~ label, &:hover ~ label {
      .canToggle__switch {
        &:after { box-shadow: $can-toggle-shadow; } 
      }
    }
    
    &:checked {
      ~ label {
        .canToggle__switch {
          &:after { transform: translate3d($can-toggle-width - ($can-toggle-switch-width + $can-toggle-offset),0,0); }  
        }
      }
      &:focus, &:hover {
        ~ label {
          .canToggle__switch { &:after { box-shadow: $can-toggle-shadow; } }
        } 
      }
    }
  }
  
  label {
    font-size: $can-toggle-label-font-size;
    
    .canToggle__switch {
      cursor: pointer;
      height: $can-toggle-height;
      flex: 0 0 $can-toggle-width;
      border-radius: $can-toggle-border-radius;
      
      &:before {
        left: calc($can-toggle-width / 2);
        font-size: $can-toggle-switch-font-size; 
        line-height: $can-toggle-height;
        width: calc($can-toggle-width / 2);
        padding: 0 12px;
      }
      
      &:after {
        top: $can-toggle-offset; left: $can-toggle-offset;
        border-radius: calc($can-toggle-border-radius / 2);
        width: $can-toggle-switch-width - $can-toggle-offset; 
        line-height: $can-toggle-height - ($can-toggle-offset*2);
        font-size: $can-toggle-switch-font-size;
      }
      
      &:hover {
        &:after { box-shadow: $can-toggle-shadow; }
      }
    } 
  }
}

.canToggle {
  position: relative;
  width: 90px;
  *, *:before, *:after {
    box-sizing: border-box;
  }

  &__container {
    @include flex;
    gap: 1rem;
    margin-bottom: .5rem;
    &:last-child {
      margin-bottom: 0;
    }
    & > label {
      font-size: .825rem;
    }

    &__joined {
      justify-content: flex-start;
      gap: .5rem;
    }
  }
  
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0; left: 0;
    
    &[disabled] ~ label {
      pointer-events: none;
      .canToggle__switch { opacity: 0.4; }
    }
    
    &:checked {
      ~ label {
        
        .canToggle__switch {
          
          &:before {
            content: attr(data-unchecked);
            left: 0;
          }
        
          &:after {
            content: attr(data-checked);
          }  
        }
      }
      
      &:focus, &:hover {
        ~ label {
        } 
      }
    }
  }
  
  label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;
    
    .canToggle__label-text { 
      flex: 1;
      padding-left: 32px;
    }
    
    .canToggle__switch {
      position: relative;
      
      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center;
      }
      
      &:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-transform: uppercase;
        text-align: center;
        background: white;
        transform: translate3d(0,0,0);
      }

    }
    
  }
  
  @include can-toggle-branding;
  @include can-toggle-appearance;
}