.bypassText {
  font-size: .825rem;
  font-weight: 700;
  color: $completed;
}

.dashboard {

  &__stats {
    
    &__stat {
      position: relative;
      width: 24%;
      border: 2px solid $teal;
      text-align: center;
      padding: .75rem .5rem;
      border-radius: 5px;
      cursor: pointer;
      background: #FFF;
      outline: none;

      &:after, &:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      
      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #FFF;
        border-width: 20px;
        margin-left: -20px;
      }

      &:before {
        border-color: rgba(194, 225, 245, 0);
        border-top-color: $teal;
        border-width: 23px;
        margin-left: -23px;
      }

      &:hover {
        background: #EFEFEF;
      }

      p {
        font-size: .7rem;
        font-weight: 700;
        margin: 0 0 .5rem;
        color: $teal;
        text-transform: uppercase;
      }

      strong {
        display: block;
        font-size: 2rem;
        color: $teal;
      }

      span {
        font-size: 1rem;
        text-transform: uppercase;
        color: $teal;
      }

      &--active {
        &:before, &:after {
          content: "";
        }
        &:hover {
          &:after {
            border-top-color: #EFEFEF;
          }
        }
      }

      &--grey {
        opacity: .5;
        border-color: $grey;
        p, strong, span {
          color: $grey;
        }
      }

    }

    &--5up {
      .dashboard__stats__stat {
        width: 19%;
        &:nth-child(1) p { padding: 0 1.25rem; }
        &:nth-child(2) p { padding: 0 1.25rem; }
        &:nth-child(5) p { padding: 0 1.25rem; }
      }
    }

    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;

      &--top {
        button.dashboard__stats__stat {
          width: 49%;
        }
      }
    }
  }

  &__filters {
    position: relative;
    input[type="search"] {
      padding: 10px;
      width: 100%;
      border: 1px solid #DFE6F4;
      border-radius: 5px;
      font-size: 1rem;
      outline: none;
      -webkit-appearance:none;
      @include bp(Mama) {
        font-size: .875rem;
      }

      &:focus {
        border-color: #24C0AC;
      }
    }
  }

  &__stripe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #dfe6f4;
    border-radius: 5px;
    padding: 1rem;

    p {
      margin: 0 1rem 0 0;
      color: #6F7F9F;
      font-size: .85rem;
    }

    button {
      width: 200px;
    }
  }

  &__customers {
    .pod {
      margin-bottom: 15px;
      h5 {
        margin: 0 0 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span.status--red {
          color: $danger;
        }

        span {
          &.status--teal {
            font-weight: 600;
            color: $teal;
          }
        }
      }
      h3 {
        margin: 5px 0 0;

        span {
          font-size: .85rem;
          font-weight: 300;
          margin-right: .5rem;
        }
      }
      p {
        margin: 5px 0 0;
      }

      .tags {
        margin-bottom: 0;
      }

      &__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        & > div {
          width: 49%;

          &:last-child {
            p {
              text-align: right;
            }
          }
        }
      }
    }
  }

  &__actions {
    padding: 1.25rem 0;
    button {
      width: 100%;
      margin-bottom: .5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.dashboard__customers {
  padding: 1.25rem 0 0;
}

.no__customers {
  margin: 0 0 10px;
  font-size: 1.125rem;
  color: #6F7F9F;
  text-align: center;
}

.charges_buttons {
  margin-bottom: 1rem;
}

.bond__warrant {
  margin-top: 1rem;
  border-top: 1px solid #999;
  .bond__charges {
    &__charge {
      display: flex;
      justify-content: space-between;
    }
  }
}

.defendantInfo__tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 2rem 0 0;

  &--notActive {
    padding-bottom: 1.5rem;
  }

  & > button.defendantInfo__tabs__tab {
    position: relative;
    width: 49.5%;
    border: 2px solid $grey;
    text-align: center;
    padding: .75rem .5rem;
    border-radius: 5px;
    cursor: pointer;
    background: #FFF;
    outline: none;
    opacity: .5;

    span {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 700;
    }

    &:hover {
      background: #EFEFEF;
    }

    &:after, &:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    
    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #FFF;
      border-width: 20px;
      margin-left: -20px;
    }

    &:before {
      border-color: rgba(194, 225, 245, 0);
      border-top-color: $teal;
      border-width: 23px;
      margin-left: -23px;
    }

    &--active {
      opacity: 1;
      border-color: $teal;
      &:before, &:after {
        content: "";
      }
      &:hover {
        &:after {
          border-top-color: #EFEFEF;
        }
      }
      span {
        color: $teal;
      }
    }

  }
}

.indemnitor__section {
  padding-top: 3rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
    }
  }

  &__content {
    position: relative;

    &__mask {
      position: absolute;
      top: 0; left: 0;
      z-index: 1;
      background: rgba(255,255,255,.85);
      width: 100%; height: 100%;
    }
  }

  &--defendant {
    padding-top: 1rem;
    // padding-bottom: 2rem;
    border-bottom: 2px solid #A2AFCD;
    .accordian.accordian--expandable h3.accordianHeader {
      i {
        display: none;
      }
      & > span.accordianHeader__meta .pill {
        margin-right: 0;
      }
    }
    .filesArea__areas__area header {
      display: none;
    }
  }

  &__defendantInfo {
    &__actions {
      padding-bottom: 1rem;
      button {
        width: 100%;
      }
    }
  }

  &--active {
    border-bottom: 0;
    .indemnitor__section__content__mask {
      display: none;
    }
  }
}

.accordian.accordian--expandable {
  h3.accordianHeader {
    position: relative;
    margin: 1em 0 0;
    padding-bottom: 1rem;
    cursor: pointer;
    & > span.accordianHeader__meta {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .pill {
        margin-right: 1rem;
      }
    }
    i {
      display: block;
    }
  }
  .accordian__content {
    display: none;
  }
  &.accordian--expanded {
    h3.accordianHeader {
      margin: 1em 0 1em;
      padding-bottom: 0;
      i {
        transform: rotate(180deg);
      }
    }
    .accordian__content {
      display: block;
    }
  }
}

.tabs_sections {
  h3.filterHeader {
    display: flex;
    justify-content: space-between;

    button {
      display: flex;
      align-items: center;
      border: 0;
      background: #576aa8;
      color: #FFF;
      padding: 3px 12px;
      border-radius: 12px;
      font-size: .6rem;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
      &:hover {
        background: $navy;
      }

      i {
        margin-left: .5rem;
      }
    }
  }
  h3.accordianHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    i {
      // display: none;
      transition: $base-transition;
      font-size: 1.25rem;
    }
  }
}

.redirect {
  margin-top: 1rem;
  padding: 1rem;
  border: 2px solid #dfe6f4;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--employee {
    margin-bottom: 1rem;
  }

  p {
    margin: 0 0 1rem 0;
    color: #6F7F9F;
    font-size: .85rem;
  }

  &__url {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      margin-right: .5rem;
      color: #6F7F9F;
    }

    input {
      border: 1px solid #dfe6f4;
      padding: .5rem;
      border-radius: 3px;
      margin-right: .5rem;
    }
  }
  &__actions {
    button {
      height: 33px;
      margin-right: .5rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  p.redirect__copy {
    margin: 1rem 0 0;
    color: red;
  }
}

.stripe__accountErrors {
  margin-bottom: 2rem;
  padding: 1rem;
  border: 2px solid #DFE6F4;
  border-radius: 5px;

  h3 {
    margin-top: 0;
  }

  button {
    width: 100%;
  }
}

.files {
  &__uploaderArea {
    button {
      width: 100%;
      // margin-bottom: 2rem;
    }
    &__uploader {
      button {
        margin-bottom: 0;
      }
    }
  }
}

.textareaRow {
  &__label {
    margin-bottom: .5rem;
  }
  &__content {
    &__input {
      margin-bottom: .5rem;
      textarea {
        width: 100%;
        padding: .5rem;
        border: 1px solid $lightGrey;
        &:focus {
          border-color: $grey;
        }
      }
    }
    &__actions {
      button {
        margin-right: .5rem;
        width: 120px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &__view {
    @include flex;
  }
}

.financialAdjustments {
  padding: 0 2rem;
  @include bp(Mama) {
    padding: 0;
  }
  &__row {
    margin-bottom: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    input[type="text"], input[type="number"], textarea {
      padding: 4px 5px;
      max-width: 120px;
      width: 100%;
      border: 1px solid #DFE6F4;
      border-radius: 5px;
      font-size: 1rem;
      outline: none;
      -webkit-appearance:none;
      @include bp(Mama) {
        font-size: .875rem;
      }

      &:focus {
        border-color: #24C0AC;
      }
    }

    .debitAdjustment {
      display: inline-block;
      margin-left: .5rem;
      font-size: .75rem;
      color: $danger;

      &__info {
        border: 0;
        padding: 0;
        background: transparent;
        color: $grey;
        margin: 0 0 0 5px;
        &:hover {
          color: $navy;
        }
      }
    }

    input[type="text"] {
      max-width: 150px;
    }

    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      // span.MuiSlider-root {
      //   width: calc(100% - 350px);
      // }

      button {
        margin-left: 1rem;
        height: 26px;

        &.butt--cancel {
          margin-left: .25rem;
        }
      }
    }

    &--totalDue {
      margin-top: .25rem;
      padding: .75rem 0 .25rem;
      border-top: 1px solid #DFE6F4
    }

    &--agentFees {
      // div {
      //   width: calc(100% - 250px);
      // }
      .buttonBlock {
        margin-right: .5rem;
        border: 2px solid $navy;
        border-radius: 4px;
        overflow: hidden;
        button {
          border: 0;
          border-right: 2px solid $navy;
          margin: 0;
          padding: 0 4px;
          height: 20px;
          background: #FFF;
          cursor: pointer;
          font-size: .75rem;
          font-weight: 700;
          color: $navy;
          &:hover {
            background: $navy;
            color: #FFF;
          }
          &:last-child {
            border: 0;
          }
        }
      }
      input[type="text"] {
        max-width: 80px;
      }
    }

    &--creditCardProcessing {
      align-items: center;
      .bypassSwitch {
        label {
          font-size: .75rem;
          text-transform: uppercase;
          margin-right: .25rem;
        }
        div.switch {
          margin-right: .25rem;
        }
      }
    }

    &--charges {
      &.financialAdjustments__row--open {
        display: block;
      }
      div.charges__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      div.input__inner {
        margin-top: .5rem;
        width: 100%;
        textarea {
          min-height: 100px;
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }

  &__note {
    padding: 1rem;
    background: #dde2ff;

    p {
      margin-top: 0;
    }

    textarea {
      padding: 10px;
      width: 100%;
      border: 1px solid #DFE6F4;
      border-radius: 5px;
      font-size: 1rem;
      outline: none;
      min-height: 100px;
      -webkit-appearance:none;
      @include bp(Mama) {
        font-size: .875rem;
      }
    }
  }

  &--accepted, &--accepted_pending_finances, &--finances_secured, &--bail_posted {
    .financialAdjustments__row {
      div {
        padding: .2rem 0;
      }
      button {
        display: none
      }
    }
  }
}

.layout--agentCounties {
  .register__form {
    position: relative;
    padding-bottom: 20px;
    z-index: 10;
  }
}

.countyAdd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  &__dropdown {
    width: calc(100% - 120px);
  }

  button {
    width: 100px;
  }

}

.countiesList {
  &__county {
    position: relative;
    z-index: 1;
    margin: 0 0 1rem;
    padding: 10px 135px 10px 15px;
    border: 2px solid #dfe6f4;
    background: #FFF;
    border-radius: 5px;
    // max-width: 600px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin: 0 0 .25rem;
    }
    p {
      margin: 0;
    }

    button.countiesList__county__button {
      position: absolute;
      top: 0; right: 0;
      width: 60px; height: 100%;
      border: 0;
      border-left: 2px solid #dfe6f4;
      background: #FFF;
      color: #dfe6f4;
      cursor: pointer;

      &:hover {
        color: $navy;
      }

      i {
        font-size: 1.5rem;
      }

      &--settings {
        right: 60px;
      }
    }
  }
}

.locationSettings {
  header {
    margin-bottom: 2rem;
    h3 {
      margin: .5rem 0 .25rem;
      padding: 0 37px;
    }
    p {
      margin: 0;
      padding: 0 37px;
    }
  }
}

.tabs_sections__section.uploads {
  .uploadedFiles__file {
    position: relative;
    z-index: 1;
    margin: 0 auto 30px;
    padding: 12px 15px 10px 15px;
    border: 2px solid #dfe6f4;
    background: #FFF;
    border-radius: 5px;
    max-width: 600px;

    &__checkbox {
      width: 60px;
    }

    &__left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: calc(100% - 190px);
    }

    &__right {
      width: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      .switch {
        margin-bottom: .5rem;
      }

      & > span {
        font-size: .7rem;
      }
    }

    &__meta {
      p {
        display: none;
        margin: 0;
        font-size: .75rem;
      }
    }

    &--active {
      .uploadedFiles__file__meta {
        p {
          display: block;
        }
      }
    }
  }
}

.checklist {
  &__row {
    margin-bottom: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #dfe6f4;
    padding: 1rem;

    h3, p {
      margin: 0;
    }

    p:not(.big) {
      padding: 0;
      margin-top: 5px;
      font-size: .7rem;
    }

    &--finances {
      position: relative;
    }

    .tags--bypassTag {
      display: none;
      position: absolute;
      top: 50%; right: 70px;
      transform: translateY(-50%);
      margin: 0; padding: 0;
      @include bp(Mama) {
        display: block;
      }

      .tag {
        padding-right: 5px;
        cursor: default;
        &:hover {
          background: $blue;
        }
        &:after {
          display: none;
        }
      }
    }

    &--bypass {
      margin-bottom: 0;
    }

    &--shrunk {
      border-top: 0;
      margin: 0 .75rem .5rem;
      background: #EFEFEF;
    }

    &__content {
      width: calc(100% - 140px);
    }
      
    &__actions {
      display: flex;
      justify-content: flex-end;
      width: 130px;
      button {
        margin-right: .5rem;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__2up {
      @include flex;
      & > div.checklist__row {
        width: 49.5%;
        flex-direction: column;
        & > div {
          width: 100%;
          &:first {
            
          }
          button {
            margin-top: .5rem;
            width: 100%;
          }
        }
      }
    }
  }
}

.inviteForm, .editForm {
  margin-top: 1.5rem;
  max-width: 100%;

  .register__form__block {
    padding: 0;
  }
  .register__form__actionsBar__actions {
    padding: 0;
  }
}

.caseTabs__section--notes .timeline {
  margin-top: 0;
}

.timeline {
  position: relative;
  margin-top: 3rem;
  padding: 1rem;

  &:after {
    content: '';
    position: absolute;
    top: 80px;
    left: 48px;
    z-index: 0;
    background-color: #ebeef0;
    width: 3px;
    height: calc(100% - 120px);
  }

  h3 {
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 1.5rem;
    padding-bottom: .5rem;
    border-bottom: 2px solid #DFE6F4;
  }

  &__avatar {
    position: absolute;
    top: 0;
    left: 10px;
    width: 48px;
    height: 48px;
    background: $navy;
    border-radius: 50%;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    strong {
      color: #FFF;
    }
  }

  &__checkbox {
    margin-bottom: .5rem;

    .cb {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &__text {
        margin-right: .5rem;
      }
    }
  }

  header {
    position: relative;
    padding-left: 70px;

    p {
      text-align: right;
      font-size: .85rem;
    }

    form {
      @include flex;
      position: relative;
      align-items: center;
      gap: 10px;
      border-radius: 4px;

      button {
        margin-top: 5px;
      }

      input[type="text"] {
        margin-top: 5px;
        flex-grow: 1;
        padding: .75rem;
        border-radius: 4px;
        border: 1px solid #DFE6F4;
        width: 100%;
        border: 0;
        outline: none;
        background: #FFF;

        @include bp(Kid) {
          width: calc(100% - 110px);
        }    
      }

      input[type="submit"] {
        position: absolute;
        top: 55px;
        right: 6px;
        // width: 60px;

        @include bp(Kid) {
          top: 50%;
          transform: translateY(-50%);
        }    

      }
    }
  }

  &__event {
    position: relative;
    padding: 1rem 0 1rem 4rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;

    @include bp(Kid) {
      flex-direction: row;
      align-items: center;
    }

    &--message {
      font-size: 0.75rem; 

      @include bp(Toddler) {
        font-size: 1rem; 
      }  

      @include bp(Kid) {
        width: calc(100% - 85px);
      }  
    }

    &--time {
      font-size: 0.65rem;
      width: 70px;

      @include bp(Toddler) {
        font-size: .75rem;
      }    

      @include bp(Kid) {
        text-align: right;
      }    
    }

    &--date {
      text-transform: uppercase;
      font-size: .85rem;
      font-weight: 600;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 21px;
      z-index: 1;
      transform: translateY(-50%);
      width: 19px;
      height: 19px;
      border: 3px solid #ebeef0;
      background-color: #c3cfd8;
      border-radius: 50%;
    }

    &--dateRow {
      padding-top: 3rem;

      &::before {
        display: none;
      }
    }

    &--note,
    &--customer_note,
    &--adjustment,
    &--messageEvent {
      padding: 0 0 1rem;

      // margin-bottom: 1rem;
      .timeline__event__inner {
        position: relative;
        z-index: 1;
        width: 100%;
        padding: 1rem 1rem 1rem 5rem;
        background-color: #fff;
        border-radius: 3px;
        -webkit-box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
        box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);

        &>div {
          display: flex;
          flex-direction: column-reverse;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          margin-bottom: .5rem;
          font-size: 0.75rem;

          @include bp(Toddler) {
            font-size: 1rem;
          }    

          @include bp(Kid) {
            flex-direction: row;
            align-items: center;
          }    
        }
      }

      .timeline__event--message {
        margin-right: 1rem;
      }

      .timeline__event--data {
        margin-bottom: 0;
      }

      .timeline__event--noteRemove {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 3;
        font-size: 1.25rem;
        background: transparent;
        border: 0;
        color: #DFE6F4;
        cursor: pointer;
        outline: none;

        &:hover {
          color: $navy;
        }
      }

      &::before {
        content: attr(data-initials);
        background-color: $navy;
        top: 1rem;
        transform: none;
        left: 11px;
        z-index: 2;
        width: 48px;
        height: 48px;
        border: 0;
        color: #FFF;
        line-height: 48px;
        text-align: center;
        font-weight: 600;
      }
    }

    &--messageEvent {
      &::before {
        background-color: $teal;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

.displayApplicationButton {
  text-decoration: none;
  color: $teal;

  &:hover {
    color: $grey;
  }
}

.steps {
  margin-top: 2rem;
  .small {
    margin: 1.5rem 0 .75rem;
    color: $teal;
  }
  &__steps {
    position: relative;

    display: flex;
    justify-content: center;

    &__step {
      width: 50px; height: 50px;
      border-radius: 50%;
      border: 2px solid $grey;

      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: 1rem;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      span {
        color: $grey;
        font-size: 1.5rem;
      }
    }
  }

  // &.stepper--step1 {
  //   .stepper__steps {
  //     &__step {
  //       &:nth-child(1) { background: $teal; border-color: $teal; span { color: $white; } }
  //     }
  //   }
  // }

  &.steps--step2 {
    .steps__steps {
      &__step {
        &:nth-child(1) { background: $teal; border-color: $teal; span { color: $white; } }
      }
    }
  }

  &.steps--step3 {
    .steps__steps {
      &__step {
        &:nth-child(1) { background: $grey; span { color: $white; } }
        &:nth-child(2) { background: $teal; border-color: $teal; span { color: $white; } }
      }
    }
  }

  &.steps--step4 {
    .steps__steps {
      &__step {
        &:nth-child(1) { background: $grey; span { color: $white; } }
        &:nth-child(2) { background: $grey; span { color: $white; } }
        &:nth-child(3) { background: $teal; border-color: $teal; span { color: $white; } }
      }
    }
  }

  &.steps--step5 {
    .steps__steps {
      &__step {
        &:nth-child(1) { background: $grey; span { color: $white; } }
        &:nth-child(2) { background: $grey; span { color: $white; } }
        &:nth-child(3) { background: $grey; span { color: $white; } }
        &:nth-child(4) { background: $teal; border-color: $teal; span { color: $white; } }
      }
    }
  }
}

.pricingSetup {
  padding: 0 2rem;
  @include bp(Mama) {
    padding: 0;
  }
  h2 {
    margin: 0 0 .5rem;
    font-size: 2rem;
    font-weight: 300;
    text-align: center;
    color: $navy;
  }

  &__container {
    margin-top: 2rem;
    border: 2px solid $lightGrey;
    border-radius: 4px;
    color: $grey;
    header {
      padding: 2rem 0;
      text-align: center;
      border-bottom: 2px solid $lightGrey;
      p {
        margin: 0;
        border: 0;
        text-align: center;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0.75px;
        color: $teal;
      }
    }
    section {
      padding: 2rem 4rem;
      & > p {
        font-size: 1.2rem;
        line-height: 1.8rem;
        text-align: center;
        margin: 0 0 1rem;
      }
      ul {
        margin: 2rem 0 0;
        padding: 0;
        list-style: none;

        li {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: .75rem;
          span {
            margin-right: 1rem;
            display: inline-block;
            width: 24px; height: 24px;
            background: $teal;

            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            i {
              font-size: .85rem;
              color: #FFF;
            }
          }
        }
      }
    }
    footer {
      border-top: 2px solid $lightGrey;
      button {
        display: block;
        width: 100%;
        padding: 2rem 0;
        background: #EFEFEF;
        border: 0;
        text-align: center;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0.75px;
        color: $navy;
        outline: none;
        cursor: pointer;

        &:hover {
          background: #DFDFDF;
        }
      }
    }
  }

  .allFeatures {
    width: 100%;
    padding: 2rem 0;
    border: 0;
    background: transparent;
    font-size: .85rem;
    text-align: center;
    color: $lightGrey;
    cursor: pointer;

    &:hover {
      color: $grey;
    }
  }

  &__apr {
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    strong {
      font-size: 4rem;
      font-weight: 300;
    }
    span {
      &::before {
        content: '+';
        color: #c9d1e0;
        padding: 0 1rem;
        font-size: 1.25rem;
      }
    }
    p {
      margin: 1rem 0 0;
      text-align: center;
    }
  }

  &__points {
    display: flex;
    justify-content: center;
  }

  .additionalFeatures {
    padding-top: 1rem;
    &__container {
      display: flex;
      justify-content: center;
    }

    ul {
      margin: 0;
      padding: 0 0 1rem;
      list-style: none;

      li {
        position: relative;
        font-size: 1.25rem;
        margin-bottom: .5rem;
        padding-left: 24px;
        color: $grey;

        &::before {
          content: "\f00c";
          position: absolute;
          top: 5px; left: 0;
          font-family: "Font Awesome 5 Pro";
          color: $teal;
          font-size: 1rem;
        }
      }
    }

    p.small {
      font-weight: 300;
    }
  }
}

.merchantApplication {
  p.uploadInstructions {
    margin: 0;
    text-align: center;
  }
  &__points {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin-bottom: .5rem;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
  &__files {
    margin-bottom: 1rem;
    &__container {
      border: 1px solid $lightGrey;
    }
    &__file {
      padding: 10px;
      border-bottom: 1px solid $lightGrey;
  
      &:last-child {
        border-bottom: 0;
      }
  
      a {
        color: $teal;
      }
    }
  }
  .butt {
    margin-top: 2rem;
    width: 100%;
  }
}

.quickAdd {

  .formBlock--bookingNumber {
    position: relative;
    button {
      position: absolute;
      top: 25px; right: 4px;
      height: 30px;
    }
  }

  &__countyForm {
    position: relative;
    display: block;
    margin: .5rem 0 0;
    label {
      display: block;
      font-size: .85rem;
      color: #A2AFCD;
    } 
    input[type="submit"] {
      position: absolute;
      bottom: 4px; right: 4px;
      height: 30px;
      border-radius: 3px;
    }
  }

  &__locations {
    // display: flex;
    // justify-content: center;
    // align-items: center;

    padding: .5rem;
    
    background: #FAFAFA;
    border-bottom: 1px solid $lightGrey;

    & > label {
      display: block;
      margin-bottom: .25rem;
      font-size: .85rem;
      color: #6F7F9F;
    }

    button, span {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
    button {
      height: 26px;
    }

    p {
      margin: 0;

      button {
        margin-left: .5rem;
      }
    }
  }

  &__arrestee {
    padding-top: 1rem;
    border-bottom: 1px solid $lightGrey;

    height: calc(100vh - 160px);
    overflow-y: scroll;

    .register__form__block {
      padding: 0 1rem;

      .form-row.split-row {
        flex-direction: column;

        & > div {
          width: 100%;
        }
      }
    }
  }

  &__user {
    border-bottom: 1px solid $lightGrey;

    .register__form__block {
      padding: 0 1rem;

      .form-row.split-row {
        flex-direction: column;

        & > div {
          width: 100%;
        }
      }
    }

    .register__form__actionsBar__actions {
      padding: 0 0 .5rem;
    }
  }

  &__notFound {
    border-bottom: 1px solid $lightGrey;

    .register__form__block {
      padding: 0 1rem;
    }
  }

  .layout.register__form {
    padding-bottom: 1rem;
  }

  .register__form__actionsBar__actions--small {
    padding: 0 0 .5rem
  }

  .countyJailLink {
    color: $teal;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.bondsman_updates {
  &__item {
    padding: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;

    background: #C9E2F2;

    p {
      margin: 0;
      padding: 0 0 0 .5rem;
      width: calc(100% - 90px);
      font-size: .85rem;
    }
    button {
      width: 90px;
      height: 30px;
    }
  }
}

.dashboard__activity {
  margin-top: 2rem;
  .noActivity {
    padding: 2rem 0;
    text-align: center;
    color: #A2AFCD;
  }
  &__item {
    position: relative;
    z-index: 1;
    margin: 0 auto 15px;
    padding: 15px;
    border: 2px solid #dfe6f4;
    background: #FFF;
    border-radius: 5px;
    max-width: 600px;

    footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      button {
        height: 27px;
        margin-right: .5rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &--unread {
      border-color: $blue;

      button {
        background: $blue;
        border-color: $blue;

        &:hover {
          background: $white;
          color:tvars $blue;
        }
      }

      .tag {
        border-color: $blue;
        color: $blue;
      }
    }
  }

  &__content {
    margin-bottom: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    h5 {
      margin: 0;
      text-align: right;
      width: 100px;
    }
    & > div {
      width: calc(100% - 100px);
      p {
        margin: 0;
        font-size: .875rem;
        color: #6F7F9F;
      }
    }
  }

  &__header {
    p {
      margin: 0 0 .7rem;
      padding-bottom: .2rem;
      border-bottom: 2px solid #A2AFCD;
    }
  }
}

.dashboard--searching {
  .tabs__tabs button {
    &.active {
      border-color: #A2AFCD;
      color: #A2AFCD;
    }
    span {
      display: none;
    }
  }
  .tabs_sections > h3 {
    padding-bottom: 4px;
    border-bottom: 2px solid $navy;
  }
  .dashboard__customers {
    padding-top: 0;
  }
}

.manualUpload_form {
  margin-bottom: 6rem;
  text-align: center;

  & > p {
    text-align: center;
  }

  & > button {
    margin: 0 auto;
    width: 100%;
    max-width: 350px;
  }
}

.ruleViolations {
  margin-bottom: 1rem;
  &__rule {
    margin-bottom: .5rem;
    &:last-child {
      margin-bottom: 0;
    }
    p {
      margin: 0;
      color: $danger;
      text-align: center;
    }
  }
}

.questionnaireContainer {
}

body.layout--questionnaire {
  #root, main, .layout--questionnaireView {
    height: 100%;
  }
  #root main {
    padding-bottom: 0;
  }
  .layout--questionnaireView {
    height: 100%;
    @include unicorn;
    align-items: flex-start;
    margin-top: 20px;
    @include bp(Mama) {
      max-width: 732px;
      margin: 70px auto;
    }
  } 
  .questionnaireArea {
    margin: 0 auto;
    padding: 2rem 0 8rem;
    max-width: 500px;
  }
  button.developerBypass {
    position: absolute;
    top: 6.5rem;
    right: 1rem;
    padding: 0 1rem;
  }
}

.questionnaire {

  &__info {
    margin: 0;
    font-size: .825rem;
  }

  &__question {
    position: relative;
    margin: 0 auto;
    padding: 0 1.5rem;
    width: 100%;
    // max-width: 632px;
    @include bp(Mama) {
      padding: 0;
    }

    .calloutActions {
      @include unicorn;
      gap: .5rem;
      button {
        padding: 0 1rem;
        cursor: pointer;
      }
    }

    &__loading {
      text-align: center;
      font-size: 1.25rem;
      color: $teal;
      border: 2px solid $lightGrey;
      padding: .5rem 0;
      border-radius: 5px;
    }

    &__error {
      margin: .5rem 0 0;
      color: $danger;
      font-size: .85rem;
      font-weight: 700;
    }

    &:last-child {
      // border-bottom: 1px solid #DFE6F4;
    }

    input[type="text"], textarea {
      font-family: 'Rubik', -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      padding: 10px;
      width: 100%;
      border: 1px solid #DFE6F4;
      border-radius: 5px;
      font-size: 1rem;
      outline: none;
      -webkit-appearance:none;
      @include bp(Mama) {
        font-size: .875rem;
      }

      &:focus {
        border-color: #24C0AC;
      }
    }

    form {
      display: block;
      padding: 0 0 50px;
    }

    header {
      margin-bottom: 2rem;

      h6 {
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0 0 1rem;
        color: $grey;
      }

      p {
        margin: 0 0 .25rem;
        text-align: left;
      }
    }

    &__input {
      // padding-bottom: 50px;
      h5 {
        margin: 0 0 .5rem;
        font-size: 1.25rem;
        font-weight: 300;
      }
      
      &--address {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include bp(Mama) {
          flex-direction: row;
          flex-wrap: wrap;
        }

        input[type="text"] {
          margin-bottom: .5rem;
          @include bp(Mama) {
            margin-bottom: 0;
          }
          // &:last-child {
          //   margin-bottom: 0;
          // }
          &:first-child {
            @include bp(Mama) {
              width: 78%;
              // margin-bottom: .5rem;
            }
          }
          // &:nth-child(2) {
          //   @include bp(Mama) {
          //     width: 49%;
          //   }
          // }
          &:last-child {
            @include bp(Mama) {
              width: 20%;
            }
          }
        }
      }
      
      &--placesAddress {
        & > div {
          width: 100%;
        }
      }
      
      &--date {
        display: flex;
        flex: 1;
        flex-shrink: 0;
        justify-content: space-between;
        flex-direction: column;
        @include bp(Mama) {
          flex-direction: row;
        }

        & > div {
          margin-bottom: .5rem;
          @include bp(Mama) {
            margin-bottom: 0;
          }
          @include bp(Mama) {
            margin-bottom: 0;
          }
          &:nth-child(1) { 
            @include bp(Mama) {
              width: 45%;
            }
          }
          &:nth-child(2) { 
            @include bp(Mama) {
              width: 20%;
            }
          }
          &:nth-child(3) { 
            @include bp(Mama) {
              width: 25%;
            }
          }
        }
      }
      
      &--fullname {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include bp(Mama) {
          flex-direction: row;
        }

        input[type="text"] {
          width: 100%;
          margin-bottom: .5rem;
          @include bp(Mama) {
            width: 32%;
            margin-bottom: 0;
          }
        }
      }

      &--name {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include bp(Mama) {
          flex-direction: row;
        }

        input[type="text"] {
          &:first-child {
            width: 49%;
          }
          &:last-child {
            width: 49%;
          }
        }
      }
      
      &--location {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 10px;
        @include bp(Mama) {
          flex-direction: column;
        }
        input[type="text"] {
          margin-bottom: 1rem;
          @include bp(Mama) {
            margin-bottom: 0;
            // width: calc(100% - 216px);
          }
        }
        #state {
          width: 100%;
        }
        & > div {
          margin-bottom: 1rem;
          width: 100%;
          @include bp(Mama) {
            margin-bottom: 0;
            width: 200px;
          }
        }

        .foreignToggle {
          width: 100%;
        }
      }

      &--lengthOfTime {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include bp(Mama) {
          flex-direction: row;
        }
        & > div {
          margin-bottom: 1rem;
          width: 100%;
          @include bp(Mama) {
            margin-bottom: 0;
            width: 49%;
          }
        }
      }

      &--radio {
        .questionnaire__question__input__secondary {
          margin-top: 1rem;
          label {
            display: block;
            font-size: .85rem;
            margin-bottom: .25rem;
          }
        }
      }
    }

    &__actions {
      position: absolute;
      bottom: 0; right: 1.5rem;
      z-index: 1;
      display: flex;
      justify-content: flex-end;
      height: 36px;
      @include bp(Mama) {
        right: 0;
      }
      button {
        padding: 0 1rem;
        margin-right: .5rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .helpText {
      margin: .25rem 0 0;
      font-size: .85rem;
      color: #6F7F9F;
    }

    &__na {
      position: absolute;
      bottom: 0; left: 0;
      width: 97%;
      display: flex;
      justify-content: space-between;
      padding: 0 6.125rem 0 1.5rem;
      @include bp(Mama) {
        left: 0;
        padding: 0 4.5rem 0 0;
      }

      button {
        padding: 0 1rem;
      }
    }

    &--notApplicable {
      .questionnaire__question__input {
        opacity: .3;
      }
    }
  }
}

// .finalAnswers {
//   padding: 2rem 0 8rem;
//   &__answer {
//     margin-bottom: 1rem;
//   }
// }

.files__questionairArea {
  &__questions {
    &__container {
      margin: 1.5rem 0 0;
      padding: 0;
      list-style: none;
    }
    &__question {
      position: relative;
      padding: .35rem .25rem .35rem 1.5rem;
      width: 100%;
      border: 1px solid #A2AFCD;
      border-radius: 4px;
      margin-bottom: .5rem;

      button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: transparent;
        border: 0;
        cursor: pointer;
        outline: none;

        &.move {
          z-index: 10;
          left: 0;
          color: #A2AFCD;
          &:hover {
            color: $navy;
          }
        }

        &.delete {
          z-index: 10;
          right: 0;
          color: #A2AFCD;
          &:hover {
            color: $navy;
          }
        }
      }
      p {
        margin: 0;
        font-size: .85rem;
      }
    }
  }
  &__noResults {
    padding: 2rem 0;
    color: #A2AFCD;
    text-align: center;
  }
  &__formActions {
    display: flex;
    justify-content: space-between;
    & > button {
      width: 49%;
    }
  }
  &__display {

    &__row {
      &--title {
        
      }
      
      &--text {
        margin-bottom: 1rem;
        label {
          position: relative;
          display: block;
          font-size: 1rem;
          padding-left: 1.6rem;
          margin-bottom: .25rem;
          span {
            position: absolute;
            top: 0; left: 0;
            color: $teal;
            font-weight: 700;
          }
        }
        & > div {
          padding-left: 1.5rem;
        }
      }

      &--spacer {
        padding: 2rem 0;
      }
    }

    textarea {
      font-family: 'Rubik', -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      padding: 10px;
      width: 100%;
      border: 1px solid #DFE6F4;
      border-radius: 5px;
      font-size: 1rem;
      outline: none;
      -webkit-appearance:none;
      @include bp(Mama) {
        font-size: .875rem;
      }

      &:focus {
        border-color: #24C0AC;
      }
    }

    input[type="text"] {
      font-family: 'Rubik', -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      padding: 10px;
      width: 100%;
      border: 1px solid #DFE6F4;
      border-radius: 5px;
      font-size: 1rem;
      outline: none;
      -webkit-appearance:none;
      @include bp(Mama) {
        font-size: .875rem;
      }

      &:focus {
        border-color: #24C0AC;
      }
    }
  }
}

.questionnaireArea {
  &__question {
    position: relative;
    margin-bottom: 1rem;
    padding-left: 1.7rem;

    &__q {
      font-weight: 700;
      span {
        position: absolute;
        top: 0; left: 0;
        color: $teal;
      }
    }
    &__a {
    }
  }
}

body.layout--documents {
  height: 100%;
  #root, main, .layout--userDocuments, .layout--sign {
    height: 100%;
  }

  #root main {
    padding-bottom: 0;
  }

  .layout--userDocuments {
    height: 100%;
    @include unicorn;
    align-items: flex-start;
  } 

  .userDocuments {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;

    width: 100%; min-height: 100%;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;

    .paragraphCluster {
      margin-bottom: 2rem;
      p {
        margin: 0 0 .5rem;
        text-align: center;
      }
    }

    .filesArea, .signing__form {
      margin: 0 auto;
      height: 100%;
      max-width: 600px;
    }
  
    &__sidebar {
      position: relative;
      width: 100%; height: 70px;

      nav {
        display: none;
      }

      &__container {
        position: absolute;
        left: 0;
        width: 100%;
        width: 10000px;
        transition: $base-transition;
        background: $grey;

        & > div.section__tab {
          position: relative;
          display: inline-block;
          border-right: 1px solid #FFF;
          color: #FFF;
          min-width: 150px;
          padding: 0 1rem;
          height: 70px;
          // cursor: pointer;

          &__container {
            height: 100%;
          }

          p {
            margin: 0;
            font-weight: 700;
            text-align: center;
            // line-height: 70px;
            &.section__tab__area {
              margin: .8rem 0 .2rem;
              font-size: .75rem;
              text-transform: uppercase;
            }
          }
          
          &:last-child {
            border-bottom: 0;
          }

          &.active {
            position: relative;
            background: $navy;
            cursor: pointer;
    
            &:after {
              right: 50%;
              bottom: 0;
              border: solid transparent;
              content: "";
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-color: rgba(136, 183, 213, 0);
              border-bottom-color: #FFF;
              border-width: 16px;
              margin-right: -16px;
            }
          }
        }
      }
    }

    &__main {
      padding-top: 2rem;
      padding-bottom: 12rem;
      @include bp(Mama) {
        padding-bottom: 0;
      }
      // height: 100%;
      // width: calc(100% - 250px);
      // padding: 1.3rem 1rem 3rem;
    }
  
    .developerBypass {
      // display: none;
      position: absolute;
      top: 11rem; right: 1rem;
    }

    .questionnaireArea {
      max-width: 400px;
      margin: 2rem auto;
    }
  }

}

.filesArea__areas {
  &__area {
    &--on {
      padding-bottom: 1rem;
    }
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0;
    }
    border-bottom: 1px solid #A2AFCD;
  
    &:last-child {
      border-bottom: 0;
    }
  }
  &__file {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .3rem 0;
    
    a {
      text-decoration: none;
    }
  }
}

.transactions {
  // border-top: 2px solid #A2AFCD;
  padding-top: 1.5rem;
  .instructions a {
    color: $teal;
  }
  &__transaction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .25rem 0;
    & > div:first-child {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      span {
        margin-right: .5rem;
      }
    }
    & > div:last-child {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span {
        margin-left: .5rem;
      }
    }

    &__data {
      font-size: .85rem;
      &--date {
        font-size: .65rem;
      }
    }

    &--success {
      i.fas {
        color: $completed;
      }
    }

    &--pending {
      i.fas {
        color: $completed;
      }
    }

    &--failed, &--canceled {
      i.fas {
        color: $danger;
      }
    }
  }
}

.defendantActions {
  margin-top: 1rem;
  padding: 1rem;
  border: 2px solid #6F7F9F;
  background: #DFE6F4;

  display: flex;
  justify-content: space-between;

  & > p {
    margin: 0;
  }
  & > div {
    display: flex;
  }

  button {
    margin-left: .5rem;
    width: 150px;
    cursor: pointer;
    // &:first-child {
    //   margin: 0;
    // }
  }
}

.mask--filterModal {
  .mask__container {
    max-width: 450px;
    width: 100%;
  }
}

.filterModal {
  max-width: 450px;
  width: 100%;
  .formBlock {
    text-align: left;
  }
}

.dashboard_quickAdd {
  border: 2px solid $lightGrey;
  background: #FAFAFA;
  padding: 1rem;
  border-radius: 10px;
  @include bp(Mama) {
    padding: 1.5rem;
  }

  header {
    @include flex;
    flex-direction: column;
    @include bp(Mama) {
      flex-direction: row;
    }

    & > div {
      margin-bottom: 1rem;
      @include bp(Mama) {
        margin-bottom: 0;
      }
      h3 {
        margin: 0 0 5px;
        padding: 0;
        font-size: 1.5rem;
        color: $navy;
      }
    
      p {
        margin: 0;
        font-size: 1.125rem;
        color: $grey;
      }
    }

    & > a {
      width: 100%;
      @include bp(Mama) {
        width: auto;
      }
    }
  }
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}