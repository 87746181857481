@import "styles/basics/mixins";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}

.leftLogo {
  h1 {
    margin: 0;
    line-height: 0;
    a {
      display: inline-block;
      width: 50px;
      height: 24px;
      background-image: url(/assets/images/logo_key_blue.svg);
      background-repeat: no-repeat;
      background-size: 50px, 24px;
      text-indent: -9000px;
      line-height: 1rem;
      @include bp(Kid) {
        width: 223.38px;
        height: 22px;
        background-size: 223.38px, 22px;
        background-image: url(/assets/images/SimplyBail_logo_long.png);
      }
      img {
        display: none;
      }
    }
  }
}
