.header__error {
  position: fixed;
  top: 57px; left: 0;
  z-index: 1;
  padding: 0 10px;
  width: 100%; height: 30px;
  background: $danger;
  font-size: .825rem;
  font-weight: 600;
  color: #FFF;
  line-height: 29px;
  border-bottom: 1px solid #DFE6F4;
}
.header__container {
  position: fixed;
  top: 0; left: 0;
  z-index: 1000;
  width: 100%; height: 57px;
  background: #FFF;
  border-bottom: 1px solid #DFE6F4;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
  }

  .butt--help {
    margin-left: 0;
    // margin-right: 1rem;
    padding: 4px 8px 0;
    font-size: 1.5rem;
  }

  .button--home {
    display: none;
    @include bp(Kid) {
      display: inline;
    }
  }

  &__left {
    h1 {
      margin: 0;
      line-height: 0;
      a {
        display: inline-block;
        width: 50px; height: 24px;
        background-image: url(/assets/images/logo_key_blue.svg);
        background-repeat: no-repeat;
        background-size: 50px, 24px;
        text-indent: -9000px;
        line-height: 1rem;
        @include bp(Kid) {
          width: 223.38px; height: 22px;
          background-size: 223.38px, 22px;
          background-image: url(/assets/images/SimplyBail_logo_long.png);
        }
        img {
          display: none;
        }
      }
    }
    .logo {
      width: auto;
      height: 22px;
    }
  }
  
  &__right {
    & > button:not(.butt--navy), & > a {
      margin-left: .75rem;
      font-size: 1.25rem;
      color: #6F7F9F;
      background: transparent;
      border: 0;
      text-decoration: none;
      outline: none;
      cursor: pointer;
      @include bp(Mama) {
        margin-left: 1.5rem;
      }

      &:hover {
        color: #4259ED;
      }
    }
  }

  &__user {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    border-bottom: 1px solid #DFE6F4;
    background: #FFF;
    margin-top: 1px;
    @include bp(Mama) {
      justify-content: space-between;;
    }

    &--bondsman {
      display: none;
      @include bp(Mama) {
        display: block;
      }
    }

    p {
      margin: 0;
      font-size: .85rem;

      span {
        display: none;
        @include bp(Toddler) {
          display: inline;
        }
      }
    }

    a {
      color: $teal;
    }
  }
}

.header__notifications {
  @include unicorn;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 0.5rem;
  border-left: 1px solid $lightGrey;
  border-right: 1px solid $lightGrey;
  height: 57px;
  cursor: pointer;
  display: none;
  @include bp(Mama) {
    display: flex;
  }
  &:hover {
    background: #fafafa;
  }
  h5 {
    margin: 0 0 0.25rem;
    font-size: 0.75rem;
    color: $grey;
  }
  &__btm {
    @include flex;
    justify-content: flex-start;
  }
  &__type {
    margin-right: 0.5rem;
    width: 70px;
    &:last-child {
      margin-right: 0;
    }
    & > div {
      margin: 0;
      font-size: 0.825rem;
      @include flex;
      justify-content: flex-start;
    }
    strong {
      margin-left: 4px;
      font-weight: 700;
      &.on {
        color: $completed;
      }
      &.off {
        color: $danger;
      }
    }
  }
  &__actions {
    button {
      margin: 0 0.25rem 0 0;
      padding: 0;
      border: 0;
      background: transparent;
      color: $grey;
      font-size: 1rem;
      &:hover {
        color: $navy;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.header__drawer {
  position: fixed;
  top: 0;
  right: -300px;
  z-index: 999;
  background: #fff;
  max-width: 300px;
  width: 100%;
  height: 100vh;
  padding-top: 56px;
  border-left: 1px solid #dfe6f4;
  transition: $base-transition;

  .drawer__welcomeMessage {
    @include flex;
    justify-content: flex-start;
    font-size: .825rem;
    button {
      margin-right: .25rem;
      padding: 0;
      background: transparent;
      border: 0;
      color: $grey;
      cursor: pointer;
      &:hover {
        color: $navy;
      }
    }
  }

  &__actions {
    // @include flex;
    padding: 0.5rem;
    border-top: 1px solid #dfe6f4;
    & > a {
      display: block;
      width: 100%;
      text-align: center;
      text-decoration: none;
      font-size: 0.825rem;
      line-height: 32px;
      margin-bottom: 0.25rem;
    }
  }

  &__terms {
    border-top: 1px solid #dfe6f4;
    padding: 0.5rem 1rem 0 1rem;
    text-align: right;

    a {
      font-size: 0.85rem;
      color: $grey;
      text-decoration: none;
      &:hover {
        color: $teal;
        text-decoration: underline;
      }
    }
  }

  & > header {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    align-items: center;
    border-bottom: 1px solid #dfe6f4;

    a {
      font-size: 0.85rem;
      color: #6f7f9f;
      background: transparent;
      border: 0;
      text-decoration: none;
    }
  }

  nav {
    padding: 1rem;

    a {
      display: block;
      margin-bottom: 1rem;

      font-size: 1.25rem;
      color: #6f7f9f;
      background: transparent;
      border: 0;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--open {
    right: 0;
    .drawerMask {
      display: block;
    }
  }

  &--quickAdd {
    max-width: 400px;
  }
}

.drawerMask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100vw;
  height: 100vh;
  transition: $base-transition;

  &--open {
    display: block;
    background: rgba(255, 255, 255, 0.8);
  }
}
