.users {

  padding: 0 1rem;

  header {
    @include flex;
  }

  section {
    margin-bottom: 2rem;

    &:last-child {
      margin: 0;
    }
  }

  .editForm {
    margin-top: 2rem;
    .split-row .formBlock:first-child {
      margin-left: 0;
    }
  }

  &__table {
    margin-top: 1rem;
    border: 2px solid #dfe6f4;
    border-bottom: 0;

    span {
      display: inline-block;
      font-size: .85rem;
      padding: 5px 5px;
    }

    &__header, &__row {
      border-bottom: 2px solid #dfe6f4;
      span {
        border-right: 2px solid #dfe6f4;
        &:last-child {
          border-right: 0;
        }
      }
      span:nth-child(1) { width: 170px; }
      span:nth-child(2) { width: calc(100% - 360px); }
      span:nth-child(3) { width: 80px; text-align: center; }
      span:nth-child(4) { width: 110px; text-align: center; }
    }

    &__header {
      span {
        font-size: .75rem;
        font-weight: 700;
      }
    }

    &__row {
      &--actions {
        padding: 0 !important;
        button {
          background: transparent;
          border: 0;
          color: #6F7F9F;
          cursor: pointer;

          &:hover {
            background: #EFEFEF;
            color: #000;
          }
        }
      }

      &:hover {
        background: #EFEFEF;
      }

      .users__table__row__cell--name {
        position: relative;
        padding-left: 22px;
        i {
          position: absolute;
          top: 50%; left: 5px;
          transform: translateY(-50%);
        }
      }
    }

    &--invites {
      .users__table__header, .users__table__row {
        span:nth-child(1) { width: 150px; }
        span:nth-child(2) { width: calc(100% - 200px); }
        span:nth-child(3) { width: 50px; text-align: center; }
      }
    }

    i.status {
      display: inline-block;
      margin-right: .25rem;
      width: 12px; height: 12px;
      background: $completed;
      border-radius: 50%;
      padding: 0;

      &--approved {
        background: $completed;
      }
      &--required {
        background: $warning;
      }
    }
    
  }

  &__notifications {
    margin-top: 2rem;
    h5 {
      margin-bottom: 1rem;
    }
  }
}