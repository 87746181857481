@import "styles/basics/mixins";
@import "styles/basics/vars";

@import "react-datepicker/dist/react-datepicker.css";

:root {
  /* Base color using HSL */
  --hue: 207;
  --saturation: 64%;
  --light: 44%;

  /* Base color in variable */
  --primary-color: hsl(var(--hue), var(--saturation), var(--light));
  /* Base color lighten using calc */
  --primary-color-lighten: hsl(
    var(--hue),
    var(--saturation),
    calc(var(--light) + 10%)
  );
  /* Base color darken using calc */
  --primary-color-darken: hsl(
    var(--hue),
    var(--saturation),
    calc(var(--light) - 10%)
  );
}

.inputDayJs {
  label {
    display: block;
    color: $grey;
    margin-bottom: .25rem;
    font-size: 0.875rem;
  }
}

.custom_fc_frame {
  display: none;
  right: 15px !important;
  bottom: 60px !important;
  @include bp(Mama) {
    display: block;
  }

  body.layout--documents &,
  body.layout--defendant & {
    right: 0 !important;
    bottom: 0 !important;
    @include bp(Mama) {
      right: 15px !important;
      bottom: 15px !important;
    }
  }
}

.ant-form-item {
  margin-bottom: 0;

  .ant-form-item-label {
    padding-bottom: 4px !important;

    label {
      display: block;
      font-size: 0.825rem;
      color: $grey;
    }
  }
}

.paymentPlanDatePicker {
  .react-datepicker-wrapper {
    // width: 38px;
    // height: 38px;

    .butt {
      width: 28px;
      height: 28px;
    }

    .react-datepicker__input-container {
      height: 100%;
      width: 100%;
    }
  }
}

.comingSoon {
  max-width: 400px;
  border: 2px solid $lightGrey;
  background: #FFF;
  padding: 2rem;
  text-align: center;
  border-radius: 10px;
  h3 {
    margin: 0 0 .5rem;
    font-size: 2rem;
    color: $navy;
  }
  p {
    margin: 0;
  }
  &__container {
    @include unicorn;
    align-items: flex-start;
    margin-top: 200px;
    width: 100%;
    height: calc(var(--vh, 100vh) - 57px - 52px);
  }
}

body.widget--on {
  .custom_fc_frame {
    display: block;
  }
}

::placeholder {
  color: #a2afcd;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

html,
body,
#root {
  // min-height: 100vh;
}

p.error {
  margin-bottom: 1rem;
  background: $danger;
  padding: 4px 10px;
  font-size: 0.75rem;
  font-weight: 700;
  color: #fff;
}

p.errorMessage {
  margin: .5rem 0 0;
  padding: .25rem .5rem;
  font-size: .7rem;
  background: $danger;
  color: #FFF;
  border-radius: 3px;
}

p.warningMessage {
  margin: .5rem 0 0;
  padding: .25rem .5rem;
  font-size: .7rem;
  background: $orange;
  color: #FFF;
  border-radius: 3px;
}

.noResults {
  padding: 2rem 0;
  color: #a2afcd;
  text-align: center;
}

h5.rad {
  margin: 0 0 0.5rem;
  font-size: 0.85rem;
  letter-spacing: 2px;
  color: $grey;
  text-transform: uppercase;
  &.smallRad {
    font-size: 0.7rem;
    letter-spacing: 1.5px;
  }
}

h4.rad {
  margin: 0 0 0.5rem;
  font-size: 1rem;
  letter-spacing: 2px;
  color: $grey;
  text-transform: uppercase;
}

button.icon {
  margin-right: 0.25rem;
  display: inline-block;
  border: 1px solid #dfe6f4;
  background: #efefef;
  padding: 0.5rem;

  width: 40px;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25rem;
  color: #6f7f9f;
  cursor: pointer;

  &:hover {
    background: $grey;
    border-color: $grey;
    color: #fff;
  }

  &:last-child {
    margin-right: 0;
  }

  &.active {
    background: $grey;
    color: #fff;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

.font {
  font-family: "Rubik", sans-serif;
}

input,
button,
select {
  -webkit-appearance: none;
}

.layout--notFound {
  .notFound__container {
    padding: 8rem 2rem;
    text-align: center;

    h2 {
      font-size: 4rem;
    }
  }
}

button.edit {
  padding: 0;
  border: 0;
  background: transparent;
  color: $grey;
  font-size: 1.25rem;
  &:hover {
    color: $navy;
  }
}

.processingNote {
  border: 1px solid $lightGrey;
  padding: .5rem;
  background: #FAFAFA;
  font-size: .825rem;
}

.tile {
  display: flex;
  flex-direction: column;
  @include innerPod;
  text-align: left;
  margin-bottom: 0.5rem;
  @include bp(Mama) {
    min-width: 110px;
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
  &:last-child {
    margin-right: 0;
  }
  label {
    margin-bottom: 0.2rem;
    display: inline-block;
    font-size: 0.75rem;
    color: $grey;
  }
  strong {
    display: inline-block;
    margin: 0;
    font-size: 1rem;

    @include bp(Kid) {
      font-size: 1.25rem;
    }
  }
  p {
    margin: 0;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: $grey;
  }

  &--blue {
    background: $blue;
    border-color: $blue;
    label,
    strong {
      color: #fff;
    }
  }

  &--teal {
    background: $teal;
    border-color: $teal;
    label,
    strong {
      color: #fff;
    }
  }

  &--navy {
    background: $navy;
    border-color: $navy;
    label,
    strong {
      color: #fff;
    }
  }

  &--orange {
    background: $orange;
    border-color: $orange;
    label,
    strong {
      color: #fff;
    }
  }

  &--red {
    background: $danger;
    border-color: $danger;
    label,
    strong {
      color: #fff;
    }
  }

  &--yellow {
    background: $warning;
    border-color: $warning;
    label,
    strong {
      color: #fff;
    }
  }

  &--orange {
    background: $orange;
    border-color: $orange;
    label,
    strong {
      color: #fff;
    }
  }
}

.loaderInner {
  &__container {
    margin: 0 1rem;
    padding: 2rem;
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    margin: 0 1rem;
    padding: 2rem;
    max-width: 600px;
    min-width: 15rem;
    background: #fff;
    border-radius: 5px;
    text-align: center;
    border: 2px solid $teal;

    h3 {
      margin: 0 0 0.5rem;
      text-align: left;
      color: $grey;

      @include flex;
    }

    p {
      margin-top: 0;

      &.message {
        margin: 0.75rem 0 0.5rem;
      }
    }

    &--invalid {
      border-color: $danger;
      i {
        font-size: 3rem;
        color: $danger;
      }
    }

    &--completed {
      border-color: $completed;
      i {
        font-size: 3rem;
        color: $completed;
      }
    }
  }

  &__error {
    margin-bottom: 1rem;
    background: $danger;
    padding: 4px 10px;
    p {
      margin: 0;
      font-size: 0.75rem;
      font-weight: 700;
      color: #fff;
    }
  }

  &__options {
    margin-bottom: 1rem;
    text-align: left;

    &__select,
    &__other {
      margin-bottom: 0.5rem;
      .inputRow__block {
        margin: 0;
      }
      &__bypassDetails {
        @include flex;
        & > div {
          &:first-child {
            width: 60%;
          }
          &:last-child {
            width: 37%;
          }
        }
      }
    }

    &__toggle {
      margin-bottom: 0.5rem;
    }

    &__other {
      input {
        padding: 10px;
        width: 100%;
        border: 1px solid #dfe6f4;
        border-radius: 5px;
        font-size: 1rem;
        outline: none;
        -webkit-appearance: none;
        @include bp(Mama) {
          font-size: 0.875rem;
        }

        &:focus {
          border-color: #24c0ac;
        }
      }
    }
  }

  &__input {
    input[type="number"] {
      padding: 10px;
      width: 100%;
      border: 1px solid #dfe6f4;
      border-radius: 5px;
      font-size: 1rem;
      outline: none;
      -webkit-appearance: none;
      @include bp(Mama) {
        font-size: 0.875rem;
      }
    }
  }

  &__actions {
    text-align: left;

    button {
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }

    &__split {
      @include flex;
    }
    &__yesNo {
      @include flex;
      justify-content: flex-start;
      gap: .5rem;
      button {
        margin: 0;
        min-width: 100px;
      }
    }
  }

  &--alert {
    h3 {
      padding: 0 !important;
    }
    .mask__container {
      max-width: 350px;
      min-width: 300px;
    }
    p {
      text-align: left;
    }
    .mask__actions {
      button {
        width: 100%;
      }
    }
  }

  &--confirm {
    h3 {
      padding: 0 !important;
    }
    p {
      text-align: left;
    }
  }

  &--blocker {
    background: rgba(255, 255, 255, 1);
    p {
      margin: 0;
    }
  }

  &--bypass {
    .mask__actions {
      @include flex;
      button {
        width: 49.5%;
      }
    }
  }

  p.error {
    padding: 0.25rem 0.5rem !important;
    border-radius: 0.25rem;
  }
}

.instructions {
  margin-bottom: 1rem;
  padding: 0.4rem 0.6rem;
  background: #fafafa;
  border: 1px solid #efefef;
  font-size: 0.75rem;
  border-radius: 6px;
}

// TODO: convert remaining .butt classNames to ButtonPrimary components
// (or define other button styles)
.butt {
  font-family: "Rubik", sans-serif;
  height: 36px;
  border: 2px solid #6f7f9f;
  background: #6f7f9f;
  color: #fff;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: 800;

  &:hover {
    background: #fff;
    color: #6f7f9f;
  }

  &:disabled {
    opacity: 0.3;
    &:hover {
      background: #fff;
      color: #6f7f9f;
    }
  }

  &--full {
    width: 100%;
  }

  &--white {
    border-color: #fff;
    background: #fff;
    color: $grey;
    &:hover {
      background: $grey;
      color: #fff;
    }
  }

  &--red {
    border-color: $danger;
    background: $danger;
    color: #fff;
    &:hover {
      background: #fff;
      color: $danger;
    }
  }

  &--teal {
    border-color: #24c0ac;
    background: #24c0ac;
    color: #fff;
    &:hover {
      background: #fff;
      color: #24c0ac;
    }
  }

  &--navy {
    border-color: $navy;
    background: $navy;
    color: #fff;
    &:hover {
      background: #fff;
      color: $navy;
    }
  }

  &--grey {
    border-color: #999;
    background: #999;
    color: #fff;
    &:hover {
      background: #fff;
      color: #999;
    }
  }

  &--blue {
    border-color: $blue;
    background: $blue;
    color: #fff;
    &:hover {
      background: #fff;
      color: $blue;
    }
  }

  &--orange {
    border-color: $orange;
    background: $orange;
    color: #fff;
    &:hover {
      background: #fff;
      color: $orange;
    }
  }

  &--clear {
    padding: 0;
    background: transparent;
    border: 0;
    color: $grey;
    &:hover {
      color: $navy;
    }
  }
}

a.butt {
  @include unicorn;
  font-size: .825rem;
  text-decoration: none;
  padding: 0 .5rem;
}

.filesArea__header + ul {
  margin-top: 0;
}

.filesArea {
  padding-top: 50px;

  &__header {
    font-size: 0.7rem !important;
    margin-bottom: 0.25rem !important;
    color: $blue !important;
  }

  &__files {
    margin-bottom: 1rem;
    font-size: 1rem;
    list-style: none;
    padding: 0 2rem;
    @include bp(Mama) {
      padding: 0;
    }

    li {
      margin-bottom: 0.5rem;
      text-align: center;
    }

    a {
      color: $teal;
    }
  }
}

.cb {
  position: relative;
  input[type="checkbox"] {
    position: absolute;
    top: 50%;
    left: 0;
    display: none;
  }

  input[type="checkbox"] + label {
    position: relative;

    padding-right: 26px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 3px;
      border: 2px solid #6f7f9f;
    }

    i {
      display: none;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-35%);
      width: 20px;
      height: 20px;
      color: #fff;
    }
  }

  &--on {
    label {
      &::before {
        background: #a2afcd !important;
      }
      i {
        display: block !important;
      }
    }
  }

  &--disabled {
    opacity: 0.25;
  }

  // input[type="checkbox"]:active + label {
  //   &:before {
  //     background: #A2AFCD;
  //   }
  // }
}

.devArea {
  margin-bottom: 2rem;
  border: 1px solid #6f7f9f;
  padding: 1rem;
  background: #fafafa;
  border-radius: 4px;
  .big {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
  p {
    margin: 0;
  }
}

.tags {
  margin-bottom: 0.5rem;
}

.tag {
  position: relative;
  margin-right: 5px;
  padding: 2px 5px;
  border: 1px solid #6f7f9f;
  text-transform: uppercase;
  color: #6f7f9f;
  font-size: 0.65rem;
  font-weight: 600;

  &:last-child {
    margin-right: 0;
  }

  &:after {
    display: none;
    content: "\f00d";
    margin-top: 1px;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    font-family: "Font Awesome 5 Pro";
    font-size: 0.75rem;
  }

  &--teal {
    border-color: $teal;
    color: $teal;
  }
  &--blue {
    border-color: $blue;
    color: $blue;
  }
  &--yellow {
    border-color: $warning;
    color: $warning;
  }
  &--orange {
    border-color: $orange;
    color: $orange;
  }
  &--navy {
    border-color: $navy;
    color: $navy;
  }
  &--red {
    border-color: $danger;
    color: $danger;
  }
  &--orange {
    border-color: $orange;
    color: $orange;
  }
  &--green {
    border-color: $completed;
    color: $completed;
  }
  &--purple {
    border-color: $purple;
    color: $purple;
  }
}

.tags--filled {
  .tag {
    border: 1px solid #6f7f9f;
    background: #6f7f9f;
    color: #fff;
    &:after {
      display: inline-block;
    }

    &--teal {
      border-color: $teal;
      background: $teal;
    }
    &--blue {
      border-color: $blue;
      background: $blue;
    }
    &--yellow {
      border-color: $warning;
      background: $warning;
    }
    &--navy {
      border-color: $navy;
      background: $navy;
    }
    &--red {
      border-color: $danger;
      background: $danger;
    }
    &--orange {
      border-color: $orange;
      background: $orange;
    }
    &--purple {
      border-color: $purple;
      background: $purple;
    }
  }
}

.tags--pills {
  margin-right: 5px;
  padding: 4px 8px;
  font-size: 0.75rem;
  font-weight: 600;
  .tag {
    display: inline-block;
    border-radius: 20px;
    padding-right: 20px;
    cursor: pointer;
    &:hover {
      filter: brightness(120%);
    }
  }
}

.pill {
  position: relative;
  display: inline-block;
  background: #333;
  font-size: 0.85rem;
  color: #fff;
  height: 24px;
  border-radius: 12px;
  line-height: 24px;
  padding: 0 10px 0 2px;

  span {
    position: relative;
    display: inline-block;
    margin-right: 5px;
    min-width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 10px;
    color: #333;
    line-height: 20px;
    text-align: center;
    font-weight: 700;
    padding: 0 0.25rem;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &--teal {
    background: $teal;
    span {
      color: $teal;
    }
  }

  &--navy {
    background: $navy;
    span {
      color: $navy;
    }
  }

  &--red {
    background: $danger;
    span {
      color: $danger;
    }
  }

  &--green {
    background: $teal;
    span {
      color: $teal;
    }
  }

  &--yellow {
    background: $warning;
    span {
      color: $warning;
    }
  }

  &--blue {
    background: $blue;
    span {
      color: $blue;
    }
  }

  &--orange {
    background: $orange;
    span {
      color: $orange;
    }
  }

  &--small {
    font-size: 0.65rem;
    font-weight: 700;
    height: 18px;
    border-radius: 9px;
    line-height: 19px;
    padding: 0 5px;
  }
}

p.big {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.75rem;
  color: #2d3766;
  text-align: center;

  &--enhanced {
    strong {
      color: $danger;
    }
  }
}

p.big--noAdditional {
  position: relative;
  z-index: 100;
  padding: 4rem 0;
}

p.sm {
  font-size: 0.75rem;
  span {
    display: inline-block;
    margin-right: 1rem;
  }
}

p.small {
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1rem;
  color: #2d3766;
  text-align: center;

  strong {
    color: $danger;
  }
}

p.tiny {
  font-size: 0.7rem;
  font-weight: 300;
  color: #000;
}

.switch {
  display: flex;

  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: $switch_width;
    height: $switch_height;
    background: grey;
    display: block;
    border-radius: $switch_height;
    position: relative;
  }

  label:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: $switch_toggle;
    height: $switch_toggle;
    background: #fff;
    border-radius: $switch_toggle;
    transition: 0.3s;
  }

  &--active {
    input[type="checkbox"] + label {
      background: $teal;
    }
    input[type="checkbox"] + label:after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }

  label:active:after {
    width: 30px;
  }
}

.inputNumber {
  max-width: 100px;
  border: 2px solid #dfe6f4;
  border-radius: 3px;
  display: inline-block;

  display: flex;
  justify-content: space-between;
  align-items: stretch;

  &__input {
    max-width: 100%;
    height: 100%;
    padding: 0 0.5rem;
    background: #fff;
    border: 0 !important;
    font-size: 1.5rem;
    font-weight: 600;
    color: #6f7f9f;
    text-align: center;
    outline: none;

    &:focus {
      background: #fafafa;
    }

    &__container {
      width: calc(100% - 20px);
      border-right: 2px solid #dfe6f4;
    }
  }

  &__actions {
    position: relative;
    width: 20px;

    &__button {
      // position: absolute;
      height: 50%;
      border: 0;
      background: transparent;
      padding: 0;
      outline: none;
      &:hover {
        background: #fafafa;
      }
      &--up {
        border-bottom: 1px solid #dfe6f4;
      }
      &--down {
        border-top: 1px solid #dfe6f4;
      }
    }
  }
}

.radio {
  &__radioItem {
    display: inline-block;
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }

    input[type="radio"] {
      display: none;
    }
    label {
      position: relative;
      padding-left: 22px !important;
      font-size: 0.85rem;
      font-weight: 700;
      cursor: pointer;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        background: #fafafa;
        border: 1px solid #dfe6f4;
        border-radius: 50%;
      }
      &:after {
        content: "";
        display: none;
        position: absolute;
        top: 50%;
        left: 4px;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        background: $teal;
        border-radius: 50%;
      }
    }
    input[type="radio"]:checked + label:after {
      display: block;
    }
  }
}

.slideToggle {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__part {
    border: 1px solid #dfe6f4;

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-right: 0;
    }
    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-left: 0;
    }

    label {
      display: inline-block;
      padding: 0.3rem 0.5rem;
      font-size: 0.85rem;
      cursor: pointer;
    }

    input[type="radio"] {
      display: none;

      &:checked + label {
        background: $teal;
        color: #fff;
        font-weight: 700;
      }
    }
  }
}

.blockAction {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 1rem;
  padding: 1rem;
  border: 0;
  border-radius: 4px;
  background: $grey;
  text-align: left;
  @include bp(Mama) {
    padding: 1rem 10rem 1rem 4.75rem;
  }

  &__content {
    & > strong {
      display: block;
      font-size: 1.25rem;
      color: #fff;
    }
    p {
      margin: 0;
      padding: 0 !important;
      color: #fff;
      strong {
        display: inline-block;
      }
    }
  }

  i {
    display: none;
    position: absolute;
    top: 50%;
    left: 1rem;
    margin-top: 2px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 3rem;
    @include bp(Mama) {
      display: block;
    }
  }

  button,
  a.butt {
    width: 100%;
    margin-top: 1rem;
    background: #fff;
    color: $grey;
    border-color: #fff;
    &:hover {
      background: #efefef;
      border-color: #efefef;
    }
    @include bp(Mama) {
      width: auto;
      margin-top: 0;
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
    }
  }

  a.butt {
    height: auto;
    padding: 8px 10px;
    font-size: 0.85rem;
    text-decoration: none;
  }

  &--teal {
    background: $teal;
  }

  &--navy {
    background: $navy;
  }

  &--blue {
    background: $blue;
  }

  &--orange {
    background: $orange;
  }

  &--red {
    background: $danger;

    button,
    a {
      background: #fff;
      color: $danger;
      border-color: #fff;
      &:hover {
        background: #efefef;
        border-color: #efefef;
      }
    }
  }

  &--tile {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 1rem 1rem 1rem 4.75rem;
    .blockAction__content {
      i {
        top: 1rem;
        transform: none;
      }
    }
    button {
      position: relative;
      top: auto;
      right: auto;
      margin-top: 1rem;
    }
  }
}

.blockActionTile {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 1rem;
  padding: 1rem;
  border: 0;
  border-radius: 4px;
  background: $grey;
  text-align: left;

  strong {
    display: block;
    font-size: 1.25rem;
    color: #fff;
  }

  p {
    margin: 0;
    padding: 0 !important;
    color: #fff;
  }

  i {
    display: none;
  }

  button,
  a.butt {
    margin-top: 1rem;
    background: #fff;
    color: $grey;
    border-color: #fff;
    width: 100%;
    &:hover {
      background: #efefef;
      border-color: #efefef;
    }
  }

  &--navy {
    background: $navy;
  }

  &--blue {
    background: $blue;
  }

  &--red {
    background: $danger;

    button {
      background: #fff;
      color: $danger;
      border-color: #fff;
      &:hover {
        background: #efefef;
        border-color: #efefef;
      }
    }
  }
}

.input_autosave {
  position: relative;
  transition: $base-transition;

  &__container {
    padding: 0 1rem;
  }

  label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    margin-top: -1px;
    font-size: 16px;
    font-weight: 600;
    transition: $base-transition;
    pointer-events: none;
    @include bp(Mama) {
      left: 1rem;
    }
  }

  input {
    padding: 6px 10px;
    padding-left: var(--marker-width, 100px);
    width: 100%;
    font-size: 1rem;
    font-family: "Rubik", sans-serif;
    outline: none;
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: $base-transition;
  }

  i.fa-edit {
    position: absolute;
    top: 50%;
    right: 11px;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 0.85rem;
    color: $grey;
  }

  &:hover {
    input {
      border-color: $lightGrey;
    }
  }

  &--focused {
    margin: 0.25rem 1rem;
    padding-top: 1.1rem;
    label {
      top: 0;
      left: 0;
      transform: none;
      font-size: 0.75rem;
      font-weight: 300;
      color: $grey;
      @include bp(Mama) {
        font-size: 0.875rem;
      }
      &.required {
        &:after {
          content: "*";
          color: #ff9150;
        }
      }
    }
    input {
      margin: 0;
      padding: 8px 8px 8px 1rem;
      border-color: $completed !important;
    }
    i {
      &.fa-edit {
        display: none;
      }
    }

    &.input_autosave--saved {
      .savedAlert {
        margin-top: 9px;
        right: 11px;
        opacity: 1;
      }
    }
  }

  &--saved {
    .savedAlert {
      opacity: 1;
    }
  }
}

.savedAlert {
  position: absolute;
  top: 50%;
  right: 34px;
  transform: translateY(-50%);
  height: 22px;
  @include flex;
  padding: 0 8px 0 22px;
  border-radius: 10px;
  background: $completed;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 22px;
  color: #fff;
  text-transform: uppercase;
  opacity: 0;
  pointer-events: none;
  transition: $base-transition;

  i {
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
    margin-top: -2px;
  }
}

.tip {
  position: relative;
  border-left: 5px solid #ffab00;
  background: #fff2e0;
  color: #3d474c;
  padding: 1rem 1rem 1rem 3.5rem;
  margin-bottom: 1rem;

  i {
    position: absolute;
    top: 50%;
    left: 0.5rem;
    transform: translateY(-50%);
    font-size: 2rem;
    margin-top: 1px;
    color: #ffab00;
  }

  p {
    margin: 0;
    font-family: "Lora", serif;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.25rem;

    a {
      font-weight: 600;
      color: $blue;
    }
  }
}

.scrollableContainer {
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    width: 50px;
    height: 100%;
    transition: $base-transition;
    pointer-events: none;
  }
  &__inner {
    overflow-y: hidden;
    overflow-x: scroll;
    height: calc(100% + 22px);
    padding-bottom: 22px;
  }

  &.scrolled--end {
    &:after {
      opacity: 0;
    }
  }
}

.data__tiles {
  @include flex;
  &__tile {
    padding: 1rem;
    width: 25%;
    margin-right: 1rem;
    background: #fff;
    border-radius: 10px;
    transition: $base-transition;
    border: 1px solid $lightGrey;
    cursor: pointer;
    text-align: left;
    font-size: 1rem;
    font-family: "Rubik", sans-serif;
    &:last-child {
      margin-right: 0;
    }

    &:hover {
      box-shadow: 0 0 20px rgba(43, 56, 102, 0.25),
        0 0 20px rgba(43, 56, 102, 0);
    }

    i {
      font-size: 2.5rem;

      &.fa-circle-exclamation {
        color: $danger;
      }
      &.fa-circle-check {
        color: $completed;
      }
    }

    h4 {
      margin: 2rem 0 1rem;
      font-size: 0.825rem;
      color: $grey;
    }

    h2 {
      margin: 0;
      font-size: 2.5rem;
      color: $navy;
    }
  }

  &--2up {
    & > button {
      width: 50%;
      &:first-child {
        margin-right: 1rem;
      }
    }
  }
}

.toggle {
  button {
    padding: 0.4rem 0.5rem;
    font-size: 1rem;
    border: 2px solid $grey;
    border-right: 0;
    background: #fff;
    color: $grey;

    @include bp(Toddler) {
      font-size: 1.25rem;
    }
  
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 2px solid $grey;
    }
    &:hover {
      background: #efefef;
    }
    &.active {
      background: $grey;
      color: #fff;
      &:hover {
        background: $grey;
        color: #fff;
      }
    }
  }
}

.informationBox {
  border: 1px solid $lightGrey;
  padding: 0.5rem;
  font-size: 0.825rem;
  background: #fafafa;

  &--white {
    background: #fff;
  }
}

.info__tiles {
  @include flex;
  &__tile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    width: 25%;
    height: 140px;
    margin-right: 1rem;
    background: #fff;
    border-radius: 10px;
    transition: $base-transition;
    border: 1px solid $lightGrey;
    cursor: pointer;
    text-align: left;
    font-size: 1rem;
    font-family: "Rubik", sans-serif;
    &:last-child {
      margin-right: 0;
    }

    &:hover {
      box-shadow: 0 0 20px rgba(43, 56, 102, 0.25),
        0 0 20px rgba(43, 56, 102, 0);
    }

    h4 {
      margin: 0;
      font-size: 1.25rem;
      color: $grey;
    }

    h2 {
      margin: 0;
      font-size: 2.5rem;
      color: $navy;
    }

    &__content {
      @include flex;
      width: 100%;

      i {
        font-size: 2rem;
        color: $grey;
      }

      strong {
        font-size: 2.5rem;
        color: $navy;
      }
    }

    &--invalid {
      h4,
      i,
      strong {
        color: $danger;
      }
    }
  }

  &--2up {
    & > button {
      width: 49.5%;
    }
  }

  &--3up {
    & > button {
      width: 32%;
    }
  }
}

.loadingError {
  max-width: 400px;
  margin: 5rem auto;
  border: 4px solid $navy;
  padding: 2rem;
  border-radius: 10px;
  h3 {
    margin: 0 0 1rem;
    font-size: 1.5rem;
    color: $navy;
  }
  p {
    margin: 0;
  }
}

.error_showStopper {
  position: relative;
  margin-bottom: 1rem;
  padding: 0.5rem 0.5rem 0.5rem 3.75rem;
  border-radius: 10px;
  background: $danger;
  color: #fff;
  i {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    font-size: 2rem;
  }
  & > h4 {
    margin: 0 0 0.25rem;
    font-size: 1.25rem;
    font-weight: 700;
    color: #fff;
  }
  p {
    margin: 0;
    font-size: 0.825rem;
  }
}

.searchBox {
  position: relative;
  i {
    position: absolute;
    top: 50%;
    left: 0.75rem;
    transform: translateY(-50%);
    margin-top: 1px;
    color: $grey;
  }
  .inputRow__block {
    margin: 0;
    input[type="text"] {
      padding-left: 35px;
    }
  }
}

.revealText {
  position: relative;
  font-family: "Inconsolata", monospace;
  font-weight: 600;
  color: $blue;
  cursor: pointer;
  &:hover {
    color: $teal;
  }

  &--revealed {
    margin-top: 0.25rem;
  }
}

.flyout__container {
  position: relative;
  button {
    border: 0;
    background: transparent;
    font-size: 2rem;
    color: $grey;
    outline: none;
    cursor: pointer;
    &:hover {
      color: $navy;
    }
  }
  .flyout {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
    border: 1px solid $lightGrey;
    background: #fafafa;
    width: 200px;

    header {
      text-align: right;
      button {
        // float: right;
        font-size: 1.5rem;
      }
    }

    & > button,
    & > a,
    & > div[role="button"] > button {
      display: block;
      width: 100%;
      padding: 0.5rem;
      font-size: 1rem;
      text-align: left;
      border-bottom: 1px solid $lightGrey;
      text-decoration: none;
      color: $grey;
      outline: none;
      &:hover {
        color: $navy;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.backendTable {
  a {
    color: $teal;
    &:hover {
      color: $navy;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 5px;
  }
  table,
  th,
  td {
    border: 1px solid $lightGrey;
  }
  thead {
    tr {
      background: $grey;
      th {
        color: #fff;
        padding: 5px;
        font-size: 0.75rem;
      }
    }
  }
  tfoot {
    tr {
      background: #dfdfdf;
      th {
        font-weight: 600;
        color: #000;
        padding: 5px;
        font-size: 0.75rem;
      }
    }
  }
  tbody {
    tr:nth-child(odd) {
      background: #fafafa;
    }
  }
  td {
    padding: 5px;
    font-size: 0.75rem;
    text-align: center;
  }
  .actions {
    a {
      display: inline-block;
      margin-right: 0.5rem;
      text-decoration: none;
      &:last-child {
        margin-right: 0;
      }
    }
    button {
      display: inline-block;
      padding: 0;
      background: transparent;
      border: 0;
      cursor: pointer;
      color: $teal;
      &:hover {
        color: $navy;
      }
    }
  }
}

.noSections {
  margin: 5rem auto 0;
  max-width: 400px;
  border: 4px solid $lightGrey;
  background: #FFF;
  padding: 2rem;
  text-align: center;
  border-radius: 10px;

  h3 {
    margin: 0;
    font-size: 1.5rem;
    color: $grey;
  }

  &__actions {
    @include flex;
    width: 100%;
    button {
      width: 49.5%;
    }

    &--1up {
      button {
        width: 100%;
      }
    }
  }
}

.setupWizard {
  .infoPacket .collateral {
    margin-top: 1rem;
    // border: 1px solid $lightGrey;
    // background: #FFF;
    // padding: 2rem;
    // border-radius: 10px;
  }
}

.forfeitureForm {
  .react-datepicker-wrapper {
    width: 100%;
  }
}

.inputRow__block {
  .react-datepicker-wrapper {
    width: 100%;
  }
}

.dsg-container {
  min-height: calc(75vh - 35px);
}

.activation {
  margin: 0 auto 30rem;
  padding: 3rem;
  max-width: 800px;
  border: 4px solid $lightGrey;
  font-size: 2rem;
  border-radius: 20px;
  text-align: center;
  color: $grey;
}

.img-fluid {
  flex-grow: 1;
  // width: 10%;
  height: 250px;
  max-width: 80%;
  border-radius: 10px;
  object-fit: cover;
}