.layout {
  margin: 0 auto;

  &__info {
    max-width: 500px;
    margin: 0 auto;
    padding: 30px 37px;

    h3 {
      margin: 0 0 10px;
      font-size: 1.5rem;
      color: #4259ed;
    }

    p {
      margin: 0 0 10px;
      font-size: 1.125rem;
      color: #6f7f9f;
    }

    &__minimalist {
      padding: 10px 0 10px 1rem !important;
      background: $grey;
      h3 {
        font-size: 1.1rem;
        line-height: 1.1rem;
        margin: 0;
        color: #FFF !important;
      }
    }
  }

  &--expired_cases {
    padding: 0 !important;
  }
}

.minimalistHeader {
  .layout__info {
    position: relative;
    max-width: 100%;

    &__left {
      max-width: 500px;
    }
  }

  &__button {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%) rotate(180deg);
    width: 46px;
    height: 46px;
    @include unicorn;
    border: 1px solid $grey;
    color: $grey;
    background: #FFF;
    border-radius: 50%;
    font-size: 1.5rem;
    cursor: pointer;
    transition: $base-transition;

    &:hover {
      border-color: $navy;
      background: #EFEFEF;
      color: $navy;
    }
  }

  &--on {
    .minimalistHeader__button {
      width: 30px;
      height: 30px;
      font-size: 1rem;
      transform: translateY(-50%) rotate(0);
    }
  }
}

body.page--standard {
  #root main {
    padding-bottom: 0;
  }
}

.layout__page {
  .page__title {
    text-align: left;
    h2 {
      font-size: 2rem;
      margin: 2rem 0;
    }
  }
  .page__title,
  .page__content {
    max-width: 1200px;
    padding: 0 2rem;
    margin: 0 auto;
  }

  // table {
  //   border-collapse: collapse;

  //   th, td {
  //     padding: 10px;
  //   }
  // }

  // table, th, td {
  //   border: 1px solid #CCC;
  // }

  a {
    color: $teal;
    &:hover {
      color: $navy;
    }
  }

  h3.section__title {
    padding-top: 4.5rem;
    width: 100%;
  }
  p {
    width: 100%;
  }
}

.tabs {
  margin-top: 1rem;
  margin-bottom: 1rem;

  &__tabs {
    display: flex;
    justify-content: space-between;

    & > button {
      position: relative;
      width: 50%;
      border: 0;
      background: transparent;
      // background: #F30;
      padding: 15px 0;
      font-size: 1.25rem;
      font-weight: 500;
      border-bottom: 2px solid #a2afcd;
      color: #a2afcd;
      outline: none;

      span {
        position: absolute;
        top: 5px;
        left: 50%;
        display: inline-block;
        width: 18px;
        height: 18px;
        background: $danger;
        color: $white;
        font-size: 0.7rem;
        line-height: 19px;
        border-radius: 100%;
        font-weight: 700;
      }

      &:nth-child(2) {
        span {
          margin-left: 28px;
        }
      }
      // &:nth-child(3) {
      //   span {
      //     margin-left: 24px;
      //   }
      // }
      // &:nth-child(4) {
      //   span {
      //     margin-left: 54px;
      //   }
      // }

      &.active {
        border-bottom: 2px solid #4259ed;
        color: #061387;
      }
    }
  }
}

.tabs_sections {
  color: #2d3766;

  &__section {
    display: none;
    section {
      border-bottom: 2px solid #a2afcd;

      p:not(.big) {
        strong {
          margin-right: 1rem;
        }
      }
    }

    &--active {
      display: block;
    }
  }
}

.layout--defendantStatus {
  max-width: 600px;
  margin: 0 auto;

  .register__form__info {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
  }

  section {
    border-bottom: 0;
  }
}

.layout--defendantStatus {
  .indemnitorDefendantInfo {
    h5 {
      margin: 0 0 0.5rem;
      padding: 0 1rem;
      font-size: 0.85rem;
      letter-spacing: 2px;
      color: $grey;
      text-transform: uppercase;
    }
  }
  .caseInformation {
    &__header {
      position: relative;
      padding: 1.5rem 1rem;
      @include bp(Mama) {
        @include flex;
        align-items: flex-start;
        padding: 2rem 0 1.5rem;
      }
      &__info {
        padding-right: 2rem;
        h3 {
          margin: 0 0 0.5rem;
          padding: 0;
          font-size: 1.5rem;
          color: #2b3866;
        }
        & > p {
          margin: 0;
          color: #6f7f9f;
        }
        .caseId {
          margin-top: 0.5rem;
          font-size: 0.85rem;
        }
      }
      .bailBondAgency {
        margin-top: 1rem;
        @include bp(Mama) {
          // position: absolute;
          // top: 1.5rem; right: 0;
          min-width: 250px;
          margin-top: 0;
        }
        h5 {
          margin: 0 0 0.25rem;
          font-size: 0.85rem;
          letter-spacing: 2px;
          color: $grey;
          text-transform: uppercase;
        }
        p {
          font-size: 0.825rem;
          strong {
            display: inline-block;
            margin-bottom: 0.25rem;
          }
        }
        &__mobileActions {
          @include flex;
          @include bp(Mama) {
            display: none;
          }

          button.butt,
          a.butt {
            @include unicorn;
            width: 32%;
            font-size: 1.25rem;
            text-decoration: none;
          }
        }
        &__cards {
          text-align: center;
          margin-top: 1rem;
          @include bp(Mama) {
            margin-top: 0;
            text-align: left;
          }
          &__top {
            @include flex;
            justify-content: center;
            @include bp(Mama) {
              justify-content: flex-start;
            }
          }
          span {
            display: inline-block;
            margin-right: 0.25rem;
            &:last-child {
              margin-right: 0;
            }

            &.amex {
              position: relative;
              &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -55%);
                width: 40px;
                height: 40px;
                border: 6px solid $danger;
                border-radius: 50%;
              }
            }
          }
          i {
            font-size: 2.5rem;
            color: $grey;
          }
          p {
            margin: 0;
            @include bp(Mama) {
              text-align: left;
            }
          }
        }
      }
    }
  }

  section {
    border-bottom: 0;
  }
}

.layout--chooseBondsman {
  .register__form__info {
    text-align: center;
    max-width: 600px;
    margin: 2rem auto;

    p {
      margin: 0 0 0.25rem;
      color: $navy;
    }
  }
  .bondAgencySelected {
    max-width: 600px;
    margin: 0 auto;
  }
}

body.template-agentProfile {
  .layout.layout--dashboard {
    padding: 0 1rem !important;
  }
  .dashboard.register__form {
    max-width: 100%;
    .register__form__block {
      padding: 0;
    }
  }
}

body.template-employees {
  .dashboard.register__form {
    max-width: 100%;
    .register__form__block {
      padding: 0;
    }
  }
}

.layout--enterPaymentInfo {
  max-width: 600px;
  margin: 0 auto;
}

// New Layout for Agents

$asideWidth: 170px;
$asideWidthMobile: 45px;

body.template-bondsman:not(.beta-layout-temp-body) {
  background: #fafafa;

  #root div.main {
    margin: 0 auto;
    padding: 57px 0 0 $asideWidthMobile;
    max-width: 100vw;
    @include flex;
    align-items: flex-start;
    background: #fff;
    min-height: calc(var(--vh, 100vh));
    height: 100%;
    border-right: 1px solid $lightGrey;
    @include bp(Mama) {
      padding-left: $asideWidth;
    }

    aside {
      position: fixed;
      top: 57px;
      left: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      padding: 0.25rem 0.5rem;
      width: $asideWidthMobile;
      height: calc(var(--vh, 100vh) - 57px);
      background-color: $navy;
      border-right: 1px solid $lightGrey;
      @include bp(Mama) {
        width: $asideWidth;
      }
    }

    &__content {
      &__container {
        width: 100%;
      }
    }

    // & > div.dashboard_content {
    //   width: 100%;
    //   min-height: calc(var(--vh, 100vh) - 57px);
    //   border-right: 1px solid $lightGrey;
    //   background: #ffffff;
    // }

    &--searching {
      .main__content {
        opacity: 0.2;
      }
    }
  }

  &.has-error {
    #root div.main {
      padding: 87px 0 0 $asideWidthMobile;
      @include bp(Mama) {
        padding-left: $asideWidth;
      }
      aside {
        top: 87px;
        height: calc(var(--vh, 100vh) - 57px - 30px);
      }
      & > div.dashboard_content {
        min-height: calc(var(--vh, 100vh) - 57px - 30px);
      }
    }
  }
}

body.template-agentDashboard, body.template-case {
  .main__content__container {
    background: #FAFAFA;
  }
}

.searchForm {
  // padding: .5rem;
  display: block;
  position: relative;

  input[type="search"] {
    @include input;
  }

  button[type="submit"] {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 30px;
    height: 30px;
    font-size: 1rem;
    text-align: center;
  }
}

.layout {
  // max-width: 964px;
  margin: 0;
  min-height: calc(var(--vh, 100vh));
  background: #fff;
  @include bp(Mama) {
    padding: 0;
  }

  &--agentDashboard {
    position: relative;
    padding: 0 1rem;
    &.layout--customers_search {
      padding: 0;
      .layout__info {
        padding: 1.5rem 1rem;
      }
    }
    .layout__info {
      button.settingsButton {
        position: absolute;
        top: 1rem;
        right: 1rem;
        padding: 0;
        border: 0;
        background: transparent;
        font-size: 2rem;
        color: $lightGrey;
        cursor: pointer;
        &:hover {
          color: $navy;
        }
        &--active {
          color: $navy;
        }
      }
    }
    button.addButton {
      width: 100%;
      background: #fafafa;
      border: 2px solid $lightGrey;
      padding: 1rem 0;
      font-size: 3rem;
      color: $grey;
      cursor: pointer;
      border-radius: 10px;
      &:hover {
        color: $navy;
      }
    }
  }

  &__info {
    margin: 0;
    padding: 1rem 0;
    @include bp(Mama) {
      padding: 1.5rem 0;
    }

    h3 {
      color: $navy;
    }
  }
}

.customDashboard {
  margin-bottom: 1rem;
  button.remove {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    width: 30px;
    height: 30px;
    @include unicorn;
    border: 0;
    background: transparent;
    padding: 0;
    font-size: 2rem;
    color: $grey;
    cursor: pointer;
    &:hover {
      color: $navy;
    }
  }
  .checkinDashboard__tiles {
    padding: 0;
  }
  .paymentsDashboard__tiles {
    &__loading {
      position: relative;
      border: 2px solid $lightGrey;
      background: #fafafa;
      min-height: 120px;
      width: 100%;
      border-radius: 10px;
      &__wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  section {
    position: relative;
    margin-bottom: 1rem;
  }
  &__adding {
    border: 2px solid $lightGrey;
    background: #fafafa;
    padding: 1rem;
    border-radius: 10px;
    .inputRow__block {
      margin: 0;
    }
  }
}

body.template-bondsman {
  #root div.main {
    .main__content__container {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .main__content {
      flex: 1;
      overflow: auto;
    }

    & > div.help_content {
      position: fixed;
      top: 130px;
      right: 0;
      width: 500px;
      height: calc(var(--vh, 100vh) - 113px - 32px);
      z-index: 1;
      background: $grey;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border: 2px solid $lightGrey;
      background: #fff;
      padding: 2rem;
      box-shadow: 0 0 20px rgba(43, 56, 102, 0.25),
        0 0 20px rgba(43, 56, 102, 0);
      display: none;

      @media (min-width: 1600px) {
        left: 50%;
        transform: translateX(300px);
      }
    }
  }
}

body.beta-layout-temp-body {
  #root div.main {
    padding-top: 57px;
    height: 100vh;
    display: flex;

    & > div.dashboard_content {
      height: calc(100vh - 57px);
      display: flex;
      flex-direction: column;

      .main__content {
        overflow: auto;
      }
    }
  }
}

body.template-case {
  &.full-width {
    #root div.main {
      max-width: 100%;
      padding-left: 0;
      aside {
        display: none;
      }
    }
  }
  &.layout--fullScreen {
    #root div.main {
      max-width: 100%;
      aside {
        left: 0;
        transform: none;
      }
      & > div {
      }
    }
  }
}

body.template-agentDashboard--customers {
  .main > div .mainSearch {
    display: none;
  }
}

.mask--filterModal {
  .closeButton {
    padding: 0;
    border: 0;
    background: transparent;
    font-size: 1.5rem;
    color: $grey;
    cursor: pointer;

    &:hover {
      color: $navy;
    }
  }
}

.badge {
  // @include flex;
  min-width: 40px;
  border: 2px solid $grey;
  border-radius: 3px;
  text-align: center;

  label {
    display: block;
    margin: 0;
    font-size: 0.65rem;
    text-transform: uppercase;
    background: $grey;
    color: #fff;
    border-bottom: 1px solid $grey;
  }
  strong {
    display: inline-block;
    font-size: 1.5rem;
    line-height: 1.6rem;
    color: $grey;
    padding: 0.25rem 0.5rem;
  }

  &--teal {
    border-color: $teal;
    label {
      background: $teal;
      border-color: $teal;
    }
    strong {
      color: $teal;
    }
  }

  &--red {
    border-color: $danger;
    label {
      background: $danger;
      border-color: $danger;
    }
    strong {
      color: $danger;
    }
  }

  &--yellow {
    border-color: $warning;
    label {
      background: $warning;
      border-color: $warning;
    }
    strong {
      color: $warning;
    }
  }
}

.dateBadge {
  min-width: 40px;
  border: 2px solid $grey;
  border-radius: 3px;
  text-align: center;

  p {
    margin: 0;
    font-size: 0.65rem;
    text-transform: uppercase;
    background: $grey;
    color: #fff;
    border-bottom: 1px solid $grey;
  }
  strong {
    font-size: 1.5rem;
    line-height: 1.6rem;
    color: $grey;
  }

  &--completed {
    border: 2px solid $blue;

    p {
      background: $blue;
      border-bottom: 1px solid $blue;
    }
    strong {
      color: $blue;
    }
  }
}

.customers {
  &__container {
    .loadingMore {
      text-align: center;
      color: #ccc;
    }

    .endOfRoadMessage {
      padding: 2rem 0;
      text-align: center;
      color: $grey;
    }

    .ais-SearchBox {
      width: 100%;
      form {
        position: relative;
        display: block;
      }
      input {
        @include input;
      }
      button {
        position: absolute;
        top: 4px;
        right: 4px;
        width: 30px;
        height: 30px;
        border: 2px solid $teal;
        background: $teal;
        color: #fff;
        border-radius: 5px;
        outline: none;
        cursor: pointer;

        svg {
          fill: #fff;
        }

        &:hover {
          background: #fff;
          color: $teal;

          svg {
            fill: $teal;
          }
        }
      }
    }

    ul.ais-Hits-list {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .tags {
      margin-bottom: 1rem;
      padding: 0;
    }

    .noResults {
      text-align: center;
      padding: 2rem 1rem;
    }
  }

  &__filters {
    margin-bottom: 1rem;
    @include flex;
    &__left {
      width: 100%;
      .tags {
        margin-bottom: 0;
        padding: 0;
      }

      &.enhancedLeft {
        @include flex;
        gap: .5rem;
        .mobileOnly {
          display: flex;
          @include bp(Mama) {
            display: none;
          }
        }
      }

    }
    &__right {
      display: none;
      width: 100%;
      @include bp(Mama) {
        @include flex;
        justify-content: flex-end;
      }
      #sortBy {
        min-width: 140px;
        margin-left: 0.5rem;
      }
      button.butt {
        width: 38px;
        height: 38px;
        margin-left: 0.5rem;

        i {
          font-size: 1.25rem;
        }

        &.butt--sort {
          i {
            margin-top: 2px;
          }
        }

        &.butt--on {
          background: #fff;
          color: $grey;
        }
      }
    }
  }

  &__customer {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    margin: 0 0 0.5rem;
    border: 2px solid #dfe6f4;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;

    &__nub {
      @include unicorn;
      width: 40px;
      height: auto;
      background: $grey;
      border-right: 2px solid $lightGrey;

      &--actionNeeded {
        background: $danger;
      }

      &--actionWaiting {
        background: $teal;
      }

      &--blue {
        background: $blue;
      }

      &--orange {
        background: $orange;
      }

      i {
        font-size: 1.5rem;
        color: #fff;
      }
    }

    &:hover {
      background: #fafafa;
      cursor: pointer;
    }

    &__left {
      @include flex;
      justify-content: flex-start;
      align-items: stretch;
      width: 100%;
      @include bp(Mama) {
        width: auto;
        justify-content: space-between;
      }

      p {
        margin: 0;
      }
    }

    &__right {
      display: none;
      padding: 0.5rem;
      @include bp(Mama) {
        @include flex;
        justify-content: flex-end;
      }
      .tags {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 0;
        .tag {
          display: inline-block;
        }
      }
      p {
        margin: 0;
        font-size: 0.75rem;
      }
      i {
        margin-left: 0.5rem;
        font-size: 1.5rem;
        color: #ccc;
      }
    }

    &--mobileTag {
      display: none;
      // @include bp(Mama) {
      //   display: flex;
      // }
    }

    &--date {
      @include flex;
      margin-left: 0.5rem;
      display: none;
      @include bp(Mama) {
        display: flex;
      }
      // padding: .5rem 0;
    }

    &--names {
      padding: 0.5rem;
      h3 {
        // @include flex;
        margin: 0 0 0.25rem;
        font-size: 1rem;
        line-height: 1rem;
        @include bp(Mama) {
          font-size: 1.25rem;
          line-height: 1.25rem;
        }
      }
      p {
        margin: 0;
        font-size: 0.75rem;
        line-height: 0.75rem;
        &:last-child {
          margin: 0;
        }

        &.dateMobile,
        &.countyMobile {
          display: block;
          @include bp(Mama) {
            display: none;
          }
        }

        &.dateMobile {
          margin: 0.25rem 0;
        }
      }
    }
  }
}

.twoPointOhOhOh {
  @include unicorn;
  height: 32px;
  border-radius: 16px;
  padding: .25rem .75rem;
  background: $navy;
  font-weight: 700;
  color: #FFF;
  margin-left: .5rem;

  i {
    font-size: 1rem;
    margin-left: .5rem;
    color: #FFF;
  }
}