.layout--magicCode {
  main {
    padding-top: 0 !important;
  }
}

.codePage {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  &__code-box {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 365px;
    margin: 5rem auto 0;
    background: #fafafa;
    border: 2px solid #dfe6f4;
    border-radius: 10px;

    @include bp(Mama) {
      margin: 7.5rem auto 0;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    padding: 2.5rem 1rem;

    @include bp(Mama) {
      padding: 2.5rem 1.125rem 3.125rem;
    }
  }

  &__logo {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    height: 30px;
    object-fit: cover;
  }

  &__box-text {
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2;
    color: #6f7f9f;
    text-align: center;
    margin: 1.375rem 0 0.75rem;
    letter-spacing: 0.3px;
  }

  .formBlock {
    margin-bottom: 1.05rem;

    label {
      font-family: "Rubik", sans-serif;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: #6f7f9f;
    }

    button {
      font-family: "Rubik", sans-serif;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.2;
      height: 50px;
      letter-spacing: 0.3px;
      width: 100%;
      margin-top: 17px;
    }
  }
}
