@import "styles/basics/vars";

.butt {
  font-size: 0.85rem;
  font-weight: 600;
  color: $navy;
  background: transparent;
  border: 0;
  outline: none;
  text-decoration: underline;
  padding: 0;
  margin-right: 2rem;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &.block {
    display: block;
    margin: 0 0 0.5rem 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
