.collateral {
  margin-top: 1rem;
  border: 1px solid $lightGrey;
  background: #FFF;
  padding: 1rem;
  border-radius: 10px;
  
  @include bp(Mama) {
    padding: 2rem;
  }

  &__content {
    &--type {
      .inputRow__block {
        margin: 0;
      }
    }

    &--on {
      .collateral__content--type {
        margin-bottom: 20px;
      }
    }
  }

  &__actions {
    @include flex;
    margin-top: 1rem;
    button {
      width: 49.5%;
    }
  }
}