.mask--recalculate {
  .mask__options {
    p.small {
      margin-top: 0.5rem;
      text-align: left;
    }
  }
}

.paymentPlan {
  margin: 0 auto 1.5rem;
  border: 1px solid $lightGrey;

  &--customer {
    margin: 0;
    // max-width: 550px;
  }

  &__customer_actions {
    margin: 0 auto;
    max-width: 550px;

    button {
      width: 100%;
    }
  }

  &__customer_confirm {
    margin: 0 auto 1rem;
    max-width: 550px;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;

    button {
      width: 100%;
    }
  }

  &__top,
  &__btm {
    display: flex;
    align-items: stretch;
  }

  &__item {
    position: relative;
    @include unicorn;
    border-right: 1px solid $lightGrey;
    padding-bottom: 21px;
    background: #fafafa;

    &:last-child {
      border-right: 0;
    }

    label {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: block;
      padding: 3px 0;
      border-top: 1px solid $lightGrey;
      font-size: 0.75rem;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      background: $navy;
      color: #fff;
    }

    &__content {
      text-align: center;
      width: 100%;

      p {
        margin: 0;
        font-size: 2rem;
        font-weight: 700;
      }

      &--date {
        padding: 1rem 0;
        p {
          margin: 0;
        }
      }
    }
  }

  &__top {
    .paymentPlan__item {
      width: 50%;
    }
  }
  &__btm {
    .paymentPlan__item {
      width: calc(100% / 3);
    }
  }

  &__form {
    margin-bottom: 1rem;
    border: 1px solid $lightGrey;
    &__row {
      border-bottom: 1px solid $lightGrey;
      &:last-child {
        border: 0;
      }

      & > div {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        & > label {
          width: 250px;
          padding: 0.5rem;
          font-size: 1.5rem;
          color: $grey;
        }
      }

      &__control {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;

        width: calc(100% - 250px);
        border-left: 1px solid $lightGrey;
        // line-height: 0;

        & > span {
          @include unicorn;
          background: $navy;
          width: 44px;
          i {
            font-size: 1.5rem;
            color: #fff;
          }
        }

        input[type="text"],
        input[type="number"] {
          width: 100%;
          height: 100%;
          padding-left: 0.5rem;
          border: 0;
          background: transparent;
          outline: none;
          text-align: left;
          font-size: 1.5rem;
          font-weight: 700;
          color: $grey;
          &:focus {
            background: #efefef;
          }
        }

        .react-datepicker-wrapper {
          height: 100%;
          width: 100%;
          .react-datepicker__input-container {
            height: 100%;
            width: 100%;
          }
        }

        &.buttonAdded {
          position: relative;
          & > button {
            position: absolute;
            top: 50%;
            right: 0.25rem;
            transform: translateY(-50%);
            // &:hover {
            //   background: $navy;
            // }
          }
        }

        &--segment {
          line-height: 1;
          & > div {
            width: calc(100% / 3);
            height: 100%;
            text-align: center;

            input[type="radio"] {
              display: none;

              & + label {
                display: inline-block;
                width: 100%;
                height: 100%;
                border-right: 1px solid $lightGrey;
                font-size: 0.75rem;
                font-weight: 700;
                color: $grey;
                text-transform: uppercase;
                padding-top: 16px;
                cursor: pointer;
              }

              &:checked + label {
                background: $grey;
                color: #fff;
              }
            }

            &:last-child input[type="radio"] + label {
              border-right: 0;
            }
          }
        }
      }
    }
  }

  &__breakDown {
    &__title {
      @include flex;
      a {
        padding: 0 0.5rem;
        letter-spacing: 0;
        height: 30px;
        line-height: 28px;
        text-decoration: none;
      }
    }
    table {
      width: 100%;
      border: 1px solid #dfe6f4;
      border-collapse: collapse;

      tr {
        padding: 0;
        border-bottom: 1px solid #dfe6f4;
        &:last-child {
          border-bottom: 0;
        }

        &.totalRow {
          background: #efefef;
          .paymentPlan__breakDown__item--amount {
            background: #fff;
          }
          &--end {
            border-bottom: 4px solid $lightGrey;
          }
        }
      }

      th.paymentPlan__breakDown__item,
      td.paymentPlan__breakDown__item {
        border-right: 1px solid $lightGrey;
        padding: 5px;
        font-size: 0.85rem;
        color: $grey;

        &--status {
          width: 30px;
          text-align: center;
          font-size: 1rem;

          i.fa-check-circle {
            color: $completed;
          }
        }

        &--date {
          width: 120px;
          text-align: center;
        }
        &--amount {
          width: 80px;
          text-align: center;
        }
        &--remaining {
          position: relative;
          width: 80px;
          text-align: center;

          button {
            display: none;
            position: absolute;
            top: 50%;
            right: -25px;
            transform: translateY(-50%);
            width: 24px;
            height: 24px;
            border: 0;
            background: transparent;
            font-size: 1.3rem;
          }
        }

        &:last-child {
          border-right: 0;
        }
      }

      tr.paymentPlan__breakDown__row {
        &--altered {
          background: #fffbd6;
        }
        &--errorOnEquation {
          .paymentPlan__breakDown__item--remaining {
            background: $danger;
            color: #fff;
          }
        }
        &:hover {
          td.paymentPlan__breakDown__item {
            &--remaining {
              button {
                display: block;
              }
            }
          }
        }
        &--editing {
          background: #fafafa;
          .breakdown_editor {
            @include flex;
            width: 100%;
            &__left {
              @include flex;
              justify-content: flex-start;
              & > span,
              & > div {
                margin-right: 0.5rem;
              }
              .inputRow__block {
                margin-bottom: 0;
                label {
                  display: none;
                }
              }
            }
            &__right {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              width: 85px;
              button {
                border: 0;
                background: transparent;
                padding: 0;
                margin-right: 0.5rem;
                outline: none;
                &:last-child {
                  margin-right: 0;
                }
                i {
                  font-size: 1.5rem;
                  &.fa-check-circle {
                    color: $teal;
                  }
                  &.fa-times-circle {
                    color: $grey;
                  }
                  &.fa-trash {
                    color: $danger;
                    font-size: 1.25rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    &--customer {
      // margin: 0 auto 1.5rem;
      // max-width: 550px;
      table {
        border-top: 0;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;

    & > button {
      width: 32%;
      font-weight: 700;
    }
  }

  &__adjustmentMade {
    position: relative;
    display: block;
    width: 100%;
    padding: 1rem 7.4rem 1rem 4.75rem;
    text-align: left;
    border-radius: 4px;
    border: 0;
    margin-bottom: 1rem;
    background: $danger;

    strong {
      display: block;
      font-size: 1.25rem;
      color: #fff;
    }
    p {
      margin: 0;
      color: #fff;
    }

    i {
      position: absolute;
      top: 50%;
      left: 1rem;
      margin-top: 2px;
      transform: translateY(-50%);
      color: #fff;
      font-size: 3rem;
    }

    button {
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      background: #fff;
      color: $danger;
      border-color: #fff;
      &:hover {
        background: #efefef;
        border-color: #efefef;
      }
    }
  }
}

// .payments__invoice {
//   .paymentPlan--customer {
//     margin-top: 1rem;
//   }
// }

.paymentPlan_payoff {
  max-width: 425px;
  margin: 0 auto;

  .cardExamples {
    margin-bottom: 1rem;
    padding: 1rem;
    border: 1px solid $lightGrey;

    & > p {
      margin-top: 0;
    }
  }

  &__confirmation {
    .cb {
      position: relative;
      padding-left: 40px;
      &__text {
        font-size: 0.85rem;
      }
      &__checkbox {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }

  .register__form {
    padding: 2rem 0 0;

    .last-row {
      .formBlock {
        margin-bottom: 0;
      }
    }
  }

  &--confirmation {
    padding-top: 2rem;
  }
}

.card {
  &__decision {
    margin: 2rem auto 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > button {
      width: 49%;
    }
  }
}

.paymentPlanCell {
  position: relative;
  // z-index: 1;
  // margin: 0 auto;
  // max-width: 600px;
  padding-bottom: 1rem;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-30px, -16px);
    width: 14px;
    height: 14px;
    background: $lightGrey;
    border-radius: 50%;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-25px, 0);
    width: 4px;
    height: 100%;
    background: $lightGrey;
  }

  &--last {
    &::after {
      height: 48%;
    }
  }

  &__section {
    margin-bottom: 2rem;
  }

  &__inner {
  }

  &__top {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    border: 2px solid $lightGrey;
    background: #fff;
    border-radius: 5px;
  }

  &__mid {
    margin: 0 1rem 0;
    border: 2px solid $lightGrey;
    border-top: 0;
    padding: 1rem;
    background: #fafafa;

    .cardExamples {
      margin-bottom: 1rem;
      padding: 0;
    }

    .last-row {
      & > div.formBlock {
        margin-bottom: 0;
      }
    }

    .card__decision {
      margin: 1rem 0 2rem;
    }
  }

  &__btm {
    position: relative;
    bottom: 0;
    z-index: 0;
    margin: -34px 1rem 0; // -34px[
    border: 2px solid $lightGrey;
    border-top: 0;
    background: #fafafa;
    transition: $base-transition;

    &__details {
      @include flex;
      display: none;
      background: #fafafa;
      padding: 0.25rem;
      font-size: 0.825rem;
      p {
        margin: 0;
      }
      label {
        display: block;
        font-size: 0.7rem;
        font-weight: 700;
      }
    }

    &__actions {
      @include flex;
      // border-top: 1px solid $lightGrey;
      padding: 0.25rem;
      & > div {
        display: flex;
        align-items: center;
        &:first-child {
          justify-content: flex-start;
          button {
            margin: 0;
            margin-right: 0.5rem;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        &:last-child {
          justify-content: flex-end;
          button {
            margin: 0;
            margin-left: 0.5rem;
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
      button {
        height: 24px;
        text-transform: uppercase;
        font-size: 0.6rem;
        font-weight: 700;
      }
    }
  }

  .register__form {
    padding-bottom: 0;
  }

  &--on {
    .paymentPlanCell__btm {
      margin-top: 0;
    }
  }

  &__left {
    width: 100%;
    padding: 0.5rem;
    h3,
    h5 {
      margin: 0;
    }
    h3 {
      @include flex;
      margin-bottom: 0.25rem;
      color: $navy;

      div.tags {
        margin: 0;
      }
    }
    h5 {
      font-weight: 400;
    }
    p {
      margin: 0.25rem 0 0;
      font-size: 0.75rem;
    }
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    span {
      @include unicorn;
      flex-direction: column;
      padding: 0 1rem;
      border-left: 2px solid $lightGrey;
      line-height: 1rem;
      &:last-child {
        margin-right: 0;
      }
      strong {
        display: block;
        font-size: 0.6rem;
        line-height: 0.6rem;
        text-transform: uppercase;
        font-weight: 400;
        margin-bottom: 0.5rem;
        color: $grey;
      }
      &.paymentPlanCell--digit {
        min-width: 110px;
        padding: 0 0.5rem;
        font-size: 1.5rem;
        font-weight: 700;
        color: $navy;
      }
      &.paymentPlanCell--amount {
        width: 150px;
        padding: 0;
        font-size: 1.5rem;
        color: $grey;
      }
      &.paymentPlanCell--status {
        padding: 0 0.5rem;
        font-size: 2.5rem;
        i.fa-check-circle {
          color: $teal;
        }
        i.fa-exclamation-circle,
        .fa-piggy-bank,
        .fa-cog {
          color: $warning;
        }
        i.fa-times-circle,
        i.fa-circle-question {
          color: $danger;
        }
        i.fa-circle {
          color: $grey;
        }
      }
      &.paymentPlanCell--actions {
        padding: 0 0.5rem;
        color: $grey;
        cursor: pointer;
      }
    }
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      &:last-child {
        min-width: 200px;
      }
    }

    padding: 0 0 1rem 40px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(0, 12px);
      width: 14px;
      height: 14px;
      background: $lightGrey;
      border-radius: 50%;
    }

    &::after {
      content: "";
      position: absolute;
      top: 12px;
      left: 0;
      transform: translate(5px, 0);
      width: 4px;
      height: 45px;
      background: $lightGrey;
      // background: #F30;
    }

    p {
      margin: 0;
      font-size: 1.25rem;
      font-weight: 700;
      color: $grey;
    }
  }

  &__cells {
    padding-left: 30px;
  }
}

.creditCardForm__form {
  .register__form {
    padding-bottom: 0;
  }
  .cardExamples {
    padding: 0.5rem;
  }
}
