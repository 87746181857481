$base-transition: all 0.3s ease-out;

//Text
$textColor: #151625;
$black: #000000;
$grey2: #222222;
$grey3: #333333;
$grey5: #555555;
$greyC: #cccccc;
$grey64: #646464;
$greyAD: #adadad;
$greyEa: #eaeaea;
$white: #ffffff;
$navy: #2b3866;
$teal: #24c0ac;
$tealLight: #eafff6;
$blue: #47aceb;
$grey: #6f7f9f;
$mutedRed: #ea6b6b;
$otherGrey: #f5f5f5;
$lightGrey: #dfe6f4;
$backgroundGrey: #F7F9FB;
$lightBlue: #F7FCFF;

$orange: #ffab00;
$warning: #ffc800;
$completed: #00cc74;
$yellow: #ffc800;
$green: #00cc74;
$danger: #d0302f;
$purple: #be64ea;

$switch_width: 40px;
$switch_height: 22px;
$switch_toggle: 18px;

$right_nav_width: 54px;
$add_payment_plan_mobile_bp: 475px;
