.trialTag {
  padding: 4px 10px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 600;
  letter-spacing: 2px;
  background: $blue;
  color: #fff;
  text-transform: uppercase;
  border-radius: 3px;
  margin-right: 1rem;

  &--whiteNavy {
    background: #fff;
    color: $navy;
  }
}

.trial {
  display: none;
  width: 100%;
  padding: 2px 4px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 600;
  letter-spacing: 2px;
  background: $blue;
  color: #fff;
  text-transform: uppercase;

  &--whiteNavy {
    background: #fff;
    color: $navy;
  }
}

body.template-finances {
  background: #fafafa;

  #root div.main {
    .layout {
      &__info {
        @include flex;
        align-items: flex-start;
        max-width: 100%;
        margin: 0;
        padding: 1rem;
        h3 {
          color: $navy;
        }

        &__left {
          width: 65%;
        }
      }
    }
  }
}

body.template-finances {
  .invoiceAddLayout {
    background: transparent;
  }
  .layout--invoicesAdd {
    background: transparent;
    max-width: 100%;
    @include unicorn;
    align-items: flex-start;
    padding-top: 3rem;
  }
}

.accountBalanceTile {
  position: absolute;
  top: 1rem; right: 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 240px;
  border: 2px solid $lightGrey;
  background: #fafafa;
  border-radius: 5px;
  padding: 0.5rem;

  button {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 0;
    border: 0;
    background: transparent;
    color: #ccc;
    cursor: pointer;

    &:hover {
      color: #999;
    }
  }

  h5 {
    margin: 0 0 0.25rem;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $grey;
  }

  &>strong {
    margin: 0 0 0.25rem;
    display: inline-block;
    font-size: 2rem;
    color: $navy;
  }

  p {
    margin: 0 !important;
    font-size: 0.85rem !important;
  }

  &--trial {
    .trial {
      display: block;
    }

    &>strong {
      text-decoration: line-through;
    }
  }
}

.backToSettlements {
  margin-top: 2rem;
  padding: 0;
  border: 0;
  background: transparent;
  color: $grey;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.cardExamples {
  margin-bottom: 1rem;
  border: 1px solid $lightGrey;
  padding: 1rem;
  display: block;
  font-size: 0.875rem;
  color: #6f7f9f;
  background: #fff;

  p {
    margin-top: 0;
  }

  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.cardOnFile {
  @include flex;
  align-items: stretch;
  border: 1px solid $lightGrey;
  border-radius: 10px;
  min-height: 200px;
  background: #fafafa;
  padding: 2rem;

  &__promo {
    width: 68%;
    @include unicorn;
    flex-direction: column;
    border-right: 1px solid $lightGrey;
    padding: 0 2rem 0 0;

    h3 {
      margin: 0;
      font-size: 2rem;
      color: $grey;
    }
    h2 {
      margin: 0.5rem 0;
      font-size: 3.5rem;
      line-height: 3.5rem;
      color: $navy;
      display: flex;
      align-items: center;
      small {
        display: inline-block;
        margin-left: 0.5rem;
        font-size: 1rem;
        color: $black;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        margin-bottom: 0.25rem;
        text-align: center;
        font-size: 0.85rem;
        color: $grey;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  &__getStarted {
    @include unicorn;
    flex-direction: column;
    width: 30%;
    padding: 0 0 0 2rem;
    p {
      margin: 0 1rem rem;
      font-size: 0.85rem;
      color: $grey;
      text-align: center;
    }
  }
}

.financePod {
  position: relative;
  z-index: 1;
  margin: 0 0 0.5rem;
  padding: 0.5rem;
  border: 2px solid #dfe6f4;
  background: #fff;
  border-radius: 5px;
  // max-width: 600px;
  // cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background: #fafafa;
  }

  &__container {
    margin-top: 0.25rem;
    h5 {
      margin: 0 0 0.25rem;
      @include flex;
    }

    &--section {
      margin-top: 1.5rem;
      padding-top: 1.5rem;
      border-top: 2px solid $lightGrey;
    }

    &:first-child {
      margin-top: 0;
      padding-top: 0;
      border-top: 0;
    }
  }

  &__top {
    @include flex;
  }

  &__bottom {
    margin-top: 0.5rem;
  }

  &__left {
    color: $grey;
    h3 {
      margin: 0 0 0.25rem;
      padding: 0;
      font-size: 1.25rem;
      line-height: 1.25rem;
    }

    p {
      margin: 0;
      font-size: 0.85rem;
      line-height: 0.85rem;

      strong {
        color: #000;
      }
    }
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > span {
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }

    &--items {
      width: 50px;
      font-size: 1.5rem;
      text-align: right;
      color: $navy;
    }
    &--total {
      width: 120px;
      font-size: 1.25rem;
      text-align: right;
    }
  }

  &__case {
    font-size: 0.75rem;
    &--sale {
      background: $blue;
      color: #fff;
    }
    &--paymentPage {
      background: $orange;
      color: #fff;
    }
    &--paymentPlan {
      background: $purple;
      color: #fff;
    }
    &--refund {
      background: $danger;
      color: #fff;
    }
  }
}

.payoutNote {
  margin: 1rem;
  background: #fafafa;
  border: 2px solid $lightGrey;
  padding: 1rem;
  border-radius: 10px;

  p {
    margin: 0;
    font-size: 0.825rem;
  }
}

h5.finance_transactions--header {
  margin-top: 1rem;
}

.finance_transactions {
  padding: 0;
  border-top: 0;
  border: 2px solid #dfe6f4;
  background: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  max-width: 600px;

  &__transaction {
    @include flex;
    border-bottom: 2px solid #dfe6f4;
    padding: 0.25rem;

    padding: 0.5rem;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }

    &__left {
      h4,
      p {
        margin: 0;
      }
    }

    &__right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button,
      a {
        margin-left: 1rem;
      }

      .amounts {
        text-align: right;
      }
    }
  }
}

.settlementsFilter {
  margin-bottom: 2rem;
  &__right {
    @include flex;
    justify-content: flex-end;
    width: 50%;
    .inputRow__block {
      width: 100%;
    }
    .flyout__container {
      padding: 0 0.25rem 0 0.75rem;
    }
  }
}

.finances {
  &__filter {
    display: flex;
    border: 1px solid $lightGrey;
    height: 28px;
    border-radius: 4px;
    min-width: 400px;
    overflow: hidden;

    &__container {
      padding: 0 1rem;
      @include flex;
    }

    button {
      display: inline-block;
      width: calc(100% / 4);
      border: 0;
      background: transparent;
      border-right: 1px solid $lightGrey;
      white-space: nowrap;
      padding: 4px 10px;
      background: #fafafa;
      cursor: pointer;
      font-weight: 600;
      color: $grey;

      &:hover {
        background: #efefef;
      }

      &.active {
        background: $navy;
        color: #fff;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }
  &__section {
    padding: 1rem;
    border-bottom: 1px solid #dfe6f4;

    h4 {
      margin: 0 0 0.5rem;
      @include flex;
      color: $grey;
      span {
        text-transform: uppercase;
        letter-spacing: 2px;
      }
      strong {
        display: inline-block;
        color: #000;
        margin-left: 1rem;
      }
    }

    &__noResults {
      font-size: 1.25rem;
      color: $grey;
      text-align: center;
    }
  }
  &__charts {
    margin-bottom: 1rem;
    & > div {
      width: 100%;
    }
    &__item {
      border: 1px solid $lightGrey;
      border-radius: 10px;
      padding: 0.5rem;
    }
    &__tiles {
      @include flex;
      flex-wrap: wrap;
      &__tile {
        width: 32%;
        border: 1px solid #dfe6f4;
        border-radius: 10px;
        text-align: center;
        overflow: hidden;
        margin-bottom: 1rem;
        label {
          display: block;
          background: $grey;
          font-size: 0.75rem;
          text-transform: uppercase;
          color: #fff;
          padding: 3px 0;
          font-weight: 700;
        }
        strong {
          display: block;
          padding: 0.5rem;
          font-size: 2rem;
          color: $grey;
        }
      }
    }
  }
}

.noResults {
  margin: 1rem 0 0;
  font-size: 1.25rem;
  color: $grey;
}

.invoices {
  &__section {
    padding: 1rem;
    border-bottom: 1px solid $lightGrey;
    &:last-child {
      border-bottom: 0;
    }

    & > h4 {
      margin: 0 0 0.5rem;
      @include flex;
      color: $grey;
      span {
        text-transform: uppercase;
        letter-spacing: 2px;
      }
      strong {
        display: inline-block;
        color: #000;
        margin-left: 1rem;
      }
    }

    &--top {
      @include flex;
      align-items: flex-start;
      & > div {
        width: 49%;
      }
    }

    &__address {
      p {
        font-size: 1.25rem;
        line-height: 1.5rem;
        color: $grey;
      }
    }

    &__card {
      position: relative;

      button {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 10;
        padding: 0;
        border: 0;
        background: transparent;
        color: #999;
        cursor: pointer;

        &:hover {
          color: #ccc;
        }

        i {
          font-size: 1rem;
        }
      }
    }

    h4 {
      margin: 0 0 0.25rem;
      // text-transform: uppercase;
      // letter-spacing: 2px;
      color: $navy;
    }

    .paymentForm {
      border: 2px solid #dfe6f4;
      padding: 1rem;
      background: #fafafa;
      border-radius: 10px;

      &__actions {
        button {
          margin-right: 1rem;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.invoice_snapshot {
  z-index: 1;
  padding: 0;
  border-top: 0;
  border: 2px solid #dfe6f4;
  background: #fff;
  border-radius: 5px;

  @include flex;

  background: $navy;
  color: #fff;

  .invoicePod {
    margin-right: 1rem;
    padding: 0.5rem;
    text-align: left;
    height: 58px;
    p {
      margin: 0 0 0.25rem;
      font-size: 0.65rem;
      line-height: 0.65rem;
      font-weight: 300;
      text-transform: uppercase;
    }
    strong {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: .5rem;
    // & > button,
    // .flyout__container > button {
    //   display: inline-block;
    //   border: 0;
    //   background: transparent;
    //   border-left: 2px solid #dfe6f4;
    //   height: 58px;
    //   cursor: pointer;
    //   color: #fff !important;
    //   font-size: 2rem;

    //   &:hover {
    //     background: $grey !important;
    //   }
    // }
    .tags {
      margin-bottom: 0;
      .tag::after {
        display: none;
      }
    }
  }

  &__container {
    min-height: 58px;
    margin-bottom: .5rem;

    .invoices__container {
      position: relative;
      border: 2px solid $lightGrey;
      border-top: 0;
      border-radius: 4px;
      margin: 0 1rem;
      transition: $base-transition;

      a.butt {
        line-height: 32px;
        font-size: 0.85rem;
        text-decoration: none;
        padding: 0 0.25rem;
      }
    }

    // &--on {
    //   position: relative;
    //   overflow: hidden;
    //   .invoice_snapshot {
    //     position: relative;
    //   }
    //   .invoices__container {
    //     margin-top: 0;
    //   }
    // }
  }
}

.settlements {
  &__container {
    .register__form {
      margin: 0;
      max-width: 100%;
      width: 100%;
    }
    .finance_transactions {
      max-width: 100%;
    }
  }
  &__buttons {
    @include flex;
    margin-bottom: 1.5rem;
    padding: 0 1rem;
    & > button {
      width: 49.5%;
      font-size: 1rem;

      &.active {
        position: relative;
        &:after {
          top: 100%;
          left: 50%;
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-top-color: $navy;
          border-width: 16px;
          margin-left: -16px;
        }
      }
    }
  }
}

.layout--settlements {
  p.tiny {
    padding: 0 1rem;
  }
  .settlements__container {
    padding: 0 1rem 2rem;
  }
}

.layout--search {
  max-width: 100%;
}

.trialBanner {
  @include flex;
  display: none;
  @include bp(Mama) {
    display: flex;
  }
  border-radius: 5px;
  background: $blue;
  padding: 0.5rem;
  margin: 0 1rem 1rem;
  &__left {
    color: #fff;
    h4 {
      margin: 0;
    }
    p {
      margin: 0;
      font-size: 0.85rem;
    }
  }
  &__right {
    @include flex;
    justify-content: flex-end;

    &__date {
      min-width: 60px;
      @include unicorn;
      flex-direction: column;
      background: #fff;
      margin-left: 0.5rem;
      border-radius: 5px;
      padding: 0.5rem 0;
      color: $blue;
      p {
        margin: 0;
        font-size: 0.75rem;
        text-transform: uppercase;
      }
      strong {
        font-size: 1.5rem;
      }
    }
  }
}

.transactions__container {
  padding: 0 1rem;

  .noResults {
    text-align: center;
    padding: 2rem 0;
  }
}

.finance_transction {
  @include flex;
  margin-bottom: 0.5rem;
  &:last-child {
    margin-bottom: 0;
  }

  &__paymentPagePayment {
    @include pod;
  }

  &__cardEnding {
    font-size: 0.75rem;
    margin-left: 0.25rem;
  }

  &__group {
    padding: 1rem 0;
    // border-top: 1px solid $lightGrey;
    border-bottom: 2px solid $lightGrey;

    header {
      @include flex;
      margin-bottom: 1rem;
      h4 {
        margin: 0 0 0.25rem;
      }
      p {
        margin: 0;
        font-size: 0.75rem;
      }

      button {
        margin-right: 0.5rem;
        height: 30px;
        &:last-child {
          margin-right: 0;
        }
      }
      a {
        height: auto;
        padding: 5px 6px 6px;
        text-decoration: none;
        font-size: 0.85rem;
      }
    }
  }

  &__left {
    h4 {
      margin: 0;
    }
    p {
      margin: 0;
      @include flex;
      justify-content: flex-start;
      font-size: .825rem;
    }
    .tags {
      margin: 0 0 4px 0.5rem;
    }
  }
  &__right {
    @include flex;
    justify-content: flex-end;

    p {
      margin: 0;
      font-size: 0.85rem;
    }
  }
}

.refund_transction {
  @include flex;
  // margin-bottom: .5rem;
  padding: 0.5rem;
  border-bottom: 1px solid $lightGrey;
  background: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  &__group {
    padding: 1rem 0;
    border-bottom: 2px solid $lightGrey;

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0.5rem;
    }

    &__section {
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }

    h5 {
      margin: 0 0 0.3rem;
      font-size: 0.85rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $navy;
    }

    header {
      @include flex;
      margin-bottom: 1rem;
      h4 {
        margin: 0 0 0.25rem;
      }
      p {
        margin: 0;
        font-size: 0.75rem;
      }

      button {
        margin-right: 0.5rem;
        height: 30px;
        &:last-child {
          margin-right: 0;
        }
      }
      a {
        height: auto;
        padding: 5px 6px 6px;
        text-decoration: none;
        font-size: 0.85rem;
      }
    }

    &--noTransactions {
      header {
        margin: 0;
      }
    }
  }

  &__left {
    // @include flex;
    // justify-content: flex-start;

    // .tags {
    //   margin: 0 0 4px .5rem;
    // }
  }
  &__right {
    p {
      margin: 0;
      font-size: 0.85rem;
      text-align: right;
    }
  }
}

.radCalendar {
  display: inline-block;
  border: 1px solid $lightGrey;
  background: #EFEFEF;
  
  &__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__options {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    .dateStr {
      margin-left: .5rem;
      font-size: .75rem;
    }

    .butt--calReset {
      margin-left: 0.5rem;
      padding: 0;
      height: auto;
    }
  }

  .react-datepicker-popper {
    z-index: 10;
  }
}

.radCalendar__button,
.calendarButton {
  display: inline-block;
  padding: 0.5rem;
  width: 40px;
  border: 0;
  background: transparent;
  border-right: 1px solid $lightGrey;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25rem;
  color: $grey;
  cursor: pointer;

  &:last-child {
    border-right: 0;
  }

  &:hover {
    background: $grey;
    color: #fff;
  }

  &.active {
    background: $navy;
    color: #fff;
  }
}

.payments__container {
  padding: 0 1rem;
  .noResults {
    padding: 2rem;
    text-align: center;

    a {
      font-weight: 700;
      color: $navy;
      text-decoration: none;
      &:hover {
        color: $teal;
      }
    }
  }

  &__filters {
    @include flex;
    margin-bottom: 1rem;

    a.butt {
      font-size: 0.85rem;
      text-decoration: none;
      padding: 7px 10px;
    }

    &__left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: .5rem;
      .defendantSearch__form {
        margin: 0;
        min-width: 240px;
      }
      .switchContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: .5rem;
        label {
          font-size: .75rem;
        }
      }
    }
  }

  .paymentPod {
    &__left {
      p.error {
        margin-top: .25rem;
      }
    }
    strong {
      font-weight: 300;
    }
    p {
      font-size: 0.85rem;
      &.paymentPod__defendantName {
        font-size: 1.25rem;
        margin-bottom: 0.25rem;
        font-weight: 600;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
          margin-left: .25rem;
        }
        .tags {
          margin: 0 0 0 .25rem;
        }
      }
    }
  }

  &__defendant {
    @include pod;
    &__left {
    }
    &__right {
    }
  }

  header {
    margin-top: 3rem;
  }

  h5 {
    margin: 0 0 0.5rem;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $navy;
  }

  &__searchResults {
    h5 {
      margin-top: 1.5rem;
    }
  }
}

.refunds__container {
  padding: 0 1rem;

  & > h4 {
    margin: 0 0 1rem;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $navy;
  }

  .refundPod {
    @include pod;

    &__left {
      h4 {
        strong {
          font-size: 0.7rem;
        }
      }
      p {
        margin-top: 5px;
        font-size: 0.75rem;
      }
    }
    &__right {
      .amount {
        color: $danger;
      }
      button {
        height: auto;
        padding: 6px;
      }
      a {
        height: auto;
        padding: 5px 6px 6px;
        text-decoration: none;
        font-size: 0.85rem;
      }
    }
  }
}

.refunder {
  @include flex;
  border: 1px solid $lightGrey;
  border-radius: 10px;
  background: $grey;
  padding: 1rem;
  color: #fff;

  &__container {
    padding: 0 1rem 1rem;
  }

  &__left {
    h4 {
      margin: 0;
    }
    p {
      margin: 0;
      font-size: 0.85rem;
    }
  }
  &__right {
    button {
      min-width: 100px;
    }
  }

  &__adder {
    margin-bottom: 3rem;
    padding: 0 1rem;
    &__inner {
      border: 2px solid $lightGrey;
      border-top: 0;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      min-height: 50px;
      background: #efefef;

      form {
        padding: 0.5rem;
        display: block;
        position: relative;
        input[type="search"] {
          @include input;
        }
        button[type="submit"] {
          position: absolute;
          top: 12px;
          right: 12px;
          width: 30px;
          height: 30px;
          font-size: 1rem;
          text-align: center;
        }
      }
    }
    &__content {
      padding: 0 0.5rem 0.5rem;
      p {
        margin: 0;
      }
      .finance_transction__group {
        padding: 1rem;
        border: 2px solid $lightGrey;
        background: #fafafa;
        border-radius: 5px;
        margin-bottom: 0.5rem;

        .finance_transction__left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .tags {
          margin: 0 0 0 0.25rem;
        }
      }
      &--found {
        .finance_transction__group {
          margin-bottom: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
    &__refundIt {
      @include flex;
      background: #fafafa;
      margin-bottom: 0.5rem;
      padding: 1rem;
      border: 2px solid $lightGrey;
      border-top: 0;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      &--vertical {
        flex-direction: column;
        // margin-bottom: 0;
      }

      &__top {
        @include flex;
        width: 100%;
      }

      &__btm {
        width: 100%;
        margin-top: 1rem;
        padding-top: 1rem;
        border-top: 1px solid $lightGrey;

        &--partialStep {
          border-bottom: 1px solid $lightGrey;
          margin-bottom: 1rem;
          padding-bottom: 1rem;
        }
      }

      &__left {
        h4,
        p {
          margin: 0;
        }
        p {
          margin-top: 0.25rem;
          font-size: 0.85rem;
        }
        .refundDifferenceError {
          strong {
            color: $danger;
          }
        }
      }

      &__right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        form {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0;
          button {
            margin-right: 0.5rem;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        input {
          @include input;
          margin-right: 0.5rem;
        }
        .butt--confirmButton {
          margin-right: 0.5rem;
        }

        .verifyButton {
          margin-right: 0.5rem;
        }

        .refundTotal {
          color: $grey;
          margin-right: 1rem;
          strong {
            font-size: 1rem;
            color: #000;
          }
        }

        &--yesNo {
          button {
            margin-left: 0.5rem;
            min-width: 50px;
          }
        }
      }
    }
  }
}

.partialRefund__row {
  @include flex;
  width: 100%;
  margin-bottom: 0.5rem;
  &:last-child {
    margin-bottom: 0;
  }
  &__left {
  }
  &__right {
    position: relative;
    input {
      @include input;
    }
    button.filler {
      position: absolute;
      top: 50%;
      right: 4px;
      z-index: 2;
      transform: translateY(-50%);
      height: 30px;
    }

    .resetButton {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      z-index: 1;
      padding: 0;
      border: 0;
      background: transparent;
      color: $grey;
      &:hover {
        color: $navy;
      }
    }
  }
}

form.defendantSearch__form {
  // padding: .5rem;
  display: block;
  position: relative;
  margin-bottom: 1rem;
  input[type="search"] {
    @include input;
  }
  button[type="submit"] {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 30px;
    height: 30px;
    font-size: 1rem;
    text-align: center;
  }
}

.settlementFlow {
  &__row {
    @include flex;
    align-items: stretch;
    &__pod {
      padding: 0.75rem;
      width: 30%;
      border: 2px solid $lightGrey;
      border-radius: 10px;
      background: #fafafa;

      p {
        margin: 0;
        font-size: 0.825rem;
        strong {
        }
      }

      &--batch {
        @include unicorn;
        .batchTotals {
          width: 100%;
          @include flex;
          text-align: center;
          & > div {
            width: 50%;
          }
          label {
            display: block;
            text-transform: uppercase;
            font-size: 0.7rem;
            letter-spacing: 1px;
          }
          strong {
            display: block;
            font-size: 3rem;
            font-weight: 700;
            color: $grey;
          }
        }
        p {
          margin-bottom: 1rem;
          text-align: center;
          font-size: 1.1rem;
          font-weight: 700;
          color: $grey;
        }
      }
    }
  }
}
