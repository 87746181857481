.template-forfeitures {
  #root div.main > div {
    .layout {
      &__info {
        padding: 1.5rem 1rem;
      }
    }
  }
  div.main {
    & > div {
      background: #EFEFEF;
      .layout__info {
        max-width: 100%;
        padding-right: 30%;
      }
      .layout__info, .mainSearch {
        background: #FFF;
      }
    }
  }
}

.agencyForfeitures {
  padding: .5rem .5rem 6rem;
  &__container {
    .graph {
      background: #FFF;
      padding: 1rem 0 0;
      border-radius: 10px;
      border: 2px solid $lightGrey;
      &__container {
        padding: .5rem;
      }
    }
  }
  &__filterBar {
    @include flex;
    padding: 0 1rem;
    height: 50px;
    border-top: 1px solid $lightGrey;
    border-bottom: 1px solid $lightGrey;
    background: #FAFAFA;
    &__left {
      @include flex;
      justify-content: flex-start;
      .days {
        margin-left: 1rem;
        border-left: 1px solid $lightGrey;
        padding-left: 1rem;
        .inputRow__block {
          @include flex;
          margin: 0;
          label {
            margin: 0 .25rem 0 0;
          }
          input {
            max-width: 5rem;
            text-align: center;
          }
        }
      }
    }
  }

  &__filters {
    margin-bottom: .5rem;
    label {
      margin-right: 0.25rem;
      font-size: .75rem;
    }
    & > div {
      margin-right: .5rem;
      cursor: pointer;
    }
  }

  .forfeitures__pod {
    @include pod;
    &__left {
      h4 {
        margin-bottom: .25rem;
      }
      p {
        @include flex;
        justify-content: flex-start;
        margin-bottom: .25rem;
        font-size: .75rem;
        span {
          @include flex;
          justify-content: flex-start;
        }
        i {
          margin-right: 3px;
          color: $grey;
        }
      }
    }
    &__right {
      .dateTile {
        display: block;
        padding: 8px 0;
        background: $navy;
        border-radius: 10px;
        color: #FFF;
        width: 180px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
      }
      .tile {
        max-width: 150px;
      }
    }

    &__actions {
      @include flex;
      justify-content: flex-start;
      padding: .5rem;
      border-bottom: 1px solid $lightGrey;
      background: $grey;
      a {
        @include flex;
        margin-right: .5rem;
        padding: 4px 8px;
        border: 0;
        background: $teal;
        border-radius: 3px;
        font-size: .75rem;
        color: #FFF;
        cursor: pointer;
        text-decoration: none;
        &:hover {
          background: #FFF;
          color: $teal;
        }
        &.blue {
          background: $blue;
          &:hover {
            background: #FFF;
            color: $blue;
          }
        }
        i {
          margin-right: 8px;
        }
      }
    }
  }
}

.forfeiture {
  &__container {
    padding: .5rem;
    padding-bottom: 0;
    background: #EFEFEF;
  }

  .backingTag {
    margin: 0;
  }

  &__top {
    @include pod;
    margin: 0;

    &__left {
      @include flex;
      justify-content: flex-start;
      & > i {
        margin-right: .5rem;
        font-size: 2.5rem;
        color: $grey;
      }
      h4 {
        margin-bottom: .25rem;
      }
      p {
        @include flex;
        justify-content: flex-start;
        font-size: .75rem;
        span {
          @include flex;
          justify-content: flex-start;
          margin-right: 1rem;
          i {
            margin-right: 3px;
            color: $grey;
          }
          a {
            color: $grey;
            text-decoration: none;
            &:hover {
              color: $blue;
              text-decoration: underline;
            }
          }
        }
      }
    }
  
    &__right {
      label.status {
        border: 4px solid $teal;
        font-size: 1.25rem;
        font-weight: 700;
        background: $teal;
        color: #FFF;
        text-transform: uppercase;
        border-radius: 10px;
        height: 40px;

        &--red {
          border-color: $danger;
          background: $danger;
        }
        &--orange {
          border-color: $orange;
          background: $orange;
        }
        &--blue {
          border-color: $blue;
          background: $blue;
        }
      }
      button {
        border: 0;
        background: transparent;
        padding: 0;
        font-size: 1rem;
        color: $grey;
        &:hover {
          color: $navy;
        }
      }
    }

    &__info {
      margin-top: .25rem !important;
      span {
        color: $grey;
      }
    }
  }

  &__btm {
    margin: 0 1rem;
    padding: .5rem;
    border: 2px solid $lightGrey;
    border-top: 0;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: #FFF;

    .inputRow__block {
      margin-bottom: 0;
    }

    .cb {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      p {
        margin: 0;
        font-size: .75rem;
      }
      &__checkbox {
        position: relative;
        padding-right: 0;
        input[type="checkbox"] + label {
          &:before {
            position: absolute;
            top: 50%; left: 0;
            transform: translateY(-50%);
            border-width: 1px;
            width: 14px;
            height: 14px;
          }
          i {
            top: 4px; left: 3px; right: auto;
            transform: none;
            font-size: .65rem;
          }
        }
      }
    }
  }

  &__pod {
    margin-bottom: .5rem;
    &__top {
      @include pod;
      margin-bottom: 0;
      background: #FAFAFA;
      &__left {
        h4 strong {
          color: $teal;
        }
        p {
          @include flex;
          justify-content: flex-start;
          font-size: .75rem;
          span {
            @include flex;
            justify-content: flex-start;
            margin-right: 1rem;
            i {
              margin-right: 3px;
              color: $grey;
            }
            a {
              color: $grey;
              text-decoration: none;
              &:hover {
                color: $blue;
                text-decoration: underline;
              }
            }
          }
        }
      }
      &__right {
        .tile {
          justify-content: center;
          text-align: center;
        }
        button {
          border: 0;
          background: transparent;
          padding: 0;
          font-size: 1.5rem;
          color: $grey;
          cursor: pointer;
          &:hover {
            color: $navy;
          }
        }
      }
    }

    &__btm {
      margin: 0 1rem;
      border: 2px solid $lightGrey;
      border-radius: 5px;
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background: #FFF;
    }

    &__download {
      @include flex;
      // margin-bottom: .25rem;
      padding: .5rem;
      border-bottom: 1px solid $lightGrey;
      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
      }

      button {
        padding: 0;
        border: 0;
        background: transparent;
        font-size: 1rem;
        color: $grey;
        &:hover {
          color: $navy;
        }
      }
    }
  }

  &__form {
    & > div {
      margin-bottom: .5rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .react-datepicker-wrapper {
      width: 100%;
    }
    &__add {
      width: 100%;
      background: #FAFAFA;
      border: 2px solid $lightGrey;
      padding: .25rem 0;
      font-size: 2rem;
      color: $grey;
      cursor: pointer;
      border-radius: 10px;
      outline: none;
      &:hover {
        color: $navy;
        background: #EFEFEF;
        border-color: $grey;
      }
    }
    &__fields {
      @include flex;
      align-items: stretch;
      margin-top: .5rem;
      
      padding: .5rem;
      border: 2px solid $lightGrey;
      background: #EFEFEF;
      border-radius: 5px;

      &__left {
        width: 72%;
        & > div {
          margin-bottom: .5rem;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &__right {
        width: 25%;
        .uploader {
          margin: 0;
          height: 100%;
          overflow: hidden;
        }
      }
    }
    &__actions {
      @include flex;
      & > button {
        width: 49.5%;
      }
    }
    &__split {
      @include flex;
      & > div.inputRow__block {
        margin: 0;
        width: 49%;
      }
      &--3up {
        & > div.inputRow__block {
          margin: 0;
          width: 31%;
        }
      }
    }
    &__dueDate {
      font-size: .825rem;
      font-weight: 700;
      color: $blue;
      p {
        margin: .5rem 0 0;
      }
    }
  }
}