.mask--charges {
  background: rgba(255,255,255,.7);
  .mask__container {
    border-color: $grey;
    width: 100%;
    max-width: 764px;
    border-radius: 1rem;
  }
  .form-row {
    @include flex;
    margin-bottom: .5rem;
    & > div {
      margin-bottom: 0;
      &:nth-child(1) { width: 130px; }
      &:nth-child(2) { width: 270px; }
      &:nth-child(3) { width: 120px; }
      &:nth-child(4) { width: 120px; }
      &:nth-child(5) { 
        width: 30px;
        button {
          margin-top: 1.4rem;
          padding: 0;
          background: transparent;
          border: 0;
          font-size: 1.25rem;
          color: $grey;
          &:hover {
            color: $navy;
          }
        }
      }
      label {
        text-align: left;
      }
    }
  }
}

.charges {
  margin-bottom: 1rem;
  padding: .75rem 1rem;
  border: 2px solid $lightGrey;
  border-radius: 5px;
  background: #FAFAFA;
  h5 {
    @include flex;
    button {
      display: none;
      padding: 0;
      background: transparent;
      border: 0;
      font-size: 1.25rem;
      color: $grey;
      &:hover {
        color: $navy;
      }
      @include bp(Mama) {
        display: inline;
      }
    }
  }
  &__list {
    margin-bottom: 1rem;
    @include bp(Mama) {
      margin-bottom: 0;
    }
    &__row {
      @include flex;
      padding: .25rem 0;
      @include bp(Mama) {
        display: block;
      }
      & > div {
        &:first-child {
          span {
            display: inline-block;
            margin-right: 1rem;
            font-size: .85rem;
            white-space: nowrap;
            &.charge--warrant { width: 100px;  text-align: left; }
            &.charge--charge { width: calc(100% - 270px); text-align: left }
            &.charge--fees { width: 60px; text-align: center; }
            &.charge--amount { width: 60px; margin-right: 0; text-align: center; }
          }
        }
        &:last-child {
          width: 50px;
          text-align: right;
          @include bp(Mama) {
            display: none;
          }
        }
      }
      button {
        margin-right: .5rem;
        border: 0;
        background: 0;
        padding: 0;
        color: $grey;
        font-size: 1rem;
        &:hover {
          color: $navy;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &__add {
    width: 100%;
    background: #FAFAFA;
    border: 2px solid $lightGrey;
    padding: .25rem 0;
    font-size: 2rem;
    color: $grey;
    cursor: pointer;
    border-radius: 10px;
    &:hover {
      color: $navy;
      background: #EFEFEF;
      border-color: $grey;
    }
    @include bp(Mama) {
      display: none;
    }
  }
}