.questions_viewer {
  padding: 0 1rem;
  &__container {

  }
  &__actions {
    @include flex;
    padding: 0 0 1rem 0;
    button {
      margin-left: .5rem;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  &__section {
    @include pod;
  }
  &__question {
    position: relative;
    margin-bottom: .5rem;
    padding: .25rem 0 .5rem 2rem;
    @include flex;
    border-bottom: 1px solid $lightGrey;
    &:last-child {
      border-bottom: 0;
    }
    .move {
      position: absolute;
      top: 50%; left: 0;
      transform: translateY(-50%);
      padding: 0;
      border: 0;
      background: transparent;
    }
    &__left {
      p {
        margin: 0;
      }
      .tags {
        margin: 0;
        .tag {
          padding-right: 5px;
          &:after {
            display: none;
          }
        }
      }
    }
    &__right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        margin-left: .5rem;
        &:first-child {
          margin-left: 0;
        }
        i {
          font-size: 1.25rem;
          line-height: 1.25rem;
        }
        &.butt--icon {
          width: 36px; height: 36px;
        }
      }
    }

    &--disabled {
      opacity: .5;
      &:hover {
        opacity: 1; 
      }
    }
  }
}
