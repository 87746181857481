.layout--userDocuments {
  .register__form__error, .register__form__success {
    padding: 0 !important;

    &__inner {
      border-radius: 0;
    }
  }
}

.userDocuments {
  margin: 0 auto;
  max-width: 600px;

  &__header {
    margin-bottom: 2rem;
    padding: 0 1rem;
    @include bp(Mama) {
      padding: 0;
    }

    button {
      margin-top: 1.25rem;
      border: 0;
      background: transparent;
      padding: 0;
      font-size: 1.25rem;
      color: #999;
      outline: none;

      i {
        margin-right: .5rem;
      }
    }
  }

  &__content {
    h3 {
      text-align: center;
      color: $navy;
    }
  } 

  .questionnaire__question {
    max-width: 732px;
    margin: 0 auto;
  }
}

.stepper {
  @include unicorn;

  div {
    position: relative;
    z-index: 1;
    @include unicorn;
    margin-right: .25rem;
    border: 1px solid #DFE6F4;
    background: #DFE6F4;
    width: 30px; height: 30px;
    border-radius: 50%;
    @include bp(Mama) {
      border: 2px solid #DFE6F4;
      margin-right: .5rem;
      width: 40px; height: 40px;
    }

    &.active {
      background: $navy;
      border-color: $navy;
    }
    
    &.complete {
      border-color: #24C0AC;
      background: #55FAC3;
    }

    &:last-child {
      margin-right: 0;
    }

    span {
      position: relative;
      z-index: 1;
      display: inline-block;
      font-size: .85rem;
      font-weight: 700;
      color: #FFF;
      @include bp(Mama) {
        font-size: 1.25rem;
      }
    }
  }

}

.filesArea {
  padding: 0 2rem;
  @include bp(Mama) {
    padding: 0;
  }
}

// .layout--userDocuments {
//   background: #EFEFEF;
// }

.layout--sign {
  background: #EFEFEF;
}

.powerSignature {
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }

  &__container {
    h5 {
      margin: 0 0 .5rem;
      padding-top: 1rem;
      font-size: .85rem;
      color: $grey;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }

  &__pod {
    @include innerPod;

    header {
      @include flex;
      margin-bottom: .5rem;
      h3 {
        margin: 0 0 .25rem;
        color: $teal;
      }
      .charges {
        margin: 0;
        font-size: .75rem; 
        border: 0;
        background: transparent;
        padding: 0;
        border-radius: 0;
      }
      .header__tiles {
        display: flex;
        justify-content: flex-end;

        .tile {
          width: 100px;
          margin-left: .5rem;

          &.tile--navy {
            border: 2px solid $navy;
            background: #FFF;
            label, strong {
              color: $navy;
            }
          }
          &.tile--teal {
            border: 2px solid $teal;
            background: #FFF;
            label, strong {
              color: $teal;
            }
          }
        }
      }
    }
  }

  &__documents {
    &__doc {
      @include flex;
      margin-bottom: .5rem;
      padding: .5rem;
      background: $grey;
      border-radius: 5px;
      color: #FFF;

      &:last-child {
        margin-bottom: 0;
      }

      & > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        & > i {
          margin-right: .5rem;
          font-size: 1.5rem;
        }
        
        p {
          margin: 0;
          font-size: 1rem;
          font-weight: 700;
        }
      }
      button {
        @include unicorn;
        width: 100px;
        i {
          margin-top: 2px;
          font-size: 1.25rem;
        }
      }

      &.signed {
        background: #FFF;
        border: 1px solid $grey;
        color: $grey;
        button {
          cursor: default;
          &:hover {
            background: $teal;
            color: #FFF;
          }
        }
      }
    }
  }
}

.signing__form {
  margin: 0 auto;
  max-width: 600px;

  .error {
    font-size: 1rem;
    padding: .5rem;
    border-radius: 4px;
  }

  #visualForm {
    display: none;
    height: calc(100vh - 57px);
    body.header--user & {
      height: calc(100vh - 90px);
    }
  }
  
  .documentSign {
    max-width: 600px;
    margin: 0 auto;

    &__container {
      padding-bottom: 4rem;
    }
  
    &__row {
      margin-bottom: .5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 2px solid #dfe6f4;
      padding: 1rem;
  
      h3, p {
        margin: 0;
      }
  
      h3 {
        font-size: .85rem;
        @include bp(Mama) {
          font-size: 1.2rem;
        }
      }
  
      p {
        margin-top: 5px;
        margin: 0;
        font-size: .75rem;
      }
    }
  }

  footer {
    padding: 1.5rem 0;
    text-align: center;
    
    button {
      max-width: 350px;
      width: 100%;
    }
  }

}

#visualForm {
  display: none;
  height: calc(100vh - 57px);
  margin-top: 57px;
  body.header--user & {
    height: calc(100vh - 90px);
    margin-top: 90px;
  }
  body.is_viewing_signature & {
    display: block;
  }
}

.layout--userDocuments {
  .split-row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include bp(Mama) {
      flex-direction: row;
    }
    & > div {
      width: 100%;
      @include bp(Mama) {
        width: 50%;
      }
      &:first-child {
        @include bp(Mama) {
          margin-left: 5px;
        }
      }
      &:last-child {
        @include bp(Mama) {
          margin-left: 5px;
        }
      }
    }

    &--1up {
      div.formBlock {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
      }
    }

    &--2up {
      div.formBlock {
        width: 100%;
        @include bp(Mama) {
          margin-right: 5px;
          width: calc(100% / 2);
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &--3up {
      div.formBlock {
        width: 100%;
        @include bp(Mama) {
          margin-right: 5px;
          width: calc(100% / 3);
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &--4up {
      div.formBlock {
        width: 100%;
        @include bp(Mama) {
          margin-right: 5px;
          width: calc(100% / 4);
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }

    &.form-actions {
      flex-direction: row-reverse;
      button {
        width: 48%;
      }
    }

  }
}

.layout--formBuilder {
  height: 100%;
  #root, .formBuilder {
    width: 100%; height: 100%;
    #visualForm {
      display: block;
      height: 100%;
      margin: 0;
    }
  }

  .formBuilder__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    width: 100%;
    border-bottom: 1px solid #CCC;

    span {
      margin-right: 1rem;
    }
    & > div {
      min-width: 400px;
    }
  }  
}

.layout--documentUploadForm {
  #root, .formBuilder {
    width: 100%; height: 100%;
    #visualForm {
      display: block;
    }
    .documentUpload {
      display: none;
    }
  }
  main {
    display: none;
  }
}