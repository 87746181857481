.layout--reporting {
  .layout__info__left {
    padding: 0 0 0 1rem;
  }
}

.reporting {

  &__section {
    margin: 1rem 0;
    padding: 0 1rem 1rem;
    border-bottom: 1px solid $lightGrey;

    & > div {
      h4 {
        margin: 0;
      }
    }
  }

  & > h5 {
    @include flex;
    padding: 0 1rem;
    button {
      border: 0;
      background: transparent;
      font-size: 1.5rem;
      padding: 0;
      color: $grey;
      cursor: pointer;

      &:hover {
        color: $navy;
      }
    }
  }

  &__adder {
    @include flex;
    padding: 0;
    padding-top: 5px;
    &__left {
      @include flex;
      width: calc(100% - 120px);
      & > div {
        width: 49.5%
      }
      &--3up {
        & > div {
          width: 32%
        }
      }
    }
    &__right {
      display: flex;
      justify-content: flex-end;
      width: 120px;
      button {
        height: 30px;
        margin-right: .25rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__row {
    @include flex;
    margin: 0 0 .25rem;
    padding: 0 0 .25rem;
    border-bottom: 1px solid $lightGrey;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
    &__left {
      @include flex;
      justify-content: flex-start;
      width: calc(100% - 120px);
      & > div {
        width: 49.5%
      }
      & > span {
        margin-right: .25rem;
      }
    }
    &__right {
      display: flex;
      justify-content: flex-end;
      width: 120px;
      button {
        border: 0;
        background: transparent;
        font-size: 1.25rem;
        color: $grey;
        margin-right: .25rem;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          color: $grey;
        }
        &:disabled {
          opacity: .2;
        }
      }
    }
  }

  button.add {
    width: 100%;
    background: #FAFAFA;
    border: 2px solid $lightGrey;
    padding: .5rem 0;
    font-size: 2rem;
    color: $grey;
    cursor: pointer;
    border-radius: 10px;
    &:hover {
      color: $navy;
    }
  }
}

.reportTile {
  position: relative;
  padding: 1rem 1rem 1rem 4rem;
  border: 2px solid $lightGrey;
  border-radius: 10px;
  background: #FAFAFA;
  cursor: pointer;

  i {
    position: absolute;
    top: 1rem; left: 1rem;
    font-size: 2rem;
    color: $grey;
  }

  h3 {
    margin: 0 0 .25rem;
    font-size: 1.1rem;
    font-weight: 700;
    color: $grey;
  }
  p {
    margin: 0;
    font-size: .825rem;
  }

  &:hover {
    border-color: $navy;
    background: #EFEFEF;
    i, h3 {
      color: $navy;
    }
  }
}

.reportingBuilder {
  .error {
    margin: 0 1rem 1rem;
  }
  &__tiles {
    @include flex;
    margin-top: 1rem;
  }
  &__template {
    margin-bottom: 1rem;
    padding: 0 1rem;
    border-bottom: 1px solid $lightGrey;
  }
  &__actions {
    text-align: right;
    padding: 0 1rem;
    button {
      margin-left: 1rem;
    }
  }
  &__filter {
    @include flex;
    margin-bottom: 1rem;
    padding: 0 1rem;
    border-bottom: 1px solid $lightGrey;
    button {
      margin-right: .25rem;
      &:last-child {
        margin-right: 0;
      }
    }
    & > div {
      &:first-child {
        width: calc(100% - 130px);
      }
      &:last-child {
        text-align: right;
        width: 120px;
      }
    }
  }
  &__query {
    margin-bottom: 1rem;
    padding: 0 1rem 1rem;
    border-bottom: 1px solid $lightGrey;
    &__quickFilters {
      margin-bottom: .5rem;
      button {
        margin-right: .5rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &__content {
      margin-bottom: 1rem;
      .pill {
        margin-right: .5rem;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &__meta {
    margin-bottom: 1rem;
    padding: 0 1rem;
    border-bottom: 1px solid $lightGrey;
    &__content {
      @include flex;
      align-items: flex-start;
      & > div {
        &:first-child {
          width: 65%;
        }
        &:last-child {
          width: 30%;
        }
      }
    }
  }
  &__fields {
    padding: 0 1rem;
    &__content {
      margin-bottom: 1rem;
    }
  }
}

.reportingTable {
  position: fixed;
  top: 57px; left: 0;
  width: 100%;
  min-height: calc(var(--vh, 100vh) - 57px);
  // padding: 0 1rem;
  background: #FFF;

  &__filter {
    position: absolute;
    top: 26px; right: 6px;
    background: #FFF;
    border: 2px solid $lightGrey;
    padding: 1rem;
    min-width: 200px;
    text-align: left;

    h5 {
      margin: 0 0 .25rem;
      color: $grey;
    }
  }

  &__export {
    @include flex;
    padding: .5rem;

    &__left {
      width: 50%;
    }
    &__right {
      width: 50%;
      text-align: right;
    }
    button {
      
    }
  }
  a {
    color: $teal;
    &:hover {
      color: $navy;
    }
  }
  &__table {
    height: calc(var(--vh, 100vh) - 57px - 52px);
    overflow-y: scroll;
    table {
      width: 100%;
      border-collapse: collapse;
    }
    table, th, td {
      border: 1px solid $lightGrey;
    }
    thead {
      tr {
        background: $grey;
        th {
          position: relative;
          color: #FFF;
          padding: .5rem;
          font-size: 1rem;
        }
      }
    }
    tbody {
      tr:nth-child(odd) {
        background: #FAFAFA;
      }
      td {
        white-space: nowrap;
      }    }
    td {
      padding: .5rem;
      font-size: 1rem;
      text-align: center;
    }
    .actions {
      a {
        display: inline-block;
        margin-right: .5rem;
        text-decoration: none;
        &:last-child {
          margin-right: 0;
        }
      }
      button {
        display: inline-block;
        padding: 0;
        background: transparent;
        border: 0;
        cursor: pointer;
        color: $teal;
        &:hover {
          color: $navy;
        }
      }
    }
  }
}