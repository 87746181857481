body.layout--learningCenter {
  #root main {
    padding-top: 57px;
    padding-bottom: 0;
  }

  .learningCenter {
    .welcome {
      position: absolute;
      top: 80px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 400px;
      padding: 3rem;
      border: 4px solid $navy;
      border-radius: 20px;

      p {
        margin: 0;
        text-align: center;
        &.big {
          margin: 0 0 1rem;
        }
      }
    }

    &__linksContainer {
      overflow-y: scroll;
      height: calc(100vh - 57px - 94px);
    }

    &__sidebar {
      position: fixed;
      top: 57px; left: 0;
      width: 600px;
      height: calc(100vh - 57px);
      border-right: 1px solid #dfe6f4;

      header {
        padding: 2rem;
        background: #efefef;
        border-bottom: 1px solid #dfe6f4;
      }

      h1 {
        margin: 0;
        text-align: center;
      }

      .linkGroup {
        padding: 0;
        margin-bottom: 1rem;
        &:first-child {
          padding-top: 1rem;
        }
        h5 {
          margin: 0 0 1rem;
          padding: 0 1rem;
          text-transform: uppercase;
        }
        button {
          position: relative;
          display: block;
          // margin-bottom: .75rem;
          width: 100%;
          padding: 0.5rem 3.5rem 0.5rem 2rem;
          background: transparent;
          border: 0;
          font-size: 1rem;
          cursor: pointer;
          text-align: left;
          color: $grey;

          &:last-child {
            margin-bottom: 0;
          }

          &:hover {
            background: #efefef;
          }

          span {
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            font-size: 0.7rem;
            font-weight: 700;
          }
        }
      }
    }

    &__content {
      display: flex;
      justify-content: center;
      align-items: stretch;
      min-height: calc(100vh - 57px);
      margin-left: 300px;

      &--welcome {
        position: relative;
        display: block;
      }

      &__video {
        padding: 2rem;
        width: 100%;

        header {
          h3 {
            margin: 0 0 1rem;
          }
        }
      }

      &__breadcrumb {
        margin-bottom: 2rem;

        button {
          padding: 0;
          border: 0;
          background: transparent;
          font-weight: 700;
          color: $navy;
          cursor: pointer;
          &::after {
            content: ">>";
            padding: 0 0.5rem;
            color: $navy;
          }
          &:hover {
            color: $black;
          }

          &:last-child {
            font-weight: 400;
            color: $grey;
            &::after {
              content: "";
              padding: 0;
            }
          }
        }
      }

      &__instructions {
        display: none;
        padding: 2rem;
        width: 40%;
        min-height: calc(100vh - 57px);
        background: #fafafa;
        border-left: 1px solid #dfe6f4;

        header {
          h3 {
            margin: 0 0 1rem;
          }
        }
      }
    }
  }
}

.videoContainer {
  position: relative;
  padding: 63.72% 0 0 0;
  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
