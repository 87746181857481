
.layout--offices {
  min-height: calc(var(--vh, 100vh) - 57px - 52px - 138px);
}

.template-offices {
  #root div.main > div {
    .layout {
      &__info {
        padding: 1.5rem 1rem;
      }
    }
  }
}
.offices {
  padding: 0 1rem 3rem;
  &__header {
    h5 {
      margin: 0 0 .5rem;
      padding-bottom: 4px;
      color: $grey;
      letter-spacing: 2px;
      text-transform: uppercase;
      border-bottom: 1px solid $grey;
    }
    .officeRules {

    }
    .officeViolations {
      padding: 0 1rem;
      h5 {
        color: $danger;
        border-bottom: 1px solid $danger;
      }
      p {
        color: $danger;
      }

      &--warnings {
        h5 {
          color: $orange;
          border-bottom: 1px solid $orange;
        }
        p {
          color: $orange;
        }
      }
    }
  }
  &__office {
    margin-bottom: 1rem;
    padding: 1rem;
    background: #EFEFEF;
    border: 2px solid $lightGrey;
    border-radius: 10px;

    &__title {
      @include flex;
      align-items: flex-start;
      margin-bottom: 1rem;
      &__left {
        h3 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 0 0 .25rem;
          color: $navy;
          
          button {
            margin-top: -2px;
            margin-left: .5rem;
            border: 0;
            background: transparent;
            padding: 0;
            color: $grey;
            &:hover {
              color: $navy;
            }
          }

          .tags {
            margin-left: .5rem;
          }
        }
        p {
          margin: 0;
          font-size: .85rem;
        }
      }
      &__right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    &__section {
      // margin-bottom: 1rem;

      &__top {
        padding: 1rem;
        border: 2px solid $lightGrey;
        background: #FFF;
        border-radius: 5px;
      }

      &__bottom {
        margin: 0;
        padding: 1rem;
        border: 2px solid $lightGrey;
        border-top: 0;
        background: #FAFAFA;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &__content {
        position: relative;
        // min-height: 80px;
        // padding-right: 4rem;
        button.add {
          position: absolute;
          top: 50%; right: 0;
          transform: translateY(-50%);
          background: #FAFAFA;
          border: 2px solid $lightGrey;
          padding: 1rem .5rem;
          font-size: 2rem;
          color: $grey;
          cursor: pointer;
          border-radius: 4px;
          &:hover {
            color: $navy;
          }
        }

        .officesContainer {
          .scrollableContainer__inner {
            display: flex;
            justify-content: flex-start;
            padding: .25rem 0 22px 0;
          }
        }

        .formTile {
          margin-right: .5rem;
        }
      }

      &__processor {

        &__actions {
          display: flex;
          justify-content: flex-end;
          button {
            margin-left: .5rem;
          }
        }
      }

      h5 {
        @include flex;
        margin: 0 0 .5rem;
        padding-bottom: 8px;
        letter-spacing: 2px;
        color: $grey;
        text-transform: uppercase;
        border-bottom: 1px solid $lightGrey;

        span {
          @include flex;
          justify-content: flex-start;
          i {
            margin-left: .25rem;
            font-size: 1rem;
            cursor: pointer;
          }
        }

        button {
          padding: 0;
          border: 0;
          background: transparent;

          i {
            color: $grey;
            font-size: 1.25rem;
          }
        }
      }

      .noResults {
        margin: 0;
        font-size: 1rem;
        color: $grey;
        text-align: left;
      }
    }
  }

  .offices__office__section--employees {
    .offices__office__section__content {
      padding-right: 0;
    }
    .employeesList {
      .checkIt input + label:after {
        top: 1px;
        left: 2px;
      }
      &__employee {
        @include flex;
        justify-content: flex-start;
        padding: .25rem;
        border-bottom: 1px solid $lightGrey;
        span {
          display: inline-block;
          margin-left: .5rem;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
  

  .offices__office__section--forms, .offices__office__section--locations {
    position: relative;
    .offices__office__section__top {
      position: relative;
      z-index: 1;
      transition: $base-transition;
    }
    .offices__office__section__bottom {
      position: absolute;
      left: 1rem; bottom: 0;
      z-index: 0;
      width: calc(100% - 2rem);
      transition: $base-transition;
    }

    &.offices__office__section--open {
      z-index: 20;
      .offices__office__section__top {
        margin-bottom: 106px;
      }
      .offices__office__section__bottom {
        bottom: -90px;
      }
    }
  }

  &__add {
    button {
      width: 100%;
      background: #FAFAFA;
      border: 2px solid $lightGrey;
      padding: 1rem 0;
      font-size: 3rem;
      color: $grey;
      cursor: pointer;
      border-radius: 10px;
      &:hover {
        color: $navy;
      }
    }
  }

  .tile {
    min-width: 90px;
    margin-left: .5rem;
    padding: .5rem;
    border: 1px solid $lightGrey;
    background: #FFF;
    border-radius: 4px;

    &:first-child {
      margin-left: 0;
    }

    label {
      display: block;
      font-size: .75rem;
    }
    strong {
      font-size: 1.5rem;
    }
  }

  .form__chooseForms {
    width: 100%;
    .inputRow__block {
      margin: 0;
    }
    // @include flex;
    // align-items: flex-end;

    // & > div {
    //   width: calc(100% - 94px);
    // }

    // button {
    //   width: 70px;
    // }
  }

  // .trashIt, .editIt {
  //   width: 36px; height: 36px;
  //   font-size: 1.25rem;
  //   margin-right: .5rem;
  // }

  .buttonEllipse {
    position: relative;
    z-index: 26;
    margin-left: 1rem;
    padding: 0;
    border: 0;
    background: transparent;
    color: $grey;
    font-size: 2rem;
    cursor: pointer;
    &:hover {
      color: $navy;
    }

    &__mask {
      position: fixed;
      top: 0; left: 0;
      width: 100%; height: 100vh;
      z-index: 24;
      background: rgba(255,255,255,0);
    }

    &__flyout {
      display: none;
      position: absolute;
      top: -.5rem; right: -.5rem;
      z-index: 25;
      width: 160px; min-height: 100px;
      padding: 2.75rem 0 0 0;
      border: 2px solid $lightGrey;
      border-radius: 4px;
      background: #FFF;

      button {
        display: block;
        padding: 3px .6rem;
        width: 100%;
        border: 0;
        background: transparent;
        font-size: .85rem;
        font-weight: 300;
        text-align: left;
        border-bottom: 2px solid $lightGrey;
        color: $grey;
        cursor: pointer;
        outline: none;

        &:disabled {
          opacity: .5;
        }

        &:hover {
          background: $teal;
          color: #FFF;

          &:disabled {
            background: transparent;
            color: $grey;
          }
        }

        &:first-child {
          border-top: 2px solid $lightGrey;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    &__container {
      position: relative;

      &--flyout {
        .buttonEllipse__flyout {
          display: block;
        }
      }
    }
  }

  .processorTile {
    border: 1px solid $lightGrey;
    background: $grey;
    width: 120px;
    padding: .5rem;
    border-radius: 5px;
    cursor: default;

    &:hover {
      background: $grey;
    }

    h3 {
      margin: 0;
      font-size: .85rem;
      text-align: center;
      color: #FFF;
    }

    &--active {
      background: $teal;
      border-color: $teal;

      &:hover {
        background: $teal;
      }
    }
  }

  .formTile {
    position: relative;
    @include unicorn;
    justify-content: flex-start;
    flex-direction: column;
    padding: 1rem 1rem .5rem;
    width: 100px;
    background: $grey;
    color: #FFF;
    text-align: center;
    border-radius: 4px;
    i {
      display: block;
      margin-bottom: .5rem;
      font-size: 3rem;
    }
    strong {
      display: block;
      font-size: .75rem;
      font-weight: 700;
    }
    button {
      cursor: pointer;
      position: absolute;
      top: -5px; right: -5px;
      background: #FFF;
      border: 0;
      padding: 0;
      margin: 0;
      border-radius: 50%;
      width: 22px; height: 22px;
      i {
        // margin-top: 3px;
        margin-bottom: 0;
        font-size: 1.25rem;
        color: $grey;
      }
    }
    &:hover {
      background: $navy;
      button {
        i {
          color: $danger;
        }
      }
    }
  }

  &__settingsForm {
    padding: 1rem;
    background: #FAFAFA;
    border: 2px solid $lightGrey;
    border-radius: 10px;
  }

  &__addForm {
    margin-top: 1rem;
    padding: 1rem;
    background: #EFEFEF;
    border: 2px solid $lightGrey;
    border-radius: 10px;
    &__row {
      &--address {
        // @include flex;
        // .inputRow__block {
        //   &:first-child {
        //     width: 72%;
        //   }
        //   &:last-child {
        //     width: 25%;
        //   }
        // }
      }
      &--phoneEmail {
        @include flex;
        .inputRow__block {
          width: 49.5%;
        }
      }
      &--location {
        @include flex;
        .inputRow__block {
          width: 49.5%;
        }
      }
    }
    .inputRow__block {

    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 120px;
        margin-right: .5rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

}

.processors {
  padding: 0 1rem;
  &__list {
    margin-top: 3rem;
    padding-bottom: 3rem;
  }
  .noResults {
    margin: 0;
    padding: 2rem 1rem;
    font-size: 1rem;
    color: $grey;
    text-align: center;
  }

  .processor__pod {
    @include pod;
    h3 {
      margin: 0;
      color: $grey;
    }
    p {
      margin: 0 0 .25rem;;
      font-size: .75rem;
    }
    button {
      min-width: 60px;
    }
  }
  
  .gatewayTile {
    padding: .25rem .5rem;
    background: $navy;
    border-radius: 2px;
    text-transform: uppercase;
    font-size: .85rem;
    color: #FFF;
  }
}

.layout--locations {
  .countiesList {
    padding: 0 1rem;
    .register__form__block.settings {
      padding: 0;
    }
    .register__form__actionsBar__actions {
      padding: 0;
    }
    .settingsHeader {
      margin-bottom: 3rem;
    }
  }
}

.layout--forms {
  .register__form__block--documents {
    padding: 0 1rem;
  }
  .uploadedFiles__file {
    margin: 0 0 1rem;
    max-width: 100%;
  }
}

.layout--uploads {
  .uploadsManager__content {
    padding: 0 1rem;
  }
}

.layout--paymentDashboard {
  max-width: 100%;
}

.layout--processors {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: calc(100vh - 113px);

  .layout__info {
    width: 100%;
  }

  .onboarding__sectionTitle {
    font-size: 1.25rem;
    color: $navy;
    text-align: left;
    margin-bottom: 0 0 .5rem;
  }
  .onboarding {
    width: 100%; height: 100vh;
    
    h1 {
      display: none;
      margin: 0;
      a {
        display: inline-block;
        width: 223.38px; height: 38px;
        background: url(/assets/images/SimplyBail_logo_long.png) no-repeat;
        background-size: 223.38px, 38px;
        text-indent: -9000px;
        line-height: 1rem;
  
        img {
          display: none;
        }
      }
    }

    section {
      border-top: 2px solid $lightGrey;
    }

    .onboarding__terms__container {
      display: block;
    }

    .onboarding__terms {
      padding: 0 1rem;
      display: block;
    }

    .onboarding__onlineSales {
      padding: 0 1rem;
      .onboarding__onlineSales__content, .onboarding__onlineSales__options {
        padding: 0;
      }
    }

    .onboarding__profile {
      padding: 2rem 1rem 2rem;
      // overflow-y: auto;
      .questionnaire__question  {
        max-width: 100%;
      }

      .questionnaireArea {
        margin: 2rem auto 0;
        max-width: 400px;
      }
    }

    .onboarding__review {
      display: block;
      padding-top: 3rem;
    }
  }
}

.officeAdminTags {
  margin: 0;
  .tag::after {
    display: none;
  }
}

.officeDowner {
  @include flex;
}