.form-row {
  &--radioGroup {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &--charges {
    p {
      text-align: left;
      margin: 0;
    }
  }
}

.action-row {
  &.split-row {
    @include flex;
    button {
      width: 49%;
    }
  }
}

.info-row {
  margin-bottom: 1rem;
  border-top: 1px solid #DFE6F4;
  border-bottom: 1px solid #DFE6F4;

  .infoBlock {
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: .85rem;
      margin: 0;
    }
    button {
      width: 140px;
      margin-left: 2rem;
    }
  }
}

.formBlock {
  margin-bottom: 20px;

  label {
    display: block;
    color: #6F7F9F;
    margin-bottom: 4px;

    font-size: .75rem;
    @include bp(Mama) {
      font-size: .875rem;
    }
    
    i {
      margin-left: .5rem;
    }

    &.required {
      &:after {
        content: '*';
        color: #FF9150;
      }
    }
  }

  .DayPickerInput {
    display: block;
  }

  input[type="password"], input[type="text"], input[type="number"], input[type="tel"], input[type="email"], .DayPickerInput input {
    padding: 10px;
    width: 100%;
    border: 1px solid #DFE6F4;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    -webkit-appearance:none;
    @include bp(Mama) {
      font-size: .875rem;
    }

    &:focus {
      border-color: #24C0AC;
    }

    &.formBlock--valid {
      border-color: $blue;
    }
    &.formBlock--warning {
      border-color: $warning;
    }
    &.formBlock--danger {
      border-color: $danger;
    }
  }

  textarea {
    font-family: 'Rubik', -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 10px;
    width: 100%;
    border: 1px solid #DFE6F4;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    -webkit-appearance:none;
    @include bp(Mama) {
      font-size: .875rem;
    }

    &:focus {
      border-color: #24C0AC;
    }
  }

  .creditCardContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > input {
      margin-right: 1rem;
    }
    
    & > span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 1;
      padding-top: 4px;
      @include bp(Mama) {
        width: 185px;
      }

      i {
        display: none;
        margin-right: .4rem;
        font-size: 2.3rem;
        color: #DFE6F4;
        @include bp(Mama) {
          display: inline-block;
        }

        &:last-child {
          margin-right: 0;
        }

        &.active {
          display: inline-block;
          color: $navy;
        }
      }
    }

    &__invalid {
      font-size: .85rem;
      color: $danger;
      margin-bottom: 0;
    }
  }

  &--creditCardContainer {
    p.small {
      text-align: left;
      margin: 4px 0 0;
      color: $danger;
    }
  }

  &__selectWrapper {
    position: relative;
    width: 100%;
    border: 1px solid #DFE6F4;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    -webkit-appearance:none;
    @include bp(Mama) {
      font-size: .875rem;
    }

    select {
      position: relative;
      z-index: 1;
      border: 0;
      padding: 10px;
      width: 100%;
      background: transparent;
    
      &.formBlock--valid {
        border-color: $blue;
      }
      &.formBlock--warning {
        border-color: $warning;
      }
      &.formBlock--danger {
        border-color: $danger;
      }
    }

    i {
      position: absolute;
      top: 50%; right: 10px;
      z-index: 0;
      transform: translateY(-50%);
      color: $teal;
    }
    
    &.formBlock--valid {
      border-color: $blue;
    }
    &.formBlock--warning {
      border-color: $warning;
    }
    &.formBlock--danger {
      border-color: $danger;
    }
  }

  &--calendar {
    position: relative;
    .calendar_trigger {
      position: absolute;
      top: 30px; right: 10px;
      i {
        color: #DFE6F4;
      }
    }
    .react-calendar {
      border-color: #DFE6F4;
    }
  }

  &--radio {
    margin-right: 1rem;
    input { display: none; }
    label {
      position: relative;
      padding-left: 30px;

      &:before {
        content: '';
        position: absolute;
        top: 50%; left: 0;
        transform: translateY(-50%);
        width: 24px; height: 24px;
        border: 1px solid #DFE6F4;
        border-radius: 50%;
      }

      &:after {
        display: none;
        content: '';
        position: absolute;
        top: 50%; left: 3px;
        transform: translateY(-50%);
        width: 18px; height: 18px;
        border: 1px solid #DFE6F4;
        border-radius: 50%;
        background: #24C0AC;
      }
    }
    input:checked + label {
      &:after {
        display: block;
      }
    }
  }

  &--checkbox {
    .cb {
      padding-left: 34px;
      &__checkbox {
        position: absolute;
        top: 50%; left: 0;
      }
      &__text {
        font-size: .75rem;
        color: #6F7F9F;
    
        a {
          color: $teal;
        }
      }
    }
  }
}

.timeContainer {
  position: relative;
  &__meridiem {
    position: absolute;
    bottom: 3px; right: 4px;
    position: absolute;
    border-radius: 4px;
    overflow: hidden;
    
    button {
      width: 40px; height: 32px;
      background: $lightGrey;
      border: 0;
      color: #FFF;
      cursor: pointer;
      outline: none;

      &:hover {
        background: $grey;
      }

      &.active {
        background: $navy;
        font-weight: 700;
        &:hover {
          background: $navy;
        }
      }
    }
  }
}

.register__form {

  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 100px;

  h3 {
    padding: 0 37px;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .error {
    padding: .5rem;
    background: #ED445B;
    color: #FFF;
    border-radius: 5px;
  }

  &__error, &__success {
    margin-bottom: 1rem;
    padding: 0 37px;

    &__inner {
      padding: .5rem;
      background: #ED445B;
      color: #FFF;
      border-radius: 5px;

      p {
        margin: 0;
        font-size: .85rem;
      }
    }
  }

  &__success {
    &__inner {
      background: #24d64d;
    }
  }

  &__info {
    max-width: 500px;
    margin: 0 auto;
    padding: 0 37px;
    h2, p {
      font-weight: 600;
      font-size: 1.5rem;
      color: #2D3766;
      font-size: 1.125rem;
      line-height: 1.375rem;
      @include bp(Mama) {
        font-size: 1.5rem;
        line-height: 1.75rem;
      }
      strong {
        font-weight: 600;
        color: #4259ED;
      }
    }
  }

  &__block {
    padding: 0 37px;
  }

  .cityStateBlock {
    display: none;
  }

  .checkbox-row {
    .cb {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;

      &__checkbox {
        margin-right: 1rem;
      }
    }
  }

  .radio-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .radio-row-options {
      display: flex;
      justify-content: flex-start;
      margin-right: 1rem;
    }

    input[type="radio"] {
      display: none;
      appearance: radio;
    }

    label {
      position: relative;
      padding-left: 24px;
      margin-right: .5rem;
      cursor: pointer;
      font-size: .85rem;
      &::before {
        content: '';
        position: absolute;
        top: 50%; left: 0;
        transform: translateY(-50%);
        width: 16px; height: 16px;
        border: 1px solid $lightGrey;
        border-radius: 50%;
      }
    }

    input:checked + label {
      &::before {
        background: $teal;
      }
    }

    .radio__text {
      font-size: .8rem;
    }
  }

  .split-row {    
    & > div {
      &:first-child {
        margin-right: 5px;
      }
      &:last-child {
        margin-left: 5px;
      }
    }

    @include bp(Kid) {
      display: flex;
      justify-content: space-between;

      & > div {
        width: 50%;
      }
    }

    &--1up {
      div.formBlock {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
      }
    }

    &--2up {
      div.formBlock {
        margin-right: 5px;
        width: calc(100% / 2);
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &--3up {
      display: flex; // allows payment rows to be side-by-side
      div.formBlock {
        margin-right: 5px;
        width: calc(100% / 3);
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &--4up {
      div.formBlock {
        margin-right: 5px;
        width: calc(100% / 4);
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__actionsBar {
    &__actions {
      padding: 0 37px .5rem;
      display: flex;
      justify-content: space-between;

      button, input[type="submit"] {
        width: 49%;
        height: 45px;
        border: 2px solid #6F7F9F;
        color: #6F7F9F;
        border-radius: 5px;
        background: #FFF;
        outline: none;
        -webkit-appearance:none;

        &:hover {
          background: #6F7F9F;
          color: #FFF;
        }

        &:disabled {
          opacity: .3;
          &:hover {
            background: #FFF;
            color: #6F7F9F;
          }
        }

        &.teal {
          border-color: #24C0AC;
          background: #24C0AC;
          color: #FFF;
          &:hover {
            background: #FFF;
            color: #24C0AC;
          }
        }

        &.red {
          border-color: #fc5050;
          background: #fc5050;
          color: #FFF;
          &:hover {
            background: #FFF;
            color: #fc5050;
          }
        }
      }
    }
  }
  .register__form__progress {
    position: relative;
    height: 48px;
    background: #E8EBFC;

    &__container {
      position: fixed;
      bottom: 0; left: 0;
      width: 100%; height: 48px;
      z-index: 6;
    }
    
    &:before, &:after {
      content: '';
      position: absolute;
      top: 0; left: 0;
      z-index: 5;
      width: 100%;
      display: block;
      height: 5px;
      background: #2D3766;
    }

    &:after {
      z-index: 6;
      width: 0%;
      background: #24C0AC;
    }

    &--stepOne {
      &:after {
        width: 25%;
      }
    }

    &--stepTwo {
      &:after {
        width: 50%;
      }
    }

    &--stepThree {
      &:after {
        width: 75%;
      }
    }
  }
}

.searchResults {
  &__item {
    padding: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DFE6F4;

    &--header {
      span {
        display: block;
        font-size: .875rem;
        color: #6F7F9F;
        margin-bottom: 4px;
        &:nth-child(3) { width: 50px; }
      }
    }
  }

  &__actions {
    padding: 1rem 0;
    text-align: center;
    button {
      width: 100%;
      max-width: 300px;
    }
  }

  span {
    &:nth-child(1) { width: calc(100% - 140px); }
    &:nth-child(2) { width: 100px; }
  }

  button {
    width: 50px;
    height: 24px;
    -webkit-appearance:none;
  }
}