body.layout--signup {
  #root main,
  #root div.main {
    padding-top: 57px;
    padding-bottom: 0;
  }

  .video {
    display: none;
    margin-left: 420px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding: 56.25% 0 0 0;
    width: calc(100% - 450px);
    height: 50vh;
    @include bp(Mama) {
      display: block;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .signup__container {
    height: calc(100vh - 57px);
    @include bp(Mama) {
      background: url("/assets/images/home_hero_1.jpg") no-repeat 50% 50%;
      background-size: cover;
    }

    form {
      margin: 0;
      padding: 2rem 2rem 0;
      height: 100%;
      width: 100%;
      background: #fafafa;
      border-right: 1px solid $lightGrey;

      @include bp(Mama) {
        padding: 2rem 0 0;
        @include unicorn;
        flex-direction: column;
        width: 400px;
      }

      header {
        display: none;
      }

      .register__form__block {
        padding: 0;
        @include bp(Mama) {
          margin-top: -85px;
          padding: 2rem;
          border-top: 1px solid $lightGrey;
          border-bottom: 1px solid $lightGrey;
          background: #efefef;
        }
      }

      button {
        width: 100%;
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }

  // position: relative;
  // height: 500px;
  // @include bp(Mama) {
  //   min-height: 700px;
  //   height: 70vh;
  // }

  // &__slide {
  //   width: 100%; height: 100%;
  //   background: url('/assets/images/home_hero_1.jpg') no-repeat 50% 50%;
  //   background-size: cover;
  // }
}
