@import "styles/basics/mixins";
@import "styles/basics/vars";

input, button, select {
  -webkit-appearance:none;
}

form {
  .formRow {
    &--radioGroup {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &--2up, &--address {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .formRow__block {
        margin-right: 5px;
        width: calc(100% / 2);
        &:last-child {
          margin-right: 0;
        }
      }
    }

    &--3up {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .formRow__block {
        margin-right: 5px;
        width: calc(100% / 3);
        &:last-child {
          margin-right: 0;
        }
      }
    }

    &--address {
      .formRow__block {
        &:first-child {
          width: calc(80%);
        }
        &:last-child {
          width: calc(20%);
        }
      }
    }

    &__block {
      margin-bottom: 20px;

      &__selectAnswer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 38px;
        border: 1px solid #DFE6F4;
        border-radius: 5px;
        padding: .5rem;
        background: #FFF;

        button {
          height: 24px;
        }
      }
    
      label {
        display: block;
        color: #6F7F9F;
        margin-bottom: 4px;
    
        font-size: .75rem;
        @include bp(Mama) {
          font-size: .875rem;
        }
    
        i {
          margin-left: .5rem;
        }
    
        &.required {
          &:after {
            content: '*';
            color: #FF9150;
          }
        }
      }
    
      input[type="password"], input[type="text"], input[type="number"], input[type="tel"], input[type="email"], .DayPickerInput input {
        padding: 10px;
        width: 100%;
        border: 1px solid #DFE6F4;
        border-radius: 5px;
        font-size: 1rem;
        outline: none;
        -webkit-appearance:none;
        @include bp(Mama) {
          font-size: .875rem;
        }
    
        &:focus {
          border-color: #24C0AC;
        }
    
        &.formBlock--valid {
          border-color: $blue;
        }
        &.formBlock--warning {
          border-color: $warning;
        }
        &.formBlock--danger {
          border-color: $danger;
        }
      }
    
      textarea {
        font-family: 'Rubik', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding: 10px;
        width: 100%;
        border: 1px solid #DFE6F4;
        border-radius: 5px;
        font-size: 1rem;
        outline: none;
        -webkit-appearance:none;
        @include bp(Mama) {
          font-size: .875rem;
        }
    
        &:focus {
          border-color: #24C0AC;
        }
      }
    
      .creditCardContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        & > input {
          margin-right: 1rem;
        }
        
        & > span {
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 1;
          width: 185px;
          padding-top: 4px;
    
          i {
            margin-right: .4rem;
            font-size: 2.3rem;
            color: #DFE6F4;
    
            &:last-child {
              margin-right: 0;
            }
    
            &.active {
              color: $navy;
            }
          }
        }
    
        &__invalid {
          font-size: .85rem;
          color: $danger;
          margin-bottom: 0;
        }
      }
    
      &__selectWrapper {
        position: relative;
        width: 100%;
        border: 1px solid #DFE6F4;
        border-radius: 5px;
        font-size: 1rem;
        outline: none;
        -webkit-appearance:none;
        @include bp(Mama) {
          font-size: .875rem;
        }
    
        select {
          position: relative;
          z-index: 1;
          border: 0;
          padding: 10px;
          width: 100%;
          background: transparent;
        
          &.formBlock--valid {
            border-color: $blue;
          }
          &.formBlock--warning {
            border-color: $warning;
          }
          &.formBlock--danger {
            border-color: $danger;
          }
        }
    
        i {
          position: absolute;
          top: 50%; right: 10px;
          z-index: 0;
          transform: translateY(-50%);
          color: $teal;
        }
        
        &.formBlock--valid {
          border-color: $blue;
        }
        &.formBlock--warning {
          border-color: $warning;
        }
        &.formBlock--danger {
          border-color: $danger;
        }
      }
    
      .radio {
        &__radioItem {
          display: inline-block;
          margin-right: .5rem;
          &:last-child {
            margin-right: 0;
          }
      
          input[type="radio"] {
            display: none;
          }
          label {
            position: relative;
            padding-left: 22px;
            font-size: .85rem;
            font-weight: 700;
            cursor: pointer;
            &:before {
              content: '';
              position: absolute;
              top: 50%; left: 0;
              transform: translateY(-50%);
              width: 16px; height: 16px;
              background: #FAFAFA;
              border: 1px solid #DFE6F4;
              border-radius: 50%;
            }
            &:after {
              content: '';
              display: none;
              position: absolute;
              top: 50%; left: 4px;
              transform: translateY(-50%);
              width: 10px; height: 10px;
              background: $teal;
              border-radius: 50%;
            }
          }
          input[type="radio"]:checked + label:after {
            display: block;
          }
        }
      }
    
      &--checkbox {
        .cb {
          padding-left: 34px;
          &__checkbox {
            position: absolute;
            top: 50%; left: 0;
          }
          &__text {
            font-size: .75rem;
            color: #6F7F9F;
        
            a {
              color: $teal;
            }
          }
        }
      }
    }
  }
}

.inputRow {
  &--radioGroup {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &--2up, &--address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .formRow__block {
      margin-right: 5px;
      width: calc(100% / 2);
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--address {
    .formRow__block {
      &:first-child {
        width: calc(80%);
      }
      &:last-child {
        width: calc(20%);
      }
    }
  }

  &__block {
    margin-bottom: 20px;

    &__helpText {
      margin: .25rem 0 0;
      font-size: .7rem;
      color: $grey;
    }

    &__selectAnswer {
      display: flex;
      // flex-wrap: wrap;
      gap: 0.5rem;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #DFE6F4;
      border-radius: 5px;
      padding: .5rem;
      background: #FFF;

      span {
        order: 2;
        width: 100%;
      }

      button {
        height: 24px;
        order: 1;
        margin-left: auto;
      }

      @include bp(Kid) {
        flex-wrap: nowrap;
        height: 38px;

        span {
          order: 1;
        }

        button {
          order: 2;
        }
      }    
    }
  
    label {
      display: block;
      color: #6F7F9F;
      margin-bottom: 4px;
  
      font-size: .75rem;
      @include bp(Mama) {
        font-size: .875rem;
      }
    
      i {
        margin-left: .5rem;
      }
  
      &.required {
        &:after {
          content: '*';
          color: #FF9150;
        }
      }
    }
  
    input[type="password"], input[type="text"], input[type="number"], input[type="tel"], input[type="email"], .DayPickerInput input {
      padding: 10px;
      width: 100%;
      border: 1px solid #DFE6F4;
      border-radius: 5px;
      font-size: .875rem;
      outline: none;
      -webkit-appearance:none;
  
      &:focus {
        border-color: #24C0AC;
      }
  
      &.formBlock--valid {
        border-color: $blue;
      }
      &.formBlock--warning {
        border-color: $warning;
      }
      &.formBlock--danger {
        border-color: $danger;
      }
    }
  
    textarea {
      font-family: 'Rubik', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      padding: 10px;
      width: 100%;
      border: 1px solid #DFE6F4;
      border-radius: 5px;
      font-size: 1rem;
      outline: none;
      -webkit-appearance:none;
      @include bp(Mama) {
        font-size: .875rem;
      }
  
      &:focus {
        border-color: #24C0AC;
      }
    }
  
    .creditCardContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      & > input {
        margin-right: 1rem;
      }
      
      & > span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 1;
        width: 135px;
        padding-top: 4px;
  
        i {
          margin-right: .4rem;
          font-size: 2.3rem;
          color: #DFE6F4;
  
          &:last-child {
            margin-right: 0;
          }
  
          &.active {
            color: $navy;
          }
        }
      }
  
      &__invalid {
        font-size: .85rem;
        color: $danger;
        margin-bottom: 0;
      }
    }
  
    &__selectWrapper {
      position: relative;
      width: 100%;
      border: 1px solid #DFE6F4;
      border-radius: 5px;
      font-size: 1rem;
      outline: none;
      -webkit-appearance:none;
      @include bp(Mama) {
        font-size: .875rem;
      }
  
      select {
        position: relative;
        z-index: 1;
        border: 0;
        padding: 10px;
        width: 100%;
        background: transparent;
      
        &.formBlock--valid {
          border-color: $blue;
        }
        &.formBlock--warning {
          border-color: $warning;
        }
        &.formBlock--danger {
          border-color: $danger;
        }
      }
  
      i {
        position: absolute;
        top: 50%; right: 10px;
        z-index: 0;
        transform: translateY(-50%);
        color: $teal;
      }
      
      &.formBlock--valid {
        border-color: $blue;
      }
      &.formBlock--warning {
        border-color: $warning;
      }
      &.formBlock--danger {
        border-color: $danger;
      }
    }
  
    .radio {
      &__radioItem {
        display: inline-block;
        margin-right: .5rem;
        &:last-child {
          margin-right: 0;
        }
    
        input[type="radio"] {
          display: none;
        }
        label {
          position: relative;
          padding-left: 22px;
          font-size: .85rem;
          font-weight: 700;
          cursor: pointer;
          &:before {
            content: '';
            position: absolute;
            top: 50%; left: 0;
            transform: translateY(-50%);
            width: 16px; height: 16px;
            background: #FAFAFA;
            border: 1px solid #DFE6F4;
            border-radius: 50%;
          }
          &:after {
            content: '';
            display: none;
            position: absolute;
            top: 50%; left: 4px;
            transform: translateY(-50%);
            width: 10px; height: 10px;
            background: $teal;
            border-radius: 50%;
          }
        }
        input[type="radio"]:checked + label:after {
          display: block;
        }
      }
    }
  
    &--checkbox {
      .cb {
        padding-left: 34px;
        &__checkbox {
          position: absolute;
          top: 50%; left: 0;
        }
        &__text {
          font-size: .75rem;
          color: #6F7F9F;
      
          a {
            color: $teal;
          }
        }
      }
    }
  }
}